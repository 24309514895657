import * as React from 'react';
import { MenuItem, MenuItemProps } from '@mui/material';

export interface DropdownItemProps {
  children?: React.ReactNode;
  label?: React.ReactNode;
  dsOnClick: MenuItemProps['onClick'];
  e2e?: string;
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  children,
  dsOnClick,
  e2e,
  label,
}: DropdownItemProps) => {
  return (
    <MenuItem onClick={dsOnClick} {...(e2e && { 'data-e2e': e2e })}>
      {children}
      {label}
    </MenuItem>
  );
};

export default DropdownItem;
