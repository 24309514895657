import React, { useEffect, useMemo } from 'react';
import { FilterDataTypes, InternalOperator, Operator } from '../../types';
import styled from '@emotion/styled';
import { Icon, TextField } from '../../..';
import { useTranslate } from '../../../i18n';
import { FilterableField, TableProps } from '../..';
import { FilterComponentProps } from './FilterComponentFactory';
import Select, { SelectItem } from '../../../Select';
import ButtonFilter from '../../../Button/ButtonFilter';
import { StyledFilter, StyledFilterLabel } from '../FilterBar';
import { Box, useTheme } from '@mui/material';

const StyledTextField = styled.div`
  width: 100%;
  display: flex;
  & > * {
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  width: 320px;
  display: flex;
`;

const SearchSettingsWrapper = styled.div`
  margin: 0;
  padding: 4px 0;
`;

export const SEARCH_KEY = 'SEARCH_KEY';

interface SearchFilterComponentProps extends FilterComponentProps {
  filterableFields: FilterableField[];
  dsOnSearchChange?: (state: string) => void;
  dsOnSearchOperatorChange?: (operator: InternalOperator) => void;
  dsOnChangeIncludeCustomFields?: (include: boolean) => void;
  searchPlaceholder?: TableProps['searchPlaceholder'];
  includeCustomFields: boolean;
  setIncludeCustomFields: (includeCustomFields) => void;
  searchMode: InternalOperator;
  setSearchMode: (searchMode) => void;
}

export const SearchFilterComponent = (props: SearchFilterComponentProps) => {
  const {
    e2e,
    stateKey,
    state,
    stateChangeFn,
    filterableFields,
    dsOnSearchChange,
    dsOnSearchOperatorChange,
    dsOnChangeIncludeCustomFields,
    filterBarOptions,
    searchPlaceholder,
    includeCustomFields,
    setIncludeCustomFields,
    searchMode,
    setSearchMode,
  } = props;
  const theme = useTheme();

  const { translate } = useTranslate();

  const [searchString, setSearchString] = React.useState('');

  const searchableFields = useMemo(() => {
    const fieldsToSearch =
      filterBarOptions?.searchFields ||
      filterableFields?.filter(({ type }) => type === FilterDataTypes.STRING);
    return includeCustomFields
      ? fieldsToSearch
      : fieldsToSearch?.filter(({ name }) => !name.includes('__c'));
  }, [filterableFields, filterBarOptions?.searchFields, includeCustomFields]);

  useEffect(() => {
    if (filterBarOptions?.defaultSearchOperator && searchString === '') {
      setSearchMode(filterBarOptions.defaultSearchOperator);
    }
  }, [filterBarOptions?.defaultSearchOperator, searchString]);

  useEffect(() => {
    if (
      filterBarOptions?.defaultIncludeCustomFields !== undefined &&
      searchString === ''
    ) {
      setIncludeCustomFields(filterBarOptions.defaultIncludeCustomFields);
    }
  }, [filterBarOptions?.defaultIncludeCustomFields, searchString]);

  const stateChange = (updates, blur = false) => {
    if (dsOnSearchChange && Object.keys(updates).includes('value')) {
      dsOnSearchChange(updates.value);
    } else {
      stateChangeFn(
        {
          ...state,
          [stateKey]: {
            ...state[stateKey],
            type: FilterDataTypes.STRING,
            dataKey: searchableFields?.map(({ name }) => name),
            operator: Operator.In,
            internalOperator: searchMode,
            includeCustomFields,
            ...updates,
          },
        },
        blur,
      );
    }
  };

  const getPlaceholder = (internalOperator: InternalOperator) => {
    switch (internalOperator) {
      case InternalOperator.STARTSWITH:
        return 'SearchStartsWith';
      case InternalOperator.CONTAINS:
        return 'SearchPlaceholder';
      case InternalOperator.EQUALS:
        return 'FilterBar_Equals';
    }
  };

  const processState = () => {
    const incomingValue = state[stateKey]?.value;
    if (incomingValue !== searchString) {
      setSearchString(incomingValue || '');
    }

    const incomingMode = state[stateKey]?.internalOperator;
    if (incomingMode && incomingMode !== searchMode) {
      setSearchMode(incomingMode);
    }

    const incomingIncludeCustomFields = state[stateKey]?.includeCustomFields;
    if (
      incomingIncludeCustomFields &&
      incomingIncludeCustomFields !== includeCustomFields
    ) {
      setIncludeCustomFields(incomingIncludeCustomFields);
    }
  };

  React.useEffect(() => {
    if (state && !dsOnSearchChange) {
      processState();
    }
  }, [state]);

  return Object.keys(searchableFields || {}).length > 0 ? (
    <Box
      sx={{
        flexWrap: 'wrap',
        paddingLeft: theme.spacing(1),
        display: 'flex',
        margin: 'auto',
        width: '100%',
        alignItems: 'center',
        gap: theme.spacing(0.5),

        '& .MuiChip-root': {
          maxWidth: '250px',
        },
      }}
    >
      {!filterBarOptions?.disableSearchOperator && (
        <SearchSettingsWrapper>
          <Select
            {...(e2e && { e2e: `${e2e}-select-group` })}
            value={searchMode}
            label=""
            dsOnChange={(e) => {
              const internalOperator = e.target.value as InternalOperator;
              setSearchMode(internalOperator);
              dsOnSearchOperatorChange?.(internalOperator);

              if (searchString) {
                stateChange({ internalOperator }, true);
              }
            }}
          >
            <SelectItem
              {...(e2e && { key: `${e2e}-select-group-option-contains` })}
              value={InternalOperator.CONTAINS}
            >
              {translate(`FilterBar_Contains`)}
            </SelectItem>
            <SelectItem
              {...(e2e && { key: `${e2e}-select-group-option-starts-with` })}
              value={InternalOperator.STARTSWITH}
            >
              {translate(`FilterBar_StartsWith`)}
            </SelectItem>
            <SelectItem
              {...(e2e && { key: `${e2e}-select-group-option-equals` })}
              value={InternalOperator.EQUALS}
            >
              {translate(`FilterBar_Equals`)}
            </SelectItem>
          </Select>
        </SearchSettingsWrapper>
      )}
      {!filterBarOptions?.disableSearchExclusionBtn && (
        <SearchSettingsWrapper>
          <ButtonFilter
            {...(e2e && { e2e: `${e2e}-table-filter-bar` })}
            a11yDescribedby="test"
            state={includeCustomFields ? 'indeterminate' : 'default'}
            dsOnClick={() => {
              const newState = !includeCustomFields;
              dsOnChangeIncludeCustomFields?.(newState);
              setIncludeCustomFields(newState);
            }}
            body={
              <StyledFilter>
                <StyledFilterLabel>
                  {`${translate('FilterBar_CustomFields')}: ${
                    includeCustomFields
                      ? translate('Include')
                      : translate('Exclude')
                  }`}
                </StyledFilterLabel>
              </StyledFilter>
            }
            size="small"
            endIcon="cancel"
          />
        </SearchSettingsWrapper>
      )}

      <SearchSettingsWrapper>
        <SearchWrapper>
          <StyledTextField>
            <TextField
              {...(e2e && { e2e: `${e2e}-text-field` })}
              dsOnChange={(e) => {
                setSearchString(e.target.value);
              }}
              dsOnKeyPress={({ key }) => {
                if (key === 'Enter') {
                  stateChange({ value: searchString }, true);
                }
              }}
              placeholder={
                searchPlaceholder ||
                (searchMode && translate(getPlaceholder(searchMode)))
              }
              value={searchString}
              endAdornmentIcon={searchString.length > 0 ? 'clear' : 'search'}
              {...(searchString.length > 0 && {
                dsOnEndAdornmentClick: () => {
                  setSearchString('');
                  stateChange({ value: '' }, true);
                },
              })}
            />
            {(searchableFields || []).length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  body="help_outline"
                  tooltip={`${translate(
                    'FilterBar_SearchIndexTooltip',
                  )} ${searchableFields
                    ?.map(({ label }) => label)
                    .sort()
                    .join(', ')}`}
                />
              </Box>
            )}
          </StyledTextField>
        </SearchWrapper>
      </SearchSettingsWrapper>
    </Box>
  ) : null;
};
