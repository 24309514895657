import React from 'react';
import { FilterComponentProps } from './FilterComponentFactory';
import { Operator, Option } from '../../types';
import { Checkbox, Divider, ListItemText, MenuList } from '@mui/material';
import Spinner from '../../../Spinner';
import styled from '@emotion/styled';
import { TextField } from '../../..';
import { useTranslate } from '../../../i18n';
import { ClearComponent } from './ClearComponent';
import {
  StyledMenuItem,
  StyledMenuList,
  StyledSpinnerContainer,
} from '../styles';

const TypeaheadWrapper = styled.div`
  width: 300px;
`;

const StyledTextField = styled.div`
  & > * {
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  margin: 1.5rem 1.5rem 0;
`;

const StyledNoRecords = styled.div`
  text-align: center;
  margin: 1rem 1rem 0;
`;

export const TypeaheadFilterComponent = (props: FilterComponentProps) => {
  const {
    stateKey,
    state,
    stateChangeFn,
    filterableField,
    dsComponentProps,
    cachedSelections = [],
    setCachedSelections = () => {},
  } = props;
  const { translate } = useTranslate();
  const e2e = dsComponentProps?.e2e ?? null;

  const [selectedChoices, setSelectedChoices] = React.useState<
    string[] | null
  >();

  const [options, setOptions] = React.useState<Option[] | undefined>(undefined);
  const [searchString, setSearchString] = React.useState('');
  const [initialLoading, setInitialLoading] = React.useState<boolean>(true);

  const showShortlist = searchString.length === 0;

  const initialValue = React.useRef<any>();
  const timer = React.useRef<number>();

  const setOptionsWrapper = async () => {
    if (filterableField?.relationshipQueryCallback) {
      setOptions(undefined);
      const optionData = await filterableField.relationshipQueryCallback(
        searchString,
        initialValue.current,
      );

      setOptions(optionData?.sort((a, b) => a.label.localeCompare(b.label)));
    }
  };

  const debounceQuery = () => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      setOptionsWrapper();
    }, 300);
  };

  const processStateValue = () => {
    if (Array.isArray(state[stateKey].value)) {
      setSelectedChoices(state[stateKey].value as any);
    } else if (!state[stateKey].value) {
      setSelectedChoices(null);
    }

    initialValue.current = state[stateKey].value || [];
    setOptionsWrapper();
  };

  const stateChange = (value, blur = false) => {
    stateChangeFn(
      {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          value: value,
          operator: Operator.In,
        },
      },
      blur,
    );
  };

  React.useEffect(() => {
    debounceQuery();
  }, [searchString]);

  // Populate the Selected Short List on Load
  React.useEffect(() => {
    if (options && state && initialLoading) {
      setCachedSelections(
        options.filter(({ value }) => selectedChoices?.includes(value)),
      );
      setInitialLoading(false);
    }
  }, [options, selectedChoices]);

  React.useEffect(() => {
    processStateValue();
  }, [state]);

  React.useEffect(() => {
    processStateValue();

    return () => {
      stateChange(initialValue.current, true);
    };
  }, []);

  const menuItem = React.useCallback(
    ({ value, label }) => {
      const choiceIndex = Array.from(selectedChoices || []).indexOf(value);

      return (
        <StyledMenuItem
          {...(e2e && { 'data-e2e': `${e2e}-menu-item` })}
          key={value}
          onClick={() => {
            if (choiceIndex > -1) {
              setCachedSelections(
                cachedSelections.filter(
                  ({ value: cachedValue }) => cachedValue !== value,
                ),
              );

              initialValue.current.splice(choiceIndex, 1);
              stateChange(initialValue.current);
            } else {
              setCachedSelections([...cachedSelections, { value, label }]);

              stateChange([...initialValue.current, value]);
            }
          }}
        >
          <Checkbox color="primary" checked={choiceIndex > -1} />
          <ListItemText primary={label} />
        </StyledMenuItem>
      );
    },
    [selectedChoices, cachedSelections],
  );

  return (
    <TypeaheadWrapper>
      <SearchWrapper>
        <StyledTextField>
          <TextField
            {...(e2e && { 'data-e2e': `${e2e}-menu-item` })}
            autoFocus
            dsOnChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            endAdornmentIcon={searchString.length > 0 ? 'clear' : 'search'}
            {...(searchString.length > 0 && {
              dsOnEndAdornmentClick: () => {
                setSearchString('');
              },
            })}
          />
        </StyledTextField>
      </SearchWrapper>

      {options && !initialLoading ? (
        <>
          <StyledMenuList>
            <MenuList>
              {showShortlist && cachedSelections.map(menuItem)}
              <Divider />
              {options.length > 0 ? (
                options
                  .filter(({ value }) =>
                    !showShortlist
                      ? true
                      : !cachedSelections.find(
                          ({ value: cachedValue }) => cachedValue === value,
                        ),
                  )
                  .map(menuItem)
              ) : (
                <StyledNoRecords>{translate('NoRecordsFound')}</StyledNoRecords>
              )}
            </MenuList>
          </StyledMenuList>
          {Array.from(selectedChoices || [])?.length > 0 && (
            <ClearComponent
              onClear={() => {
                setCachedSelections([]);
                stateChange([]);
              }}
            />
          )}
        </>
      ) : (
        <StyledSpinnerContainer>
          <Spinner />
        </StyledSpinnerContainer>
      )}
    </TypeaheadWrapper>
  );
};
