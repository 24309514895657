import * as React from 'react';
import {
  FormControl as MuiFormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  Switch,
  SwitchProps,
  Tooltip,
} from '@mui/material';

export interface ToggleProps
  extends Pick<
    SwitchProps,
    'checked' | 'name' | 'value' | 'disabled' | 'required' | 'size'
  > {
  dsOnChange?: SwitchProps['onChange'];
  e2e?: string;
  label: React.ReactNode;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  error?: boolean;
  tooltip?: string;
}

const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  (
    {
      disabled,
      dsOnChange,
      e2e,
      required,
      label,
      checked,
      name,
      value,
      labelPlacement,
      size = 'small',
      error,
      tooltip,
    },
    ref,
  ) => {
    const isChecked = error ? false : checked;

    const switchElem = (
      // @ts-ignore
      <Switch
        inputRef={ref}
        disabled={disabled}
        required={required}
        checked={isChecked}
        name={name}
        onChange={dsOnChange}
        value={value}
        color="primary"
        size={size}
        {...(e2e && { inputProps: { 'data-e2e': `${e2e}-input` } })}
      />
    );

    const formControlLabelElem = (
      <FormControlLabel
        checked={checked}
        disabled={disabled}
        label={
          <div>
            {label}
            <FormLabel />
          </div>
        }
        control={switchElem}
        labelPlacement={labelPlacement}
      />
    );

    return (
      <MuiFormControl required={required} error={error}>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            arrow
            PopperProps={{
              ...(e2e && { 'data-e2e': `${e2e}-tooltip` }),
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -8],
                  },
                },
              ],
            }}
          >
            {formControlLabelElem}
          </Tooltip>
        ) : (
          formControlLabelElem
        )}
      </MuiFormControl>
    );
  },
);

export default Toggle;
