import React from 'react';
import { PaletteMode, useTheme } from '@mui/material';
import styled from '@emotion/styled';

const ShimmerDiv = styled.div<{ themeMode: PaletteMode }>`
  height: 16px;
  width: 100%;
  min-width: 80px;
  ${({ themeMode }) =>
    themeMode === 'light' &&
    `
      background: #f6f7f8;
      background-image: linear-gradient(
        to right,
        #f6f7f8 0%,
        #edeef1 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
      );
  `}

  ${({ themeMode }) =>
    themeMode === 'dark' &&
    `
      background: #0a121a;
      background-image: linear-gradient(
        to right,
        #0a121a 0%,
        #1e2f3e 20%,
        #0a121a 40%,
        #0a121a 100%
      );
  `}
  
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: flex;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
`;

export const Shimmer = () => {
  const {
    palette: { mode },
  } = useTheme();

  return <ShimmerDiv themeMode={mode} />;
};

export default Shimmer;
