import * as React from 'react';
import { CircularProgress, lighten, LinearProgress } from '@mui/material';
import { brand500Colors } from '../Theme';

const sizes = {
  small: 24,
  medium: 40,
  large: 75,
};

export interface SpinnerProps {
  children?: React.ReactNode;
  e2e?: string;
  variant?: 'circular' | 'linear';
  size?: 'small' | 'medium' | 'large';
}

const Spinner: React.FC<SpinnerProps> = ({
  e2e,
  variant = 'circular',
  size = 'medium',
}: SpinnerProps) => {
  const circular = (
    <CircularProgress
      {...(e2e && { 'data-e2e': e2e })}
      size={sizes[size]}
      sx={{
        '& svg': {
          animation: `rotateColors 5s infinite`,
          '@keyframes rotateColors': {
            '0%': { color: brand500Colors[0] },
            '25%': { color: brand500Colors[1] },
            '50%': { color: brand500Colors[2] },
            '75%': { color: brand500Colors[3] },
            '100%': { color: brand500Colors[0] },
          },
        },
      }}
    />
  );

  const backgroundColor =
    brand500Colors[Math.floor(Math.random() * brand500Colors.length)];

  const linear = (
    <LinearProgress
      {...(e2e && { 'data-e2e': e2e })}
      sx={{
        width: '100%',
        backgroundColor: lighten(backgroundColor, 0.5),
        '& span': {
          backgroundColor,
        },
      }}
    />
  );

  return variant === 'linear' ? linear : circular;
};

export default Spinner;
