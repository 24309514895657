import * as React from 'react';
import styled from '@emotion/styled';
import { transientOptions } from '../utils';
import { Link as MuiLink, LinkProps as MUILinkProps } from '@mui/material';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { designTokens } from '../Theme.design-tokens';
export interface LinkProps
  extends Pick<
    MUILinkProps<
      'a',
      {
        component?: 'a' | ((props: RouterLinkProps) => JSX.Element);
      }
    >,
    'variant' | 'color' | 'underline' | 'href' | 'target' | 'rel' | 'component'
  > {
  body?: React.ReactNode;
  children?: React.ReactNode;
  dsOnClick?: MUILinkProps['onClick'];
  e2e?: string;
  to?: RouterLinkProps['to'];
  disabled?: boolean;
}

const StyledMuiLink = styled(MuiLink, transientOptions)<
  LinkProps & {
    $disabled?: LinkProps['disabled'];
  }
>`
  ${({ $disabled }) =>
    $disabled
      ? `
    && {
      color: ${designTokens.colors.linkDisabled};
      cursor: not-allowed;
    }
  `
      : ''}
`;

const Link: React.FC<LinkProps> = ({
  body,
  children,
  dsOnClick,
  color,
  component = 'a',
  e2e,
  href,
  rel,
  target,
  underline,
  variant,
  to,
  disabled = false,
}: LinkProps) => {
  const hasURL = Boolean(href || to);

  return (
    <StyledMuiLink
      color={color}
      component={component}
      {...(e2e && { 'data-e2e': e2e })}
      {...(disabled && { 'aria-disabled': disabled })}
      href={disabled ? undefined : href}
      rel={rel}
      target={target}
      underline={disabled ? 'none' : underline}
      variant={variant}
      to={to}
      {...(!hasURL && {
        tabIndex: 0,
      })}
      $disabled={disabled}
      onClick={dsOnClick}
    >
      <span {...(e2e && { 'data-e2e': `${e2e}-body` })}>
        {body}
        {children}
      </span>
    </StyledMuiLink>
  );
};

Link.defaultProps = {
  component: 'a',
};

export default Link;
