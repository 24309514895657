import * as React from 'react';
import { DialogActions, useTheme } from '@mui/material';
import { ModalProps } from '..';

export interface ModalActionsProps {
  children?: React.ReactNode;
  /** @deprecated */
  splitActions?: boolean;
  variant?: ModalProps['variant'];
}

const ModalActions: React.FC<ModalActionsProps> = ({
  children,
  splitActions,
  variant,
}: ModalActionsProps) => {
  const theme = useTheme();

  return (
    <DialogActions
      sx={{
        padding:
          variant === 'dialog'
            ? theme.spacing(3)
            : `${theme.spacing(2.5)} ${theme.spacing(4)}`,
        '& button:not(:first-of-type)': {
          marginLeft: theme.spacing(1.5),
        },
        ...(splitActions && {
          width: '100%',
          '& button:first-of-type': {
            marginRight: 'auto',
          },
        }),
      }}
    >
      {children}
    </DialogActions>
  );
};

export default ModalActions;
