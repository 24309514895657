import * as React from 'react';
import styled from '@emotion/styled';
import {
  ListItemText,
  MenuList,
  Popover,
  Tooltip,
  useTheme,
  Box,
} from '@mui/material';
import ButtonFilter from '../../Button/ButtonFilter';
import Icon from '../../Icon';
import { Filter, FilterableField, FilterBarOptions, Sort } from '../index';
import { FilterComponentFactory } from './components/FilterComponentFactory';
import {
  FilterFields,
  VanityFilterField,
  Option,
  FilterDataTypes,
  QuickFilterField,
  InternalOperator,
} from '../types';
import Button from '../../Button';
import { useEffect } from 'react';
import { FilterBarFactory } from './utils';
import { useTranslate } from '../../i18n';
import { v4 as uuid } from 'uuid';
import {
  SearchFilterComponent,
  SEARCH_KEY,
} from './components/SearchFilterComponent';
import { QuickFilterComponent } from './components/QuickFilterComponent';
import { Spinner, TableProps } from '../..';
import {
  StyledMenuList,
  StyledMenuItem,
  StyledSpinnerContainer,
} from './styles';

const StyledPopoverContent = styled.div`
  min-width: 150px;
`;

export const StyledFilter = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 3px;
  font-size: 13px;
  line-height: 18px;
`;

export const StyledFilterLabel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  margin-right: 8px;
  width: 100%;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.1px;
`;

interface FilterBarProps {
  e2e?: string;
  defaultFields?: string[];
  filterableFields: FilterableField[];
  enableFilterBarSearch: TableProps['enableFilterBarSearch'];
  instrumentOn: TableProps['dsInstrumentOn'];
  filter: Filter;
  initialFilter?: Filter;
  fields: FilterFields;
  setFields: (fields) => void;
  setFilter: (filter) => void;
  removeFilter: () => void;
  dsOnSearchChange?: (state: string) => void;
  filterBarOptions?: FilterBarOptions;
  searchPlaceholder?: TableProps['searchPlaceholder'];
  quickFilters?: QuickFilterField[];
  dsOnSort?: (args: Sort[]) => void;
  dsOnSearchOperatorChange?: (operator: InternalOperator) => void;
  dsOnChangeIncludeCustomFields?: (include: boolean) => void;
  isShowClearFilterButton: boolean;
  vanityFilters?: VanityFilterField[];
  orderBy: string | number | undefined;
  includeCustomFields: boolean;
  setIncludeCustomFields: (includeCustomFields) => void;
  searchMode: InternalOperator;
  setSearchMode: (searchMode) => void;
  searchWildcard?: string;
}

export const FilterBar = ({
  e2e,
  defaultFields,
  filterableFields = [],
  fields,
  enableFilterBarSearch,
  instrumentOn,
  filter,
  initialFilter,
  setFields,
  setFilter,
  removeFilter,
  dsOnSearchChange,
  filterBarOptions,
  quickFilters,
  dsOnSort,
  dsOnSearchOperatorChange,
  dsOnChangeIncludeCustomFields,
  isShowClearFilterButton,
  vanityFilters,
  orderBy,
  searchPlaceholder,
  includeCustomFields,
  setIncludeCustomFields,
  searchMode,
  setSearchMode,
  searchWildcard,
}: FilterBarProps) => {
  const theme = useTheme();

  useEffect(() => {
    if (initialFilter) {
      setFilter(initialFilter);
    }
  }, [initialFilter]);

  const { translate } = useTranslate();

  const [filterAnchorEl, setFilterAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const filterOpen = Boolean(filterAnchorEl);
  const filterId = filterOpen ? 'filter-popover' : undefined;

  const autoOpenKey = React.useRef<string | undefined>();

  const [menuAnchorEl, setMenuAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [relationshipQCFlag, setRelationshipQCFlag] = React.useState(false);
  const menuOpen = Boolean(menuAnchorEl);
  const menuId = menuOpen ? 'menu-popover' : '';

  const [cachedSelections, setCachedSelections] = React.useState<{
    [key: string]: Option[];
  }>({});

  const [popoverContentKey, setPopoverContentKey] = React.useState<
    string | undefined
  >();

  const [menuOptions, setMenuOptions] = React.useState<FilterableField[]>(
    filterableFields?.sort((a, b) =>
      (a.label || a.name).localeCompare(b.label || b.name),
    ),
  );

  useEffect(() => {
    if (filterBarOptions?.disableAddingFilteredFields) {
      setMenuOptions(
        FilterBarFactory.getMenuOptions(
          defaultFields,
          fields,
          filter,
          filterableFields,
        ),
      );
    } else {
      setMenuOptions(
        filterableFields?.sort((a, b) =>
          (a.label || a.name).localeCompare(b.label || b.name),
        ),
      );
    }
  }, [fields, filter, JSON.stringify(filterableFields), defaultFields]);

  const handleClickFilter = ({ currentTarget }, key) => {
    setPopoverContentKey(key);
    setFilterAnchorEl(currentTarget);
  };

  const handleSubmit = (newFields) => {
    setFilter(FilterBarFactory.filterFromFields(newFields, searchWildcard));
    setFilterAnchorEl(null);
    setMenuAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleCloseFilter = () => {
    setFilterAnchorEl(null);
    handleSubmit(fields);
  };

  const setFieldsGuard = (incomingState, blurred?: boolean) => {
    setFields(incomingState);

    if (blurred) {
      handleSubmit(incomingState);
    }
  };

  const fieldIsFiltered = (fieldKey: string) => {
    const value = fields[fieldKey]?.value;
    return Array.isArray(value) ? value.length > 0 : !!value;
  };

  const proxyRemoveFilter = () => {
    instrumentOn?.({
      action: 'clear filter',
    });
    removeFilter?.();
  };

  const removeFilterByField = (event, fieldKey: string) => {
    event.stopPropagation();
    const conditionIndex = filter?.conditions?.findIndex(
      ({ fieldId }) => fieldId === fieldKey,
    );

    if (conditionIndex > -1) {
      const removedCondition = filter?.conditions?.[conditionIndex];
      instrumentOn?.({
        action: 'remove filter',
        properties: {
          removedCondition,
        },
      });
    }

    const modifiedFilter = {
      ...filter,
      conditions: filter?.conditions?.filter(
        ({ fieldId }) => fieldId !== fieldKey,
      ),
    };

    if (Array.isArray(fields[fieldKey]?.orderBy) && dsOnSort) {
      dsOnSort([]);
    }

    const filterToSet =
      modifiedFilter.conditions?.length > 0 ? modifiedFilter : {};
    setFilter(filterToSet as Filter);

    delete fields[fieldKey];
    setFields({ ...fields });
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuSelection = (key) => {
    const newField = FilterBarFactory.keyToField(
      key,
      filterableFields,
      filterBarOptions,
    );
    const newFieldKey = uuid();

    if (newField) {
      setFields({ ...fields, [newFieldKey]: newField });
    }
    handleCloseMenu();

    autoOpenKey.current = newFieldKey;
  };

  const [addField, setAddField] = React.useState<{
    name: string;
  } | null>(null);

  useEffect(() => {
    if (addField) {
      handleMenuSelection(addField.name);
      setAddField(null);
    }
  }, [addField]);

  useEffect(() => {
    if (autoOpenKey.current) {
      handleClickFilter(
        {
          currentTarget: document.getElementById(`${autoOpenKey.current}-chip`),
        },
        autoOpenKey.current,
      );
      autoOpenKey.current = undefined;
    }
  }, [fields]);

  const setVanityFilter = (e, key) => {
    setFieldsGuard(
      {
        ...fields,
        [key]: {
          ...fields[key],
          value: !fields[key]?.value,
        },
      },
      true,
    );
  };

  if (!relationshipQCFlag && filterableFields)
    filterableFields
      .filter((field) => field.hasOwnProperty('relationshipQueryCallback'))
      .forEach(async (field) => {
        const currentFieldKey = Object.keys(fields).find(
          (key) => fields[key].dataKey === field.name,
        );
        if (
          field.relationshipQueryCallback &&
          currentFieldKey &&
          fields[currentFieldKey]?.options?.length === 0
        ) {
          try {
            const value = [...fields[currentFieldKey].value];
            const fieldOptions = await field.relationshipQueryCallback(
              '',
              value,
            );

            setFields({
              ...fields,
              [currentFieldKey]: {
                ...fields[currentFieldKey],
                options: fieldOptions,
              },
            });
          } catch (err) {
            console.error(err);
          } finally {
            setRelationshipQCFlag(true);
          }
        }
      });

  const filteredMenuOptions = menuOptions?.filter(
    (option) =>
      !Object.values(fields).find((field) => field.dataKey === option.name),
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        padding: `${theme.spacing(0.5)} 0`,
      }}
    >
      <Box
        sx={{
          flexWrap: 'wrap',
          padding: 0,
          display: 'flex',
          margin: 'auto',
          width: '100%',
          flex: 1,
          alignItems: 'center',

          '& .MuiChip-root': {
            maxWidth: '250px',
          },

          '&& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
            padding: `${theme.spacing(0.5)} 0`,
          },
        }}
      >
        {enableFilterBarSearch && (
          <SearchFilterComponent
            {...(e2e && { e2e: `${e2e}-table-filter-bar-search` })}
            stateKey={SEARCH_KEY}
            state={fields}
            filterableFields={filterableFields}
            stateChangeFn={setFieldsGuard}
            dsOnSearchChange={dsOnSearchChange}
            dsOnBlur={() => handleSubmit(fields)}
            dsOnSearchOperatorChange={dsOnSearchOperatorChange}
            dsOnChangeIncludeCustomFields={dsOnChangeIncludeCustomFields}
            filterBarOptions={filterBarOptions}
            searchPlaceholder={searchPlaceholder}
            includeCustomFields={includeCustomFields}
            setIncludeCustomFields={setIncludeCustomFields}
            searchMode={searchMode}
            setSearchMode={setSearchMode}
          />
        )}

        {quickFilters && (
          <QuickFilterComponent
            quickFilters={quickFilters}
            setFilter={setFilter}
            setFields={setFields}
            dsOnSort={dsOnSort}
            filterableFields={filterableFields}
            vanityFilters={vanityFilters}
            defaultFields={defaultFields}
            orderBy={orderBy}
            filter={filter}
            filterBarOptions={filterBarOptions}
            searchWildcard={searchWildcard}
          />
        )}

        {FilterBarFactory.getFilterChipIterable(fields)
          ?.filter(([key]) => key !== SEARCH_KEY)
          ?.map(([key, value]) => {
            const isVanity = fields[key]?.type === FilterDataTypes.VANITY;
            const isPicklist = fields[key]?.type === FilterDataTypes.PICKLIST;
            const isString = fields[key]?.type === FilterDataTypes.STRING;
            const isValue = fields[key]?.value?.length > 0;
            const isTooltip = (isPicklist || isString) && isValue;
            const handleClick = isVanity
              ? (e) => setVanityFilter(e, key)
              : (e) => handleClickFilter(e, key);

            return (
              <Tooltip
                key={`${key}-chip-tooltip`}
                arrow
                disableHoverListener={!isTooltip}
                disableTouchListener={!isTooltip}
                title={
                  FilterBarFactory.getFilterChipLabel(
                    fields,
                    filterableFields,
                    key,
                    translate,
                    isTooltip,
                    cachedSelections,
                  ) || ''
                }
                PopperProps={{
                  ...(e2e && {
                    'data-e2e': `${e2e}-chip-tooltip`,
                  }),
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ],
                }}
              >
                <div id={`${key}-chip`} key={`${key}-chip`}>
                  <ButtonFilter
                    key={key}
                    {...(e2e && { e2e: `${e2e}-table-filter-bar` })}
                    a11yDescribedby={filterId}
                    state={fieldIsFiltered(key) ? 'indeterminate' : 'default'}
                    dsOnClick={handleClick}
                    body={
                      <StyledFilter>
                        <StyledFilterLabel>
                          {FilterBarFactory.getFilterChipLabel(
                            fields,
                            filterableFields,
                            key,
                            translate,
                            false,
                            cachedSelections,
                          )}
                        </StyledFilterLabel>
                        {isVanity ? (
                          <Icon
                            body="check"
                            className="materialIcon"
                            hidden={!fields[key]?.value}
                            color="inherit"
                          />
                        ) : (
                          <Icon
                            className="materialIcon"
                            body="keyboard_arrow_down"
                          />
                        )}
                      </StyledFilter>
                    }
                    size="small"
                    endIcon="cancel"
                    {...(!(value as unknown as VanityFilterField).id &&
                      !value.default && {
                        dsOnDelete: (e) => removeFilterByField(e, key),
                      })}
                  />
                </div>
              </Tooltip>
            );
          })}

        <Popover
          {...(e2e && { 'data-e2e': `${e2e}-close-filter` })}
          id={filterId}
          open={filterOpen}
          anchorEl={filterAnchorEl}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <StyledPopoverContent>
            {popoverContentKey && (
              <FilterComponentFactory
                {...(e2e && { e2e: `${e2e}-table-filter-header` })}
                type={fields[popoverContentKey]?.type}
                key={popoverContentKey}
                stateKey={popoverContentKey}
                state={fields}
                filterableField={filterableFields.find(
                  (field) => field.name === fields[popoverContentKey]?.dataKey,
                )}
                stateChangeFn={setFieldsGuard}
                dsOnBlur={() => handleSubmit(fields)}
                cachedSelections={cachedSelections[popoverContentKey]}
                setCachedSelections={(value) =>
                  setCachedSelections({
                    ...cachedSelections,
                    [popoverContentKey]: value,
                  })
                }
              />
            )}
          </StyledPopoverContent>
        </Popover>
        {!filterBarOptions?.disableFilterBarAdd &&
          filteredMenuOptions?.length > 0 && (
            <>
              <Button
                {...(e2e && { e2e: `${e2e}-table-filter-more-button` })}
                a11yHaspopup
                a11yDescribedby={menuId}
                dsOnClick={handleOpenMenu}
                startIcon="add"
                variant="text"
                body={translate('FilterBar_More')}
                disabled={filterableFields.length < 1}
              />
              <Popover
                {...(e2e && { 'data-e2e': `${e2e}-table-filter-menu` })}
                id={menuId}
                open={menuOpen}
                anchorEl={menuAnchorEl}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <>
                  {filteredMenuOptions ? (
                    <>
                      <StyledMenuList>
                        <MenuList>
                          {filteredMenuOptions.map((option) => (
                            <StyledMenuItem
                              {...(e2e && {
                                'data-e2e': `${e2e}-menu-item-${option.name}`,
                              })}
                              key={option.name}
                              onClick={() => {
                                setAddField(option);
                              }}
                            >
                              <ListItemText
                                primary={option.label || option.name}
                              />
                            </StyledMenuItem>
                          ))}
                        </MenuList>
                      </StyledMenuList>
                    </>
                  ) : (
                    <StyledSpinnerContainer>
                      <Spinner />
                    </StyledSpinnerContainer>
                  )}
                </>
              </Popover>
            </>
          )}
      </Box>

      {isShowClearFilterButton && (
        <Box
          sx={{ display: 'flex', margin: 'auto', padding: theme.spacing(1) }}
        >
          <Button
            {...(e2e && { e2e: `${e2e}-table-filter-clear` })}
            variant="text"
            dsOnClick={proxyRemoveFilter}
            body={translate('FilterBar_Clear')}
          />
        </Box>
      )}
    </Box>
  );
};
