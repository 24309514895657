import * as React from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material';

export interface TooltipProps
  extends Pick<MUITooltipProps, 'title' | 'open' | 'children' | 'placement'> {
  dsOnClose: MUITooltipProps['onClose'];
  dsOnOpen: MUITooltipProps['onOpen'];
  e2e: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  open,
  e2e,
  dsOnClose,
  dsOnOpen,
  children,
  placement,
}: TooltipProps) => (
  <MuiTooltip
    {...(e2e && { 'data-e2e': e2e })}
    title={title}
    open={open}
    onOpen={dsOnOpen}
    onClose={dsOnClose}
    placement={placement}
    arrow
    componentsProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -4],
            },
          },
        ],
      },
      tooltip: {
        sx: { paddingY: '8px', paddingX: '12px' },
      },
    }}
  >
    <div
      style={{ display: 'inline-flex' }}
      {...(e2e && { 'data-e2e': `${e2e}-body` })}
    >
      {children}
    </div>
  </MuiTooltip>
);

export default Tooltip;
