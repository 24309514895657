import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps as MUIDateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import {
  LocalizationProvider,
  LocalizationProviderProps,
} from '@mui/x-date-pickers/LocalizationProvider';
import makeClass from 'classnames';
import { DateTime } from 'luxon';
import * as React from 'react';
import { LocaleData, useLocaleConfig } from '../../utils';
import { dateGuard } from '../utils';

export interface DateTimePickerProps
  extends Pick<
    MUIDateTimePickerProps<
      string | DateTime | Date | undefined,
      string | DateTime | Date
    >,
    | 'label'
    | 'value'
    | 'disabled'
    | 'minDate'
    | 'minDateTime'
    | 'maxDate'
    | 'maxDateTime'
    | 'PopperProps'
  > {
  dsOnChange: MUIDateTimePickerProps<
    string | DateTime | Date | undefined,
    string | DateTime | Date
  >['onChange'];
  dsOnClose?: MUIDateTimePickerProps<
    string | DateTime | Date | undefined,
    string | DateTime | Date
  >['onClose'];
  format?: MUIDateTimePickerProps<
    string | DateTime | Date | undefined,
    string | DateTime | Date
  >['inputFormat'];
  /** Locale defaults to the locale of the Theme. It is unlikely you will need to set this property.  */
  locale?: LocalizationProviderProps['adapterLocale'];
  /** @deprecated Language is set by locale. This will not be used. **/
  language?: string;
  /** Uses AdapterLuxon by default. It is unlikely you will need to set this property. */
  dateAdapter?: LocalizationProviderProps['dateAdapter'];
  e2e?: string;
  dsOnBlur?: TextFieldProps['onBlur'];
  error?: TextFieldProps['error'];
  name?: TextFieldProps['name'];
  fullWidth?: TextFieldProps['fullWidth'];
  helperText?: TextFieldProps['helperText'];
  required?: TextFieldProps['required'];
  readonly?: boolean;
  inputSx?: TextFieldProps['sx'];
}

const DateTimePicker = React.forwardRef<HTMLInputElement, DateTimePickerProps>(
  (
    {
      dsOnChange,
      dsOnClose,
      dsOnBlur,
      error,
      fullWidth,
      helperText,
      label,
      locale,
      format,
      dateAdapter,
      value,
      name,
      e2e,
      disabled,
      minDate,
      maxDate,
      minDateTime,
      maxDateTime,
      required,
      readonly,
      PopperProps,
      inputSx,
    },
    ref,
  ) => {
    const theme = useTheme();
    dateAdapter = dateAdapter || AdapterLuxon;
    locale = locale || theme.locale;
    const localeConfig = useLocaleConfig();
    const localeData: LocaleData =
      localeConfig[locale?.toString()?.replace('_', '-') || 'en-US'];
    format = format || `${localeData?.format} hh:mm a`;

    return (
      <LocalizationProvider adapterLocale={locale} dateAdapter={dateAdapter}>
        <MUIDateTimePicker
          inputRef={ref}
          inputFormat={format}
          label={label}
          readOnly={readonly}
          onChange={dsOnChange}
          onClose={dsOnClose}
          value={value}
          disabled={disabled}
          minDateTime={dateGuard(minDateTime)}
          maxDateTime={dateGuard(maxDateTime)}
          minDate={dateGuard(minDate)}
          maxDate={dateGuard(maxDate)}
          {...(e2e && {
            'data-e2e': e2e,
            inputProps: {
              'data-e2e': `${e2e}-input`,
            },
            InputLabelProps: {
              'data-e2e': `${e2e}-label`,
            },
          })}
          PopperProps={PopperProps}
          renderInput={(params) => (
            <TextField
              {...params}
              className={makeClass({ ReadOnly: readonly })}
              fullWidth={fullWidth}
              onBlur={dsOnBlur}
              error={error}
              name={name}
              required={required}
              helperText={helperText}
              variant="outlined"
              sx={inputSx}
            />
          )}
        />
      </LocalizationProvider>
    );
  },
);

export default DateTimePicker;
