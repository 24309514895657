import * as React from 'react';
import styled from '@emotion/styled';
import {
  TableCell,
  TableRow,
  IconButton,
  Checkbox,
  Button,
  ButtonGroup,
  useTheme,
} from '@mui/material';
import Icon from '../Icon';
import { designTokens } from '../Theme.design-tokens';

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  button: {
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  font-size: 12px;
`;

interface GroupRowProps {
  e2e?: string;
  colSpan: number;
  expanded: boolean;
  disabled: boolean;
  hidden: boolean;
  groupKey: string;
  isSelectable: boolean;
  isIndeterminate: boolean;
  isSelected: boolean;
  hideCheckbox?: boolean;
  children: React.ReactNode;
  showGroupedRowsControls: boolean;
  onCheckboxClick: (groupKey: string, event: any) => void;
  onToggleHeaderRow: () => void;
  onExpandAll: (groupKey: string) => void;
  onCollapseAll: (groupKey: string) => void;
  resetPagination: () => void;
  fixedGroupHeader?: boolean;
  hasExpandItem?: boolean;
}

export const GroupRow = ({
  e2e,
  colSpan,
  expanded,
  disabled,
  hidden,
  groupKey,
  isSelectable,
  isIndeterminate,
  isSelected,
  children,
  showGroupedRowsControls,
  onCheckboxClick,
  onToggleHeaderRow,
  onExpandAll,
  onCollapseAll,
  hideCheckbox = false,
  resetPagination,
  fixedGroupHeader = false,
  hasExpandItem,
}: GroupRowProps) => {
  const theme = useTheme();

  const showCheckbox = isSelectable && !hideCheckbox;

  const handleExpand = React.useCallback(
    (e) => {
      e.stopPropagation();
      onExpandAll(groupKey);
    },
    [groupKey, onExpandAll],
  );

  const handleCollapse = React.useCallback(
    (e) => {
      e.stopPropagation();
      onCollapseAll(groupKey);
    },
    [groupKey, onCollapseAll],
  );

  const onToggleClick = () => {
    disabled && resetPagination();
    onToggleHeaderRow();
  };

  if (hidden) return null;

  return (
    <TableRow
      {...(e2e && { 'data-e2e': e2e })}
      sx={{ backgroundColor: `${designTokens.colors.navy100}40` }}
      hover={!disabled}
    >
      {showCheckbox && (
        <TableCell
          padding="checkbox"
          style={{ paddingLeft: theme.spacing(0.5), paddingRight: 0 }}
        >
          <Checkbox
            color="primary"
            checked={isSelected}
            indeterminate={isIndeterminate}
            onClick={(e) => {
              e.stopPropagation();
              onCheckboxClick(groupKey, e);
            }}
            inputProps={
              {
                ...(e2e && { 'data-e2e': `${e2e}-checkbox` }),
              } as any
            }
          />
        </TableCell>
      )}
      <StyledTableCell
        colSpan={showCheckbox ? colSpan - 1 : colSpan}
        style={{ paddingBottom: 0, paddingTop: 0 }}
      >
        {hasExpandItem && (
          <IconButton
            {...(e2e && { 'data-e2e': `${e2e}-button` })}
            color="primary"
            size="small"
            style={{
              maxWidth: theme.spacing(2.5),
              maxHeight: theme.spacing(2.5),
              ...(fixedGroupHeader && {
                visibility: 'hidden',
                marginLeft: theme.spacing(-2),
              }),
            }}
            onClick={() => onToggleClick()}
          >
            <Icon body={expanded ? 'arrow_drop_down' : 'arrow_right'} />
          </IconButton>
        )}

        {children}

        {showGroupedRowsControls && (
          <ButtonGroup
            variant="text"
            color="primary"
            size="small"
            aria-label="Group row control buttons"
            style={{ marginLeft: 10 }}
          >
            <StyledButton onClick={handleExpand} aria-label="Expand all">
              Expand all
            </StyledButton>

            <StyledButton onClick={handleCollapse} aria-label="Collapse all">
              Collapse all
            </StyledButton>
          </ButtonGroup>
        )}
      </StyledTableCell>
    </TableRow>
  );
};
