import React from 'react';
import styled from '@emotion/styled';
import { TypeAhead } from '../../..';
import { FilterComponentProps } from './FilterComponentFactory';
import { LegacyFilterFactory } from '../utils';

const OperatorContainer = styled('div')`
  margin-top: 1.55rem;
`;

export const OperatorComponent = ({
  stateKey,
  state,
  stateChangeFn,
  operators,
  dsComponentProps,
}: FilterComponentProps) => {
  return (
    <OperatorContainer>
      <TypeAhead
        e2e={`${dsComponentProps.e2e}-operator`}
        disableClearable
        options={operators?.sort((a, b) => a.localeCompare(b)) || []}
        value={state[stateKey].operator}
        dsOnChange={(e, val) =>
          stateChangeFn({
            ...state,
            [stateKey]: {
              ...state[stateKey],
              operator: val,
            },
          })
        }
        getOptionLabel={(operator) =>
          LegacyFilterFactory.operatorLabelMap[operator]
        }
      />
    </OperatorContainer>
  );
};
