import React from 'react';
import { TypeAhead } from '../../..';
import { FilterBarFactory } from '../utils';
import { FilterComponentProps } from './FilterComponentFactory';
import { useTranslate } from '../../../i18n';

export const OperatorComponent = ({
  stateKey,
  state,
  stateChangeFn,
  operators,
  dsComponentProps,
  dsOnBlur,
}: FilterComponentProps) => {
  const { translate } = useTranslate();
  return (
    <TypeAhead
      e2e={`${dsComponentProps.e2e}-operator`}
      disableClearable
      options={operators?.sort((a, b) => a.localeCompare(b)) || []}
      value={state[stateKey].operator}
      dsOnChange={(e, val) => {
        stateChangeFn({
          ...state,
          [stateKey]: {
            ...state[stateKey],
            operator: val,
          },
        });
        dsOnBlur?.();
      }}
      getOptionLabel={(operator) =>
        translate(FilterBarFactory.operatorLabelMap[operator])
      }
    />
  );
};
