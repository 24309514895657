import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';

export const StyledMenuList = styled.div`
  max-height: 360px;
  overflow-x: hidden;
`;

export const StyledMenuItem = styled(MenuItem)`
  & > div > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
export const StyledSpinnerContainer = styled.div`
  text-align: center;
  padding: 1rem;
`;
