import {
  Box,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  useTheme,
} from '@mui/material';
import React from 'react';
import Typography from '../Typography';
import { designTokens } from '../index';

export interface MenuItemProps extends MuiMenuItemProps {
  itemRightContent?: (item) => React.ReactNode;
  description?: string;
  stylesForDescription?: { [name: string]: string };
}

const MenuItem: React.FC<MenuItemProps> = ({
  itemRightContent,
  description,
  children,
  stylesForDescription,
  ...rest
}) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const RenderContent = (): React.ReactElement =>
    children ? (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>{children}</Box>
    ) : (
      <Typography>{rest.value}</Typography>
    );

  return (
    <MuiMenuItem {...rest}>
      {description || itemRightContent ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <RenderContent />
            {description && (
              <Box
                sx={{
                  ...(stylesForDescription && { ...stylesForDescription }),
                }}
              >
                <Typography
                  component="div"
                  variant="caption"
                  color={
                    isDark
                      ? designTokens.colors.darkEmphasisMedium
                      : designTokens.colors.lightEmphasisMedium
                  }
                  noWrap
                >
                  {description}
                </Typography>
              </Box>
            )}
          </Box>
          {itemRightContent && (
            <Box
              sx={{
                display: 'flex',
                marginLeft: '10px',
              }}
            >
              {itemRightContent(rest.value)}
            </Box>
          )}
        </Box>
      ) : (
        children || <Typography>{rest.value}</Typography>
      )}
    </MuiMenuItem>
  );
};

export default MenuItem;
