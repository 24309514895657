import * as React from 'react';
import styled from '@emotion/styled';
import { transientOptions } from '../utils';
import {
  TableCell,
  TableRow,
  IconButton,
  Checkbox,
  useTheme,
} from '@mui/material';
import Icon from '../Icon';
import {
  TableProps,
  ColumnType,
  DataType,
  ValueType,
  RowActionType,
  StyledStickyCell,
  CustomRowEditAction,
  isGetActionData,
} from './index';
import { Cell } from './Cell';
import { Shimmer } from '../Shimmer';
import ActionsList, { ActionListItem, ActionsWrapper } from '../ActionsList';
import { HighlightColumnMap, RowDisplayUtils } from './hooks';

const useMasterDetail = ({
  uniqueId,
  dsRenderMasterDetail,
}: {
  uniqueId: string;
  dsRenderMasterDetail: TableProps['dsRenderMasterDetail'];
}) => {
  const isUsingWebcomponents = React.useRef(false);
  const masterDetailRef = React.useRef<HTMLDivElement>(null);
  const masterDetailParentRef = React.useRef<Element>();
  const [isMasterDetailOpen, setIsMasterDetailOpen] = React.useState(false);

  const toggleMasterDetail = () => {
    if (!isUsingWebcomponents.current && !dsRenderMasterDetail) {
      return;
    }

    if (!!isUsingWebcomponents.current) {
      const collection = document.getElementsByTagName(
        'ds-table-master-detail',
      );
      const masterDetailNode = Array.from(collection).find((row) => {
        return (row as any)?.uniqueKey === uniqueId;
      });

      if (!masterDetailParentRef.current && masterDetailNode?.parentElement) {
        masterDetailParentRef.current = masterDetailNode.parentElement;
      }

      if (masterDetailRef.current && masterDetailNode) {
        masterDetailRef.current.appendChild(masterDetailNode);
      }

      // if master detail is about to be visible
      if (!isMasterDetailOpen === true) {
        (masterDetailNode as any).show();
      }
    }

    setIsMasterDetailOpen(!isMasterDetailOpen);
  };

  React.useEffect(() => {
    if (!!document.getElementsByTagName('ds-table-master-detail').length) {
      isUsingWebcomponents.current = true;
    }

    return () => {
      if (isUsingWebcomponents.current && masterDetailParentRef.current) {
        const child = masterDetailRef.current?.firstChild;

        if (child) {
          (child as any).hide();
          masterDetailParentRef.current.appendChild(child);
        }
      }
    };
  }, []);

  return {
    isUsingWebcomponents,
    toggleMasterDetail,
    isMasterDetailOpen,
    masterDetailRef,
    isMasterDetailClickable:
      !!isUsingWebcomponents.current || !!dsRenderMasterDetail,
  };
};

const StyledTableRow = styled(TableRow, transientOptions)<{
  $isclickable: string;
  $visible?: boolean;
  $isEditableRow?: boolean;
  $hideBorderForLastRow?: boolean;
}>`
  transform: scale(1);
  visibility: ${({ $visible = true }) => ($visible ? 'visible' : 'hidden')};
  & td {
    cursor: ${({ $isclickable }) => ($isclickable ? 'pointer' : 'auto')};
  }
  ${({ $hideBorderForLastRow }) =>
    $hideBorderForLastRow
      ? `&:last-child td {
          border-bottom: none;
        }`
      : ``}
`;

const StyledActionsWrapper = styled('div')`
  display: flex;
  justify-content: right;
`;

const StyledMasterDetail = styled(TableCell)<{
  $expanded?: string;
}>`
  border-bottom: ${({ $expanded }) => ($expanded ? 'auto' : 0)} !important;

  & div {
    overflow: hidden;
    max-height: ${({ $expanded }) => ($expanded ? '500px' : '0px')};
  }
`;

interface RowProps {
  /**
   * @deprecated dense should not be used
   */
  dense?: boolean;
  index: number;
  e2e?: string;
  hasOverflow?: boolean;
  hasOverflowLeft?: boolean;
  overflowColor?: string;
  columns: ColumnType[];
  colSpan: number;
  rowActions: RowActionType[];
  row: DataType;
  rows: DataType[];
  editableRows?: { [key: string]: DataType };
  nestedRowsMap: { [key: string]: DataType[] };
  editableRow?: DataType;
  uniqueKey: string;
  indentLevel?: number;
  isEditable: boolean;
  isSelected: (uniqueValue: string) => boolean;
  isIndeterminate: (uniqueValue: string) => boolean;
  isSelectable: boolean;
  isExpanded: (uniqueValue: string) => boolean;
  onToggleRowExpand?: (uniqueValue: string) => void;
  dsRenderMasterDetail: TableProps['dsRenderMasterDetail'];
  dsIsRowSelectable?: TableProps['dsIsRowSelectable'];
  dsIsRowSelectionDisabled?: TableProps['dsIsRowSelectionDisabled'];
  dsIsCellEditable?: TableProps['dsIsCellEditable'];
  onCheckboxClick: (row: DataType, value: ValueType) => void;
  onInputChange: (args: {
    value: ValueType;
    column: ColumnType;
    row: DataType;
  }) => void;
  onToggleRowEdit: (row: DataType) => void;
  dsCustomRowEditAction?: CustomRowEditAction;
  isGlobalEditModeEnabled: TableProps['isGlobalEditModeEnabled'];
  onInputBlur?: (args: {
    column: ColumnType;
    row: DataType;
    editableRow?: DataType;
  }) => void;
  editingColumnRow: string;
  hideBorderForLastRow?: boolean;
  setEditingColumnRow: (string) => void;
  truncatedCell?: TableProps['truncatedCell'];
  highlightColumnMap: HighlightColumnMap;
  hasExpandItem?: boolean;
  rowDisplayUtils: RowDisplayUtils;
}

export const Row = ({
  dense,
  e2e,
  index,
  hasOverflow,
  hasOverflowLeft,
  overflowColor,
  columns,
  colSpan,
  rowActions,
  row,
  rows,
  editableRows,
  editableRow: editableRowData,
  nestedRowsMap,
  isGlobalEditModeEnabled,
  uniqueKey,
  indentLevel = 0,
  isEditable,
  isSelected,
  isIndeterminate,
  isSelectable,
  isExpanded,
  onToggleRowExpand,
  dsRenderMasterDetail,
  dsIsRowSelectable,
  dsIsRowSelectionDisabled,
  dsIsCellEditable,
  onCheckboxClick,
  onInputChange,
  onToggleRowEdit,
  dsCustomRowEditAction,
  onInputBlur,
  editingColumnRow,
  setEditingColumnRow,
  truncatedCell,
  hideBorderForLastRow,
  highlightColumnMap,
  hasExpandItem,
  rowDisplayUtils,
}: RowProps) => {
  const theme = useTheme();

  const actionsRef = React.useRef<HTMLDivElement>(null);
  const [hydratedRowActions, setHydratedRowActions] = React.useState<
    ActionListItem[]
  >([]);
  const [elementRowActions, setElementRowActions] = React.useState<
    React.ReactNode[]
  >([]);

  React.useEffect(() => {
    if (rowActions) {
      setHydratedRowActions(
        rowActions
          .map((action) => {
            return isGetActionData(action)
              ? action.dsGetActionData(row)
              : action;
          })
          .filter(Boolean)
          .filter(
            (action) => !React.isValidElement(action),
          ) as ActionListItem[],
      );

      setElementRowActions(
        rowActions
          .map((action) => {
            return isGetActionData(action)
              ? action.dsGetActionData(row)
              : action;
          })
          .filter(Boolean)
          .filter((action) =>
            React.isValidElement(action),
          ) as React.ReactNode[],
      );
    }
  }, [rowActions]);

  const uniqueId = row[uniqueKey] as string;

  const rowStyles = rowDisplayUtils.getRowStyle(index, uniqueId);

  const isItemSelected = isSelected(uniqueId);

  const isItemIndeterminate = isIndeterminate(uniqueId);

  const isItemExpanded = isExpanded(uniqueId);

  const rowChildren = nestedRowsMap[uniqueId];

  const editableRow = isGlobalEditModeEnabled
    ? editableRows && editableRows[uniqueId]
    : editableRowData;

  const isEditableRow = !!editableRow && editableRow[uniqueKey] === uniqueId;

  const hasActions = !!hydratedRowActions?.length;

  const hasElementActions = !!elementRowActions?.length;

  const isRowSelectionDisabled = !!dsIsRowSelectionDisabled
    ? dsIsRowSelectionDisabled({ row })
    : false;

  const isRowSelectable = !!dsIsRowSelectable
    ? dsIsRowSelectable({
        row,
        children: rowChildren,
      })
    : true;

  const {
    toggleMasterDetail,
    isMasterDetailOpen,
    masterDetailRef,
    isMasterDetailClickable,
    isUsingWebcomponents,
  } = useMasterDetail({
    uniqueId,
    dsRenderMasterDetail,
  });

  const handleRowClick = () => {
    toggleMasterDetail();
    rowDisplayUtils.setHighlightRowKey(uniqueId);
  };

  // check the custom edit action to see if the row should be editable
  const renderEditAction = dsCustomRowEditAction
    ? dsCustomRowEditAction.dsIsActionVisible({ row })
    : true;

  const getCellValue = (column): ValueType => {
    return isEditableRow ? editableRow?.[column.field] : row[column.field];
  };

  const isCellEditableExist = (value, column, columnIndex): boolean => {
    return dsIsCellEditable
      ? dsIsCellEditable({ column, row, value, columnIndex }) && isEditableRow
      : isEditableRow;
  };

  const hasCellEditable = columns.some((column, columnIndex) => {
    if (column.hidden) return false;
    const value = getCellValue(column);
    const isCellEditable = isCellEditableExist(value, column, columnIndex);
    return isCellEditable;
  });

  return (
    <>
      <StyledTableRow
        tabIndex={-1}
        aria-checked={isItemIndeterminate ? 'mixed' : isItemSelected}
        onClick={handleRowClick}
        $isclickable={isMasterDetailClickable ? 'true' : ''}
        $isEditableRow={hasCellEditable}
        $hideBorderForLastRow={hideBorderForLastRow}
        sx={rowStyles}
      >
        {isSelectable && (
          <TableCell
            padding="checkbox"
            style={{
              width: 40,
              paddingLeft: theme.spacing(0.5),
              paddingRight: 0,
            }}
          >
            {isRowSelectable && (
              <Checkbox
                color="primary"
                checked={isItemSelected}
                disabled={isRowSelectionDisabled}
                indeterminate={isItemIndeterminate}
                onClick={(e) => {
                  e.stopPropagation();
                  onCheckboxClick(row, uniqueId);
                }}
                inputProps={
                  {
                    ...(e2e && { 'data-e2e': `${e2e}-checkbox` }),
                  } as any
                }
              />
            )}
          </TableCell>
        )}

        {columns.map((column, columnIndex) => {
          if (column.hidden) return null;

          if (column.loading) {
            return (
              <TableCell key={`${column.field}-${columnIndex}`}>
                <Shimmer />
              </TableCell>
            );
          }

          const value = getCellValue(column);

          const isCellEditable = isCellEditableExist(
            value,
            column,
            columnIndex,
          );

          return (
            <Cell
              dense={dense}
              // Column width is included in key to force re-mounting to re-compute
              // cell overflow to conditionally display tooltips
              key={`table-body-row-column-${columnIndex}-${column.width}`}
              {...(e2e && { e2e: `${e2e}-col-${column.field}` })}
              value={value}
              column={column}
              uniqueKey={uniqueKey}
              uniqueValue={uniqueId as string}
              isEditable={isCellEditable}
              editableRowUniqueValue={
                editableRow && String(editableRow[uniqueKey])
              }
              onInputChange={onInputChange}
              align={column.alignment || 'left'}
              verticalAlign={column.verticalAlign || 'inherit'}
              row={row}
              onInputBlur={onInputBlur}
              editableRow={editableRow}
              editingColumnRow={editingColumnRow}
              setEditingColumnRow={setEditingColumnRow}
              isGlobalEditModeEnabled={isGlobalEditModeEnabled}
              truncatedCell={truncatedCell}
              stickyLeft={hasOverflowLeft}
              stickyRight={
                hasOverflow && {
                  backgroundColor: 'inherit',
                  right: (hasOverflow
                    ? actionsRef.current?.offsetWidth
                    : 0) as number,
                }
              }
              isExpandCollapseColumn={hasExpandItem && columnIndex === 0}
              hasRowChildren={!!rowChildren}
              onToggleRowExpand={onToggleRowExpand}
              isItemExpanded={isItemExpanded}
              indentLevel={indentLevel}
              sx={{
                backgroundColor: `${
                  highlightColumnMap[column.field]
                } !important`,
              }}
            />
          );
        })}

        <StyledStickyCell
          data-chromatic="ignore"
          ref={actionsRef}
          $hasOverflow={hasOverflow}
          $overflowColor={overflowColor}
          $isRowActions={!!hydratedRowActions.length}
        >
          <StyledActionsWrapper>
            {!isGlobalEditModeEnabled && isEditable && renderEditAction && (
              <IconButton
                {...(e2e && {
                  'data-e2e': `${e2e}-saveOrEditAction`,
                })}
                aria-label={isEditableRow ? 'save' : 'edit'}
                color="primary"
                size="small"
                style={{
                  width: 40,
                  height: 40,
                }}
                onClick={(e) => {
                  e.stopPropagation();

                  const toggleFn = isEditableRow
                    ? dsCustomRowEditAction?.dsUseDefaultSave
                    : dsCustomRowEditAction?.dsUseDefaultEdit;

                  if (toggleFn) {
                    const shouldToggle = toggleFn({
                      row,
                    });
                    if (shouldToggle) {
                      onToggleRowEdit(row);
                    }
                    return;
                  }

                  onToggleRowEdit(row);
                }}
              >
                <Icon body={isEditableRow ? 'save' : 'edit'} />
              </IconButton>
            )}

            {hasActions && (
              <ActionsList
                e2e={e2e}
                actions={hydratedRowActions}
                context={{ row }}
                isRowActions={!!hydratedRowActions.length}
              />
            )}
            {hasElementActions && (
              <ActionsWrapper>
                {elementRowActions.map((action, index) => (
                  <React.Fragment key={`ele-action${index}`}>
                    {action}
                  </React.Fragment>
                ))}
              </ActionsWrapper>
            )}
          </StyledActionsWrapper>
        </StyledStickyCell>
      </StyledTableRow>

      {(dsRenderMasterDetail || isUsingWebcomponents.current) &&
        isMasterDetailOpen && (
          <TableRow>
            <StyledMasterDetail
              colSpan={colSpan}
              $expanded={isMasterDetailOpen ? 'true' : ''}
              padding="none"
            >
              <div ref={masterDetailRef}>{dsRenderMasterDetail?.({ row })}</div>
            </StyledMasterDetail>
          </TableRow>
        )}

      {isItemExpanded &&
        rowChildren?.map((childRow) => {
          return (
            <Row
              {...(e2e && { e2e: `${e2e}-child-${childRow[uniqueKey]}` })}
              key={`table-body-row-child-${childRow[uniqueKey]}`}
              index={index} // TODO: change this to `childRowIndex` to enable alternatingColor on the nested rows, once UX delivers a design.
              dense={dense}
              row={childRow}
              rows={rows}
              editableRows={editableRows}
              nestedRowsMap={nestedRowsMap}
              columns={columns}
              colSpan={colSpan}
              rowActions={rowActions}
              editableRow={editableRow}
              isGlobalEditModeEnabled={isGlobalEditModeEnabled}
              uniqueKey={uniqueKey}
              highlightColumnMap={highlightColumnMap}
              indentLevel={indentLevel + 1}
              isEditable={isEditable}
              isSelected={isSelected}
              isIndeterminate={isIndeterminate}
              isSelectable={isSelectable}
              isExpanded={isExpanded}
              dsRenderMasterDetail={dsRenderMasterDetail}
              dsIsRowSelectable={dsIsRowSelectable}
              dsIsRowSelectionDisabled={dsIsRowSelectionDisabled}
              dsIsCellEditable={dsIsCellEditable}
              onCheckboxClick={onCheckboxClick}
              onInputChange={onInputChange}
              onToggleRowEdit={onToggleRowEdit}
              hasOverflow={hasOverflow}
              onToggleRowExpand={onToggleRowExpand}
              onInputBlur={onInputBlur}
              editingColumnRow={editingColumnRow}
              setEditingColumnRow={setEditingColumnRow}
              truncatedCell={truncatedCell}
              hasExpandItem={hasExpandItem}
              rowDisplayUtils={rowDisplayUtils}
            />
          );
        })}
    </>
  );
};
