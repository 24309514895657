import React from 'react';
import { FilterComponentProps, FilterWrapper } from './FilterComponentFactory';
import { Operator } from '../../types';
import { Box, Checkbox, MenuItem, MenuList, Typography } from '@mui/material';
import { ClearComponent } from './ClearComponent';

export const PicklistFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, filterableField, dsComponentProps } =
    props;

  const e2e = dsComponentProps?.e2e ?? null;

  const [selectedChoices, setSelectedChoices] = React.useState<
    string[] | null
  >();

  const initialValue = React.useRef<any>();

  React.useEffect(() => {
    if (Array.isArray(state[stateKey].value)) {
      setSelectedChoices(state[stateKey].value as any);
    } else if (!state[stateKey].value) {
      setSelectedChoices(null);
    }

    initialValue.current = state[stateKey].value || [];
  }, [state]);

  const stateChange = (value, blur = false) => {
    stateChangeFn(
      {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          value: value,
          operator: Operator.In,
        },
      },
      blur,
    );
  };

  React.useEffect(() => {
    return () => {
      stateChange(initialValue.current, true);
    };
  }, []);

  return (
    <>
      <FilterWrapper omitMargin>
        <MenuList sx={{ maxWidth: '300px' }}>
          {filterableField?.picklistOptions?.map((option, index) => {
            const label = typeof option === 'string' ? option : option.label;
            const description =
              typeof option === 'string' ? null : option.description;
            const value = typeof option === 'string' ? option : option.value;
            const choiceIndex =
              typeof value === 'string' &&
              Array.from(selectedChoices || []).indexOf(value);

            return (
              <MenuItem
                {...(e2e && { 'data-e2e': `${e2e}-menu-item` })}
                key={`${value}+${index}`}
                onClick={() => {
                  if (choiceIndex > -1) {
                    initialValue.current.splice(choiceIndex, 1);
                    stateChange(initialValue.current);
                  } else {
                    stateChange([...initialValue.current, value]);
                  }
                }}
                sx={{
                  ...(description && { alignItems: 'flex-start' }),
                }}
              >
                <Checkbox color="primary" checked={choiceIndex > -1} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    whiteSpace: 'initial',
                    overflowWrap: 'anywhere',
                  }}
                >
                  <Box>{label}</Box>
                  {description && (
                    <Typography variant="caption">{description}</Typography>
                  )}
                </Box>
              </MenuItem>
            );
          })}
        </MenuList>
      </FilterWrapper>
      {Array.from(selectedChoices || [])?.length > 0 && (
        <ClearComponent
          onClear={() => {
            stateChange([]);
          }}
        />
      )}
    </>
  );
};
