import * as React from 'react';
import { Menu, MenuProps, useTheme } from '@mui/material';

export interface DropdownProps extends Pick<MenuProps, 'anchorEl' | 'open'> {
  /**
   * Needed for accessibility. On the button invoking this dropdown,
   * set 'aria-controls' equal to this id.
   */
  a11yId: string;
  children?: React.ReactNode;
  e2e?: string;
  dsOnClose: MenuProps['onClose'];
}

const Dropdown: React.FC<DropdownProps> = ({
  children,
  anchorEl = null,
  open = false,
  dsOnClose,
  e2e,
}: DropdownProps) => {
  const theme = useTheme();
  const anchorOrigin = React.useRef<MenuProps['anchorOrigin']>({
    vertical: 'bottom',
    horizontal: 'left',
  });
  const transformOrigin = React.useRef<MenuProps['transformOrigin']>({
    vertical: 'top',
    horizontal: 'left',
  });

  if (!anchorEl) {
    return null;
  }

  return (
    <Menu
      anchorEl={anchorEl}
      sx={{ marginTop: theme.spacing(1) }}
      {...(e2e && { 'data-e2e': e2e })}
      anchorOrigin={anchorOrigin.current}
      transformOrigin={transformOrigin.current}
      open={open}
      onClose={dsOnClose}
    >
      {children}
    </Menu>
  );
};

export default Dropdown;
