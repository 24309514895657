import * as React from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import styled from '@emotion/styled';
import { designTokens } from '../../Theme.design-tokens';

export interface ButtonGroupProps extends Pick<BoxProps, 'sx'> {
  align?: 'center' | 'even' | 'left' | 'right';
  body?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  context?: 'floating';
  e2e?: string;
  variant?: 'separate' | 'split';
}

const alignmentMap = {
  center: 'center',
  even: 'space-evenly',
  left: 'flex-start',
  right: 'flex-end',
};

const StyledWrapper = styled.div`
  & .MuiButton-root {
    border-radius: 0;
  }
  & .MuiButton-root:first-child {
    border-radius: 4px 0 0 4px;
    border-right: 1px solid ${designTokens.colors.darkContextHigh};
  }
  & .MuiButton-root:last-child {
    border-radius: 0 4px 4px 0;
    border-left: 1px solid ${designTokens.colors.darkContextHigh};
  }
  & .MuiIconButton-root {
    height: 32px;
    border-radius: 0 4px 4px 0;
    color: ${designTokens.colors.darkEmphasisHigh};
    border-left: 1px solid ${designTokens.colors.darkContextHigh};
    background-color: ${designTokens.colors.blue500};
    :hover {
      background-color: ${designTokens.colors.blue600};
    }
  }
`;

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  align,
  context,
  body,
  children,
  className,
  e2e,
  sx,
  variant = 'separate',
}: ButtonGroupProps) => {
  return (
    <Box
      className={className}
      {...(e2e && { 'data-e2e': e2e })}
      sx={{
        display: 'flex',
        flex: '1',
        flexWrap: 'wrap',
        justifyContent: alignmentMap[align as any] || 'space-between',
        ...(context === 'floating' && {
          position: 'absolute',
          right: '0.5rem',
          top: '0.5rem',
          zIndex: '1',
        }),
        ...sx,
      }}
    >
      <span {...(e2e && { 'data-e2e': `${e2e}-body` })}>
        {body}
        {variant === 'split' ? (
          <StyledWrapper>{children}</StyledWrapper>
        ) : (
          <span>{children}</span>
        )}
      </span>
    </Box>
  );
};

export default ButtonGroup;
