import { FilterBarFactory } from './FilterBar/utils';
import { Filter, FilterCondition, OrderType } from './index';
import { LegacyFilterFactory } from './LegacyFilter/utils';
import { FilterFields } from './types';

export enum FilterUtilFactoryType {
  Legacy,
  FilterBar,
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: OrderType,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function stableSearch<T>(data: T[], s: string) {
  if (!s.trim()) return data;

  const output: T[] = [];
  const search = s.toLowerCase();
  for (let i = 0; i < data.length; i++) {
    const rowValues: string = Object.values(data[i]).join().toLowerCase();
    // TODO: take a look at Array.includes to see if its efficient
    if (rowValues.includes(search)) {
      output.push(data[i]);
    }
  }

  return output;
}

export function FilterUtilFactory(filterType: FilterUtilFactoryType): any {
  if (filterType === FilterUtilFactoryType.FilterBar) {
    return FilterBarFactory;
  } else {
    return LegacyFilterFactory;
  }
}

export function getValueFields(fields: FilterFields): any[] {
  return Object.values(fields)
    .sort((a, b) =>
      a?.dataKey < b?.dataKey ? -1 : a?.dataKey > b?.dataKey ? 1 : 0,
    )
    .filter((el) =>
      Array.isArray(el?.value) && el?.value.length === 0 ? false : el?.value,
    )
    .map((el) =>
      el?.value === undefined || el?.value === null ? null : el?.value,
    );
}

export function omitDeepFilter(
  filter?: FilterCondition | Filter,
  omitStrings: string[] = [],
): Filter {
  return JSON.parse(JSON.stringify(filter || {}), (k, v) =>
    omitStrings.includes(k) ? undefined : v,
  );
}
