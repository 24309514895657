import React, { useRef } from 'react';
import { TextField, TypeAhead } from '../../..';
import { useTranslate } from '../../../i18n';
import { InternalOperator, Operator } from '../../types';
import { ClearComponent } from './ClearComponent';
import { FilterComponentProps, FilterWrapper } from './FilterComponentFactory';

export const TextFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, dsComponentProps, dsOnBlur } = props;

  const { translate } = useTranslate();

  const inputRef = useRef<HTMLInputElement>();

  const onModeBlur = () => {
    inputRef.current?.focus();
  };

  const onChange = (
    updates: { value?: string; internalOperator?: InternalOperator },
    closeFilter?: boolean,
  ) => {
    stateChangeFn(
      {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          operator:
            updates.internalOperator === InternalOperator.EQUALS
              ? Operator.Eq
              : Operator.Sw,
          ...updates,
        },
      },
      closeFilter,
    );
  };

  return (
    <>
      <FilterWrapper>
        <TypeAhead
          e2e={`${dsComponentProps.e2e}-text-mode`}
          disableClearable
          options={[
            InternalOperator.CONTAINS,
            InternalOperator.STARTSWITH,
            InternalOperator.EQUALS,
          ]}
          value={state[stateKey]?.internalOperator}
          dsOnChange={(e, val) => {
            onChange({ internalOperator: val }, false);
            onModeBlur();
          }}
          getOptionLabel={(mode) => {
            switch (mode) {
              case InternalOperator.STARTSWITH:
                return translate(`FilterBar_StartsWith`);
              case InternalOperator.EQUALS:
                return translate(`FilterBar_Equals`);
              case InternalOperator.CONTAINS:
              default:
                return translate(`FilterBar_Contains`);
            }
          }}
        />
        <TextField
          ref={inputRef}
          {...dsComponentProps}
          value={state[stateKey]?.value}
          dsOnBlur={dsOnBlur}
          dsOnKeyPress={({ key }) => {
            if (key === 'Enter') {
              dsOnBlur?.();
            }
          }}
          dsOnChange={(e) => onChange({ value: e.target.value }, false)}
        />
      </FilterWrapper>
      {state[stateKey]?.value !== '' && (
        <ClearComponent onClear={() => onChange({ value: '' }, true)} />
      )}
    </>
  );
};
