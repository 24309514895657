import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { DatePicker } from '../../..';
import { FilterComponentProps, FilterRow } from './FilterComponentFactory';
import { OperatorComponent } from './OperatorComponent';
import LuxonUtils from '@date-io/luxon';

export const DateFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, dsComponentProps } = props;

  const [selectedDate, setDate] = React.useState<DateTime | null>(null);
  useEffect(() => {
    if (state[stateKey].value) {
      setDate(DateTime.fromISO(state[stateKey].value));
    } else if (state[stateKey].value === null) {
      setDate(null);
    }
  }, [state]);

  return (
    <FilterRow>
      <DatePicker
        {...dsComponentProps}
        utils={LuxonUtils}
        format="MM/dd/yyyy"
        label={state[stateKey].label || stateKey}
        value={selectedDate}
        dsOnChange={(val) => {
          stateChangeFn({
            ...state,
            [stateKey]: {
              ...state[stateKey],
              value: val,
            },
          });
        }}
      />
      <OperatorComponent {...props} />
    </FilterRow>
  );
};
