import * as React from 'react';
import {
  FormControl as MuiFormControl,
  FormControlProps as MUIFormControlProps,
} from '@mui/material';
import styled from '@emotion/styled';

export interface FormControlProps
  extends Pick<MUIFormControlProps, 'disabled' | 'error' | 'required'> {
  children?: React.ReactNode;
  body?: React.ReactNode;
}

const StyledFormControl = styled(MuiFormControl)`
  ${(props) => {
    if (props.disabled) {
      return 'opacity: 0.5;';
    }
  }}
`;

const FormControl: React.FC<FormControlProps> = ({
  body,
  children,
  disabled,
  error,
  required,
}: FormControlProps) => {
  return (
    <StyledFormControl
      disabled={disabled}
      error={error}
      required={required}
      size="small"
      variant="outlined"
    >
      {body}
      {children}
    </StyledFormControl>
  );
};

export default FormControl;
