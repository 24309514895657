import * as React from 'react';
import { ReactElement } from 'react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel as MuiInputLabel,
  ListSubheader,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Checkbox,
  ListItemText,
  CheckboxProps,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import styled from '@emotion/styled';
import { designTokens } from '../Theme.design-tokens';
import makeClass from 'classnames';
import { menuHeight, Tree, treeMenuWidth, useTreeStructure } from './hooks';
import MenuItem, { MenuItemProps } from '../MenuItem';
import { transientOptions } from '../utils';

export const Placeholder = styled.span`
  color: ${designTokens.colors.lightEmphasisLow};
`;

const StyleSelect = styled(MuiSelect, transientOptions)<{
  $readonly?: boolean;
  $standalone?: boolean;
}>`
  ${({ $readonly }) =>
    $readonly &&
    `
      & .MuiSelect-select {
        cursor: default
      }
    `}
  ${({ $standalone }) =>
    $standalone &&
    `
      display: none;
    `}
`;

const LabelWrap = styled.div`
  display: flex;
`;

export interface BaseSelectProps
  extends Pick<
      MuiSelectProps,
      | 'disabled'
      | 'multiple'
      | 'value'
      | 'name'
      | 'MenuProps'
      | 'sx'
      | 'renderValue'
      | 'variant'
    >,
    Pick<FormControlProps, 'error' | 'fullWidth' | 'required'> {
  /**
   * ARIA label. Use if component if not accompanied by a visible label.
   */
  a11yLabel?: MuiSelectProps['aria-label'];
  dsOnChange?: MuiSelectProps['onChange'];
  dsOnClose?: MuiSelectProps['onClose'];
  e2e?: string;
  label?: string;
  children?: React.ReactNode;
  helperText?: string;
  placeholder?: string;
  readonly?: boolean;
  labelPostfix?: React.ReactNode;
  itemRightContent?: MenuItemProps['itemRightContent'];
}

export interface TreeSelectProps extends BaseSelectProps {
  /**
   * Tree prop renders the select options in a tree format. Providing this prop will ignore children props.
   */
  tree: Tree;
  /**
   * Used in conjunction with the tree prop, the filterable prop allows a visual filtering of the tree options in the Select.
   */
  filterable?: boolean;
  /**
   * Used in conjunction with the tree prop, the standalone prop renders the menu as a standalone component, hiding the Select itself.
   */
  standalone?: boolean;
  /**
   * Used in conjunction with the tree prop, allows parent-node selection with Single Tree
   */
  treeParentSelect?: boolean;
  treeMenuMinWidth?: number;
  treeMenuHeight?: number;
  /**If true, then parent menu items will be collapsed by default
   * @default false
   */
  defaultCollapsed?: boolean;
}

export type SelectProps = BaseSelectProps | TreeSelectProps;

export const SelectCheckbox = ({
  checked,
  indeterminate,
  color,
  disabled,
}: {
  indeterminate?: boolean;
  color?: string;
} & Pick<CheckboxProps, 'disabled' | 'checked'>) => (
  <Checkbox
    color="primary"
    size="small"
    checked={checked}
    indeterminate={indeterminate}
    disabled={disabled}
    sx={{
      ...(color && {
        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
          color,
        },
      }),
      //syncing disabled styling with leaf-menu-items
      ...(disabled && {
        '&.Mui-disabled': {
          opacity: 0.3,
          color: designTokens.colors.black,
        },
      }),
    }}
  />
);

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      a11yLabel,
      children,
      disabled,
      dsOnChange,
      dsOnClose,
      e2e,
      fullWidth = true,
      helperText,
      label,
      multiple,
      value,
      name,
      error,
      required,
      placeholder,
      readonly,
      labelPostfix = null,
      itemRightContent,
      MenuProps,
      sx,
      renderValue,
      variant,
      ...rest
    },
    ref,
  ) => {
    const {
      tree,
      filterable,
      treeParentSelect,
      standalone,
      treeMenuMinWidth = treeMenuWidth,
      treeMenuHeight = menuHeight,
      defaultCollapsed = false,
    } = rest as TreeSelectProps;
    const labelId = uuid();
    const inputId = uuid();

    const [open, setOpen] = React.useState(false);

    const {
      setHiddenMap,
      setCollapsedMap,
      renderHiddenTree,
      renderValueForTree,
      filterBoxRef,
      getTreeChildren,
    } = useTreeStructure({
      tree,
      value,
      multiple,
      e2e,
      a11yLabel,
      filterable,
      treeParentSelect,
      placeholder,
      itemRightContent,
      defaultCollapsed,
      treeMenuMinWidth,
      treeMenuHeight,
    });

    const standaloneTree = !!tree && standalone;

    // Prevent component from rendering in uncontrolled state.
    // See https://reactjs.org/docs/uncontrolled-components.html
    if (value === undefined) {
      return null;
    }

    const getChildren = () => {
      if (!multiple || !children) {
        return children;
      }

      return Array.from(children as ReactElement[]).map(
        (child: ReactElement) => {
          return (
            <MenuItem
              value={child.props.value}
              key={child.props.value}
              itemRightContent={itemRightContent}
              description={child.props.description}
              stylesForDescription={{ marginLeft: '36px' }}
            >
              <SelectCheckbox
                checked={
                  Array.from(value as any).indexOf(child.props.value) > -1
                }
              />
              <ListItemText primary={child.props.children} />
            </MenuItem>
          );
        },
      );
    };

    const getHiddenChildren = () => {
      if (tree) {
        return renderHiddenTree(tree);
      }

      if (!children) {
        return null;
      }

      return (
        <>
          {React.Children.map(children, (untypedChild) => {
            const child = untypedChild as React.ReactElement<
              MenuItemProps,
              any
            >;

            if (!child) {
              return;
            }

            if (child.props.value) {
              return (
                <option
                  value={child.props.value}
                  key={child.props.value as any}
                  {...(e2e && {
                    'data-e2e': `${e2e}-select-opt-${child.props.value}`,
                  })}
                >
                  {child.props.children}
                </option>
              );
            }
          })?.filter(Boolean)}
        </>
      );
    };

    const triggerOpen = () => {
      if (!open && !disabled) {
        setOpen(true);
      }
    };

    return (
      <FormControl
        {...(e2e && { 'data-e2e': e2e })}
        variant={variant || 'outlined'}
        error={error}
        className={makeClass({ ReadOnly: readonly })}
        fullWidth={fullWidth}
        required={required}
      >
        {label && (
          <LabelWrap>
            <MuiInputLabel
              id={labelId}
              htmlFor={inputId}
              {...(e2e && { 'data-e2e': `${e2e}-label` })}
            >
              {label}
            </MuiInputLabel>
            {labelPostfix}
          </LabelWrap>
        )}
        <StyleSelect
          open={!readonly && open}
          onClick={triggerOpen}
          onKeyDownCapture={(e) => {
            if ([' ', 'Enter'].includes(e.key)) {
              triggerOpen();
            }
          }}
          $readonly={readonly}
          $standalone={standaloneTree}
          inputRef={ref}
          aria-label={a11yLabel}
          disabled={disabled}
          {...(tree && {
            MenuProps: {
              autoFocus: false,
              MenuListProps: {
                sx: {
                  paddingTop: `0`,
                },
              },
              PaperProps: {
                sx: {
                  minHeight: `250px`,
                  maxHeight: `400px`,
                  minWidth: `250px !important`,
                  maxWidth: `${tree ? treeMenuMinWidth : 300}px !important`,
                },
              },
            },
          })}
          name={name}
          readOnly={readonly}
          labelId={labelId}
          onChange={dsOnChange}
          onOpen={() => setTimeout(() => filterBoxRef.current?.focus(), 0)}
          onClose={(...args) => {
            if (open) {
              setOpen(false);
            }
            dsOnClose?.(...args);

            setTimeout(() => {
              setHiddenMap({});
              setCollapsedMap({});
            }, 150);
          }}
          value={value}
          label={label}
          inputProps={{
            id: inputId,
            ...(e2e && { 'data-e2e': `${e2e}-input` }),
          }}
          renderValue={
            renderValue ||
            (tree
              ? renderValueForTree
              : multiple
              ? (selected) =>
                  (children as ReactElement[])
                    .filter(
                      (child) =>
                        Array.from(selected as unknown[]).indexOf(
                          child.props.value,
                        ) > -1,
                    )
                    .map((child) => child.props.children)
                    .join(', ')
              : undefined)
          }
          multiple={multiple}
          displayEmpty
          MenuProps={MenuProps}
          sx={sx}
        >
          {!tree && placeholder && (
            <MenuItem
              sx={{
                '&&.Mui-selected': {
                  background: 'inherit',
                },
              }}
              value=""
              disabled
            >
              <Placeholder>{placeholder}</Placeholder>
            </MenuItem>
          )}
          {tree && dsOnChange
            ? getTreeChildren({ dsOnChange, setOpen })
            : getChildren()}
        </StyleSelect>
        {standaloneTree && dsOnChange && getTreeChildren({ dsOnChange })}
        {/* The purpose of this hidden select element is to
         *  progmatically control the select using e2e testing tools.
         */}
        <select
          style={{ display: 'none' }}
          aria-hidden
          name={name}
          onChange={(event) => {
            if (!event.target) {
              return;
            }
            const selectedElems = Array.from(
              event.target.querySelectorAll<HTMLOptionElement>(
                'option:checked',
              ),
            );

            const selectedValues = selectedElems.map((elem) => {
              try {
                return JSON.parse(elem.value);
              } catch (e) {
                return isNaN(Number(elem.value))
                  ? String(elem.value)
                  : Number(elem.value);
              }
            });

            const value = multiple
              ? (selectedValues as any)
              : (selectedValues[0] as any);

            const newEvent = {
              ...event,
              target: {
                ...event.target,
                // @ts-ignore
                value,
              },
            };

            // @ts-ignore
            dsOnChange?.(newEvent, newEvent.target);
          }}
          value={
            typeof value === 'object' && !Array.isArray(value)
              ? JSON.stringify(value)
              : (value as any)
          }
          {...(e2e && { 'data-e2e': `${e2e}-select` })}
          multiple={multiple}
        >
          <option value="" style={{ display: 'none' }} disabled />
          {getHiddenChildren()}
        </select>
        {!!helperText && (
          <FormHelperText {...(e2e && { 'data-e2e': `${e2e}-helperText` })}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

export default Select;

export type SelectSubheaderProps = {};
export const SelectSubheader: React.FC<SelectSubheaderProps> = ListSubheader;
SelectSubheader.displayName = 'SelectSubheader';

export type SelectItemProps = Pick<MenuItemProps, 'value' | 'children'>;
export const SelectItem: React.FC<SelectItemProps> = MenuItem;
SelectItem.displayName = 'SelectItem';
