import * as React from 'react';
import {
  DialogTitle,
  Button,
  Icon as MUIIcon,
  IconButton as MUIIconButton,
  IconButtonProps,
  Menu,
  MenuProps,
  MenuItem,
  MenuItemProps,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import ButtonGroup from '../../Button/ButtonGroup';
import { useThemeTokens } from '../../Theme';
import { AllIconsType } from '../../index';
import { ModalProps } from '..';

export interface ModalTitleActions {
  label: string;
  icon: AllIconsType;
  dsClick: IconButtonProps['onClick'] | MenuItemProps<any>['onClick'];
  primary?: boolean;
}

export interface ModalTitleProps {
  /** @deprecated */
  children?: React.ReactNode;
  e2e?: string;
  id?: string;
  body?: string;
  dsOnClose?: IconButtonProps['onClick'];
  /** @deprecated */
  dsOnBack?: IconButtonProps['onClick'];
  /** @deprecated */
  actions?: ModalTitleActions[];
  subtitle?: string;
  surtitle?: string;
  /** @deprecated */
  icon?: React.ReactNode;
  variant?: ModalProps['variant'];
}

const sortActions = (actions: ModalTitleActions[]) => {
  const primaryAction = actions.find((a) => a.primary);
  const nonPrimaryActions = actions.filter((a) => !a.primary);
  const visibleActions =
    nonPrimaryActions.length > 3
      ? nonPrimaryActions.slice(0, 2)
      : nonPrimaryActions.slice(0, 3);
  const overflowActions =
    nonPrimaryActions.length > 3 ? nonPrimaryActions.slice(2) : null;

  return { primaryAction, visibleActions, overflowActions };
};

const ModalTitle: React.FC<ModalTitleProps> = ({
  children,
  dsOnClose,
  dsOnBack,
  id,
  body,
  surtitle,
  subtitle,
  actions = [],
  icon,
  variant,
}: ModalTitleProps) => {
  const { emphasisLow } = useThemeTokens();
  const theme = useTheme();

  const { primaryAction, visibleActions, overflowActions } =
    sortActions(actions);

  const [anchorPosition, setAnchorPosition] =
    React.useState<MenuProps['anchorPosition']>();
  const open = Boolean(anchorPosition);

  const dropdownClose = () => {
    setAnchorPosition(undefined);
  };

  const actionIcons = actions?.length ? (
    <ButtonGroup
      align="right"
      sx={{
        transform: `translateY(-${theme.spacing(1)})`,
        '& button:last-child': {
          '&::after': {
            content: '""',
            borderRight: `1px solid ${theme.palette.grey[500]}`,
          },
        },
        minWidth: '300px',
      }}
    >
      {primaryAction ? (
        <Button
          sx={{ marginRight: theme.spacing(1) }}
          aria-label={primaryAction.label}
          variant="outlined"
          color="primary"
          onClick={primaryAction.dsClick}
          startIcon={<MUIIcon>{primaryAction.icon}</MUIIcon>}
        >
          {primaryAction.label}
        </Button>
      ) : null}
      {visibleActions.length
        ? visibleActions.map((action) => (
            <MUIIconButton
              sx={{ color: emphasisLow }}
              aria-label={action.label}
              key={action.label}
              onClick={action.dsClick}
            >
              <MUIIcon>{action.icon}</MUIIcon>
            </MUIIconButton>
          ))
        : null}
      {overflowActions?.length ? (
        <>
          <MUIIconButton
            sx={{ color: emphasisLow }}
            aria-label="more"
            onClick={(event) => {
              const rect = event.currentTarget.getBoundingClientRect();
              setAnchorPosition({
                top: rect.y + rect.height * 1.5,
                left: rect.x,
              });
            }}
          >
            <MUIIcon>more_vert</MUIIcon>
          </MUIIconButton>
          <Menu
            open={open}
            anchorReference="anchorPosition"
            {...(anchorPosition && { anchorPosition })}
            onClose={dropdownClose}
          >
            {overflowActions.map((action) => (
              <MenuItem
                key={action.label}
                onClick={(event) => {
                  dropdownClose();
                  action?.dsClick?.(event);
                }}
              >
                {action.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : null}
      {!!dsOnClose ? (
        <Box
          component="span"
          sx={{
            height: theme.spacing(2),
            padding: `${theme.spacing(1.5)} 0`,
            borderLeft: `1px solid ${theme.palette.grey[200]}`,
          }}
        />
      ) : null}
    </ButtonGroup>
  ) : null;

  const backButton = !!dsOnBack ? (
    <MUIIconButton
      aria-label="back"
      onClick={dsOnBack}
      sx={{
        margin: 'auto',
        '& span': {
          fontSize: `3rem`,
        },
      }}
    >
      <MUIIcon>arrow_back</MUIIcon>
    </MUIIconButton>
  ) : null;

  return (
    <DialogTitle
      id={id}
      sx={{
        margin: 0,
        padding:
          variant === 'dialog'
            ? `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(0)}`
            : `${theme.spacing(2.5)} ${theme.spacing(4)}`,
        display: 'flex',
        ...(dsOnClose && {
          paddingRight: theme.spacing(10),
        }),
      }}
    >
      {backButton}
      {icon && (
        <Box
          sx={{
            display: 'flex',
            marginRight:
              variant === 'dialog' ? theme.spacing(1) : theme.spacing(2.2),
            '& > span': {
              alignSelf: 'center',
            },
          }}
        >
          {icon}
        </Box>
      )}
      <div>
        {surtitle ? (
          <Typography variant="overline" color="textSecondary">
            {surtitle}
          </Typography>
        ) : null}
        <Typography variant={variant === 'dialog' ? 'subtitle2' : 'h6'}>
          {children}
          {body}
        </Typography>
        {subtitle ? (
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        ) : null}
      </div>
      {actionIcons}
      {dsOnClose ? (
        <MUIIconButton
          aria-label="close"
          onClick={dsOnClose}
          sx={{
            position: 'absolute',
            right: theme.spacing(2.5),
            top: theme.spacing(1.5),
            color: theme.palette.grey[500],
          }}
        >
          <Close />
        </MUIIconButton>
      ) : null}
    </DialogTitle>
  );
};

export default ModalTitle;
