import * as React from 'react';
import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MUIRadioGroupProps,
  FormControl,
  FormControlProps,
  FormLabel,
} from '@mui/material';

export interface RadioGroupProps
  extends Pick<MUIRadioGroupProps, 'defaultValue' | 'name' | 'value' | 'row'> {
  /**
   * ARIA label. Use if component if not accompanied by a visible label.
   */
  a11yLabel?: string;
  children?: React.ReactNode;
  disabled?: FormControlProps['disabled'];
  dsOnChange?: MUIRadioGroupProps['onChange'];
  e2e?: string;
  error?: FormControlProps['error'];
  label: string;
  required?: FormControlProps['required'];
}

const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      a11yLabel,
      children,
      defaultValue,
      disabled,
      dsOnChange,
      e2e,
      error,
      label,
      name,
      required,
      row,
      value = null,
    }: RadioGroupProps,
    ref,
  ) => {
    return (
      <FormControl
        disabled={disabled}
        error={error}
        required={required}
        {...(e2e && { 'data-e2e': e2e })}
      >
        {!!label && (
          <FormLabel
            disabled={disabled}
            {...(e2e && { 'data-e2e': `${e2e}-label` })}
          >
            {label}
          </FormLabel>
        )}
        <MuiRadioGroup
          ref={ref}
          aria-label={a11yLabel}
          defaultValue={defaultValue}
          name={name}
          onChange={dsOnChange}
          row={row}
          value={value}
          {...(e2e && { 'data-e2e': `${e2e}-radiogroup` })}
        >
          {children}
        </MuiRadioGroup>
      </FormControl>
    );
  },
);

export default RadioGroup;
