import React from 'react';
import styled from '@emotion/styled';
import { FilterableField, FilterBarOptions } from '../..';
import { FilterDataTypes, FilterFields, Option } from '../../types';
import { DateFilterComponent } from './DateFilterComponent';
import { PicklistFilterComponent } from './PicklistFilterComponent';
import { TextFilterComponent } from './TextFilterComponent';
import { NumericalFilterComponent } from './NumericalFilterComponent';
import { BooleanFilterComponent } from './BooleanFilterComponent';
import { FilterBarFactory } from '../utils';
import { TypeaheadFilterComponent } from './TypeaheadFilterComponent';
import { BooleanPicklistFilterComponent } from './BooleanPicklistFilterComponent';

export interface FilterComponentProps {
  stateKey: string;
  state: FilterFields;
  stateChangeFn: (value, blurred?: boolean) => void;
  dsOnBlur?: () => void;
  operators?: string[];
  dsComponentProps?: any;
  filterableField?: FilterableField;
  cachedSelections?: Option[];
  setCachedSelections?: (value: Option[]) => void;
  e2e?: string;
  filterBarOptions?: FilterBarOptions;
}

interface FilterComponentFactoryProps extends FilterComponentProps {
  type: FilterDataTypes;
}

export const FilterWrapper = styled('div')<{ omitMargin?: boolean }>`
  margin: ${({ omitMargin }) => (omitMargin ? '' : '1rem')};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FilterComponentFactory = ({
  type,
  ...props
}: FilterComponentFactoryProps) => {
  const componentProps = {
    ...props,
    operators: FilterBarFactory.componentOperatorMap[type],
  };

  const baseDsComponentProps = {
    e2e: props.e2e
      ? `${props.e2e}-${props.stateKey}-filter-field`
      : `${props.stateKey}-filter-field`,
  };

  switch (type) {
    case FilterDataTypes.PICKLIST:
      return (
        <PicklistFilterComponent
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.BIGINT:
    case FilterDataTypes.INTEGER:
    case FilterDataTypes.DECIMAL:
      return (
        <NumericalFilterComponent
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.DATE:
    case FilterDataTypes.TIMESTAMP:
      return (
        <DateFilterComponent
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.BOOLEAN:
      return (
        <BooleanFilterComponent
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.BOOLEANPICKLIST:
      return (
        <BooleanPicklistFilterComponent
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.STRING:
    default:
      if (props?.filterableField?.relationshipQueryCallback) {
        return (
          <TypeaheadFilterComponent
            {...componentProps}
            dsComponentProps={{ ...baseDsComponentProps }}
            cachedSelections={props?.cachedSelections}
          />
        );
      } else {
        return (
          <TextFilterComponent
            {...componentProps}
            dsComponentProps={{ ...baseDsComponentProps }}
          />
        );
      }
  }
};
