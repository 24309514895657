import * as React from 'react';
import {
  Stepper as MUIStepper,
  Step as MUIStep,
  StepperProps as MUIStepperProps,
  StepButton,
  StepLabel,
  StepConnector,
  StepContent,
  useTheme,
} from '@mui/material';
import Typography from '../Typography';
import { ChevronRight, Error } from '@mui/icons-material';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

export interface Step {
  complete?: boolean;
  error?: boolean;
  label: string;
  description?: string;
  contentComponent?: ReactNode;
}

export interface StepperProps extends Pick<MUIStepperProps, 'activeStep'> {
  e2e?: string;
  variant?: 'default' | 'condensed' | 'vertical';
  labelAlignment?: 'left' | 'center';
  steps: Step[];
  dsOnClick: (index: number) => {};
}

const Stepper: React.FC<StepperProps> = ({
  e2e,
  variant = 'default',
  labelAlignment = 'left',
  steps = [],
  activeStep,
  dsOnClick,
}: StepperProps) => {
  const theme = useTheme();
  const isVertical = variant === 'vertical';

  const StyledChevron = styled(ChevronRight)`
    margin: 0 12px;
  `;
  const connector =
    variant === 'condensed' ? (
      <StyledChevron />
    ) : (
      <StepConnector
        sx={{
          ...(labelAlignment === 'center' && !isVertical // central vertical not supported
            ? {
                top: 0,
                left: 'calc(-50% + 30px)',
                right: 'calc(50% + 30px)',
              }
            : isVertical
            ? {
                marginLeft: '20px',
                left: 'calc(-50% + 30px)',
                right: 'calc(50% + 30px)',
              }
            : { top: 0 }),
        }}
      />
    );

  return (
    <MUIStepper
      nonLinear
      activeStep={activeStep}
      alternativeLabel={
        variant === 'condensed'
          ? false
          : labelAlignment === 'center' && !isVertical // center vertical not supported
      }
      connector={connector}
      orientation={isVertical ? 'vertical' : 'horizontal'}
      {...(e2e && { 'data-e2e': `${e2e}-stepper` })}
    >
      {steps.map(
        ({ complete, error, label, description, contentComponent }, index) => (
          <MUIStep
            key={label}
            completed={!!complete && !error}
            sx={{ display: 'flex' }}
            {...(e2e && { 'data-e2e': `${e2e}-step-${label}` })}
          >
            <StepButton
              sx={
                // added styles only for vertical
                isVertical
                  ? {
                      '& > span > span.MuiStepLabel-iconContainer': {
                        display: 'none',
                      },
                      '& > .MuiStepLabel-root.MuiStepLabel-vertical': {
                        width: '100%',
                      },
                    }
                  : {
                      '& > span > span > span > span > span.MuiStepLabel-iconContainer':
                        {
                          display: 'none',
                        },
                    }

                // added styles only for vertical ^
              }
              color="inherit"
              onClick={() => dsOnClick(index)}
              icon={
                !!error ? (
                  <Error
                    sx={{ fontSize: '28px', margin: '-2px' }}
                    color="error"
                  />
                ) : null
              }
              {...(e2e && { 'data-e2e': `${e2e}-step-${label}-button` })}
            >
              <StepLabel
                icon={
                  !!error ? ( // error label for vertical stepper
                    <Error
                      sx={{ fontSize: '28px', margin: '-2px' }}
                      color="error"
                    />
                  ) : null
                }
              >
                <Typography
                  variant="body1"
                  color={
                    index === activeStep
                      ? 'primary'
                      : !!error
                      ? 'error'
                      : 'inherit'
                  }
                  sx={{
                    fontWeight: index === activeStep || !!error ? 500 : 400,
                  }}
                >
                  {label}
                </Typography>
                {description && (
                  <Typography variant="caption">{description}</Typography>
                )}
              </StepLabel>
              {isVertical && contentComponent ? (
                <StepContent
                  sx={{
                    borderLeftColor: `${theme.palette.action.disabledBackground}`,
                    background: `rgba(246, 247, 248, 1)`,
                    '& > MuiCollapse-wrapperInner.MuiCollapse-vertical': {
                      background: 'rgba(246, 247, 248, 1)',
                    },
                  }}
                >
                  {contentComponent}
                </StepContent>
              ) : null}
            </StepButton>
          </MUIStep>
        ),
      )}
    </MUIStepper>
  );
};

export default Stepper;
