import * as React from 'react';
import { Box, useTheme } from '@mui/material';

export interface SummaryRowProps {
  e2e?: string;
  children: React.ReactNode;
}

export const SummaryRow = ({ e2e, children }: SummaryRowProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
      }}
      {...(e2e && { 'data-e2e': e2e })}
    >
      {children}
    </Box>
  );
};
