import * as React from 'react';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import Select, { SelectItem } from '../Select';
import DatePicker from '../Pickers/DatePicker';
import DateTimePicker from '../Pickers/DateTimePicker';
import TypeAhead, { TypeAheadProps } from '../TypeAhead';
import RadioGroup from '../RadioGroup';
import {
  AllIconsType,
  ColumnType,
  FormControl,
  FormHelperText,
  Radio,
} from '..';
import { Box } from '@mui/system';
import { ValueType } from '.';

type FormRegistrationReturn = {
  onChange: (value: ValueType) => void;
  onBlur: (event: FocusEvent) => void;
  ref: <T>(instance: T) => void;
  name?: string;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
};

export type EditableInputProps = {
  value: any;
  // Props from validation library, to spread on nested Inputs
  formRegistrationReturn?: FormRegistrationReturn;
  disabled?: boolean;
  readonly?: boolean;
  e2e?: string;
  error?: boolean;
  column?: ColumnType;
  helperText?: string;
  onChange?: (value: ValueType) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onEditComplete?: () => void;
  type:
    | 'typeahead'
    | 'string'
    | 'relationship'
    | 'boolean'
    | 'number'
    | 'integer'
    | 'date'
    | 'date-time'
    | 'picklist'
    | 'password';

  // String
  multiline?: boolean;
  rows?: number;
  maxlength?: number;
  maxRows?: number;

  // Date
  dateFormat?: string;
  minDate?: string;
  maxDate?: string;

  // Picklist
  picklistOptions?: (string | { value: string; label: string })[];
  picklistEmptyOption?: string;
  picklistUniqueKey: string;

  // Typeahead
  typeaheadOptions?: any;
  typeaheadLoading?: TypeAheadProps<any>['loading'];
  typeaheadGetOptionLabel?: TypeAheadProps<any>['getOptionLabel'];
  typeaheadRenderOption?: TypeAheadProps<any>['dsRenderOption'];
  typeaheadAutoSelect?: TypeAheadProps<any>['autoSelect'];

  // For currency
  endAdornmentIcon?: AllIconsType;
  endAdornmentText?: string;

  // Checkbox
  checkboxLabel?: string;
};

export const EditableInput = React.forwardRef<
  HTMLInputElement,
  EditableInputProps
>((props, ref) => {
  const {
    formRegistrationReturn = {} as FormRegistrationReturn,
    disabled,
    readonly,
    e2e,
    type,
    onChange,
    onBlur,
    onFocus,
    onEditComplete,
    column,
    value,
    endAdornmentText,
    endAdornmentIcon,
    checkboxLabel,
    error,
    helperText,
  } = props || {};

  const { onChange: formRegOnChange, ...formRest } = formRegistrationReturn;

  const changeHandler: EditableInputProps['onChange'] = (value) => {
    onChange?.(value);
    formRegOnChange?.(value);
  };

  if (!type || type === 'string' || type === 'relationship') {
    // TODO: move relationship out into typeahead searching component
    const { multiline, rows, maxlength, maxRows } = props;

    return (
      <TextField
        disabled={disabled}
        readonly={readonly}
        e2e={e2e}
        value={value === undefined ? null : value}
        dsOnChange={(val) => {
          changeHandler?.(val.target.value);
        }}
        dsOnBlur={() => {
          onBlur?.();
          onEditComplete?.();
        }}
        dsOnFocus={onFocus}
        error={error}
        fullWidth
        helperText={helperText}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        maxlength={maxlength}
        endAdornmentText={endAdornmentText}
        endAdornmentIcon={endAdornmentIcon}
        {...formRest}
        ref={ref}
      />
    );
  }

  if (type === 'boolean') {
    if (column?.radio) {
      return (
        <Box sx={{ paddingLeft: '1rem' }}>
          <FormControl error={error}>
            <RadioGroup
              disabled={disabled}
              e2e={e2e}
              value={value}
              label=""
              dsOnChange={(val) => {
                changeHandler?.(val.target.value);
              }}
              {...formRest}
            >
              <Radio
                {...(formRest?.ref && { ref: formRest.ref })}
                ref={ref}
                value={column.radio}
                label=""
                e2e={column.radio}
              />
            </RadioGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        </Box>
      );
    } else {
      return (
        <FormControl error={error}>
          <Checkbox
            disabled={disabled}
            e2e={e2e}
            label={checkboxLabel}
            checked={
              typeof value === 'boolean'
                ? value
                : value === 'true'
                ? true
                : false
            }
            dsOnChange={(val) => {
              changeHandler?.(val.target.checked);
            }}
            {...formRest}
            ref={ref}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      );
    }
  }

  if (type === 'password') {
    const { maxlength } = props;

    return (
      <TextField
        disabled={disabled}
        e2e={e2e}
        value={value ?? ''}
        dsOnChange={(val) => {
          changeHandler?.(val.target.value);
        }}
        dsOnBlur={() => {
          onBlur?.();
          onEditComplete?.();
        }}
        dsOnFocus={onFocus}
        error={error}
        helperText={helperText}
        fullWidth
        maxlength={maxlength}
        type="password"
        {...formRest}
        ref={ref}
      />
    );
  }

  if (type === 'number') {
    return (
      <TextField
        disabled={disabled}
        readonly={readonly}
        e2e={e2e}
        type="number"
        value={value}
        dsOnChange={(val) => {
          changeHandler?.(val.target.value);
        }}
        dsOnBlur={() => {
          onBlur?.();
          onEditComplete?.();
        }}
        dsOnFocus={onFocus}
        error={error}
        fullWidth
        helperText={helperText}
        endAdornmentText={endAdornmentText}
        endAdornmentIcon={endAdornmentIcon}
        {...formRest}
        ref={ref}
      />
    );
  }

  // /**
  //  * There is an open issue to create a InputNumber component natively in MUI,
  //  * https://github.com/mui-org/material-ui/issues/19154
  //  */
  if (type === 'integer') {
    return (
      <TextField
        disabled={disabled}
        readonly={readonly}
        e2e={e2e}
        type="number"
        value={value}
        dsOnChange={(e) => {
          // when they clear return an empty string
          if (e.target.value === '') {
            changeHandler?.('');
            return;
          }

          const parsedInt = parseInt(e.target.value);

          // only allow it to be changed if its a valid integer
          if (Number.isInteger(parsedInt)) {
            changeHandler?.(String(parsedInt));
          }
        }}
        dsOnBlur={() => {
          onBlur?.();
          onEditComplete?.();
        }}
        dsOnFocus={onFocus}
        error={error}
        fullWidth
        helperText={helperText}
        endAdornmentText={endAdornmentText}
        endAdornmentIcon={endAdornmentIcon}
        {...formRest}
        ref={ref}
      />
    );
  }

  if (type === 'date') {
    const { dateFormat, minDate, maxDate } = props;
    const format = dateFormat || 'MM/dd/yyyy';

    return (
      <DatePicker
        disabled={disabled}
        readonly={readonly}
        e2e={e2e}
        value={value}
        format={format}
        dsOnChange={(val: any) => {
          if (val?.isValid) {
            changeHandler?.(val.toFormat(format));
          }
          if (val === null) {
            changeHandler?.(val);
          }
        }}
        error={error}
        fullWidth
        helperText={helperText}
        minDate={minDate}
        maxDate={maxDate}
        dsOnClose={onEditComplete}
        {...formRest}
        ref={ref}
      />
    );
  }

  if (type === 'date-time') {
    const { dateFormat, minDate, maxDate } = props;
    const format = dateFormat || 'MM/dd/yyyy';

    return (
      <DateTimePicker
        e2e={e2e}
        value={String(value)}
        format={format}
        dsOnChange={(val: any) => {
          if (val?.isValid) {
            changeHandler?.(val.toISOString());
          }
          if (val === null) {
            changeHandler?.(val);
          }
        }}
        error={error}
        fullWidth
        helperText={helperText}
        minDate={minDate}
        maxDate={maxDate}
        dsOnClose={onEditComplete}
        {...formRest}
        ref={ref}
      />
    );
  }

  if (type === 'picklist') {
    const { picklistOptions, picklistEmptyOption, picklistUniqueKey } = props;

    return (
      <Select
        disabled={disabled}
        readonly={readonly}
        e2e={e2e}
        dsOnChange={(val) => {
          // @ts-ignore
          changeHandler(val.target.value);
        }}
        dsOnClose={onEditComplete}
        error={error}
        fullWidth
        helperText={helperText as string}
        label=""
        value={value}
        {...formRest}
        ref={ref}
      >
        {picklistEmptyOption ? (
          <SelectItem
            key={`${picklistUniqueKey}-${picklistEmptyOption}`}
            value=""
          >
            {picklistEmptyOption}
          </SelectItem>
        ) : null}
        {picklistOptions?.map((option) => {
          const displayValue =
            typeof option === 'string' ? option : option.label;
          const submitValue =
            typeof option === 'string' ? option : option.value;
          return (
            <SelectItem
              key={`${picklistUniqueKey}-${submitValue}`}
              value={submitValue}
            >
              {displayValue}
            </SelectItem>
          );
        })}
      </Select>
    );
  }

  if (type === 'typeahead') {
    const {
      typeaheadLoading,
      typeaheadOptions,
      typeaheadGetOptionLabel = (val) => val,
      typeaheadRenderOption,
      typeaheadAutoSelect,
    } = props;
    return (
      <TypeAhead
        disabled={disabled}
        readonly={readonly}
        e2e={e2e}
        dsOnChange={(_, val) => {
          changeHandler?.(val);
        }}
        dsOnClose={onEditComplete}
        error={error}
        fullWidth
        getOptionLabel={typeaheadGetOptionLabel}
        dsRenderOption={typeaheadRenderOption}
        autoSelect={typeaheadAutoSelect}
        helperText={helperText}
        loading={typeaheadLoading}
        options={typeaheadOptions}
        value={value}
        {...formRest}
      />
    );
  }

  return null;
});
