import { DateTime } from 'luxon';
import React from 'react';
import { LegacyFilterFactory } from '../utils';
import { DateFilterComponent } from './DateFilterComponent';
import { FilterComponentProps } from './FilterComponentFactory';
import { TextFilterComponent } from './TextFilterComponent';

const placeholder = DateTime.now().toJSON();

export const TimestampHandlerComponentFactory = (
  props: FilterComponentProps,
) => {
  // Because many dates are stored, and must be queried as, a valid TIMESTAMP
  // It is easier to do [EQ,NE] with a text field, copy/paste "2021-06-21T10:04:47-07:00" from table
  // // If trying to use a datepicker, the time segments of the string would never match perfectly
  // It is easier to do [GE, LE] with a datepicker, pick June 22, 2021, get those dates
  // // If trying to use a text field, a full timestamp string would have to be provided

  return LegacyFilterFactory.validTimestampDatePickerOperators(
    props.state[props.stateKey].operator,
  ) ? (
    <DateFilterComponent {...props} />
  ) : (
    <TextFilterComponent
      {...props}
      dsComponentProps={{ ...props.dsComponentProps, placeholder }}
    />
  );
};
