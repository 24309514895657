import * as React from 'react';
import styled from '@emotion/styled';
import { transientOptions } from '../utils';
import { useDrop, useDrag, DropTargetMonitor } from 'react-dnd';
import { Popover, List, ListItem, Divider } from '@mui/material';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Typography from '../Typography';
import { ColumnType } from './index';
import { useThemeTokens } from '../Theme';
import { useTranslate } from '../i18n';

const StyledColumnControlContainer = styled('span', transientOptions)<{
  $hasActions: boolean;
  $color: string;
}>`
  margin-left: ${({ $hasActions }) => ($hasActions ? '8px' : 0)};
  border-left: ${({ $hasActions, $color }) =>
    $hasActions ? `1px solid ${$color}` : 'none'};
  padding-left: ${({ $hasActions }) => ($hasActions ? '8px' : 0)};
  padding-right: 8px;
`;

const StyledHeaderTextWrapper = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

interface ColumnControlPopoverListProps {
  e2e?: string;
  columns: ColumnType[];
  hasActions: boolean;
  onColumnsChange: (columns: ColumnType[]) => void;
  onColumnControlPopoverClick?: () => void;
}

const DRAGGABLE_ITEM = 'DRAGGABLE_ITEM';

export const ColumnControlPopoverList = ({
  e2e,
  columns: inputColumns,
  hasActions,
  onColumnsChange,
  onColumnControlPopoverClick,
}: ColumnControlPopoverListProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [columns, setColumns] = React.useState<ColumnType[]>([]);
  const themeTokens = useThemeTokens();
  const { translate } = useTranslate();
  React.useEffect(() => {
    setColumns(inputColumns);
  }, [inputColumns]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onColumnControlPopoverClick) {
      onColumnControlPopoverClick();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleMoveItem = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = columns[dragIndex];
      const newColumns = [...columns];
      newColumns.splice(dragIndex, 1);
      newColumns.splice(hoverIndex, 0, dragCard);
      setColumns(newColumns);
    },
    [columns],
  );

  const [, drop] = useDrop({
    accept: DRAGGABLE_ITEM,
    drop: () => {
      onColumnsChange(columns);
    },
  });

  return (
    <StyledColumnControlContainer
      $color={themeTokens.contextHigh}
      $hasActions={hasActions}
    >
      <Button
        {...(e2e && { e2e: `${e2e}-table-customize-columns` })}
        aria-describedby={id}
        variant="contained"
        icon="table_chart"
        tooltip={translate('CustomizeColumns')}
        dsOnClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledHeaderTextWrapper>
          {translate('CustomizeColumns')}
        </StyledHeaderTextWrapper>

        <List
          // aria-label=""
          ref={drop}
          disablePadding
        >
          <Divider />
          {columns.map((column, index) => {
            return (
              /* eslint-disable @typescript-eslint/no-use-before-define */
              <DraggableListItem
                e2e={e2e}
                key={`column-control-list-item-${column.field}`}
                index={index}
                column={column}
                onMoveItem={handleMoveItem}
                onToggleItem={(index: number) => {
                  const newColumns = [...columns];
                  newColumns[index].hidden = !newColumns[index].hidden;
                  setColumns(newColumns);
                  onColumnsChange(newColumns);
                }}
              />
            );
          })}
        </List>
      </Popover>
    </StyledColumnControlContainer>
  );
};

interface DraggableListItemProps {
  e2e?: string;
  index: number;
  column: ColumnType;
  onMoveItem: (dragIndex: number, hoverIndex: number) => void;
  onToggleItem: (index: number) => void;
}

const DraggableListItem = ({
  e2e,
  index,
  column,
  onMoveItem,
  onToggleItem,
}: DraggableListItemProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: DRAGGABLE_ITEM,
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onMoveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DRAGGABLE_ITEM,
    item: { index },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  drag(drop(preview(ref)));

  const opacity = isDragging ? 0 : 1;

  return (
    <span ref={ref}>
      <ListItem
        ref={(node) => preview(node)}
        dense
        disableGutters
        style={{ opacity }}
      >
        <Button
          buttonRef={(node: any) => {
            drag(node);
          }}
          variant="contained"
          icon="drag_indicator"
        />
        <Checkbox
          {...(e2e && {
            e2e: `${e2e}-table-customize-column-${
              column.label || column.field
            }-checkbox`,
          })}
          label={column.label || column.field}
          checked={!column.hidden}
          dsOnChange={() => {
            onToggleItem(index);
          }}
        />
      </ListItem>
      <Divider />
    </span>
  );
};
