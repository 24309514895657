import React from 'react';
import { QuickFilterField, VanityFilterField } from '../../types';
import styled from '@emotion/styled';
import { Select, SelectItem } from '../../..';
import { useTranslate } from '../../../i18n';
import { FilterBarFactory } from '../utils';
import { FilterableField, Sort, Filter, FilterBarOptions } from '../../index';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';
import { omitDeepFilter } from '../../utils';

const StyledSelectField = styled.div`
  width: 100%;
  & > * {
    width: 100%;
  }
`;

const QuickWrapper = styled.div`
  width: 200px;
  display: flex;
`;

interface QuickFilterComponentProps {
  quickFilters: QuickFilterField[];
  setFilter: (filter) => void;
  setFields: (fields) => void;
  dsOnSort?: (args: Sort[]) => void;
  filterableFields: FilterableField[];
  vanityFilters?: VanityFilterField[];
  defaultFields?: string[];
  orderBy: string | number | undefined;
  filter: Filter;
  filterBarOptions?: FilterBarOptions;
  searchWildcard?: string;
}

export const QuickFilterComponent = ({
  quickFilters: origQuickFilters,
  setFilter,
  setFields,
  dsOnSort,
  filterableFields,
  vanityFilters,
  defaultFields,
  orderBy,
  filter,
  filterBarOptions,
  searchWildcard,
}: QuickFilterComponentProps) => {
  const { translate } = useTranslate();

  const [quickValueId, setQuickValueId] = React.useState<string>('');
  const [quickFilters, setQuickFilters] = React.useState<QuickFilterField[]>(
    [],
  );

  const getQuickFields = (quickFilter, isActive = true) => {
    return FilterBarFactory.filterToFields(
      filterableFields,
      undefined,
      defaultFields,
      vanityFilters?.map((el) => ({ ...el, value: false })),
      { ...quickFilter, value: isActive },
      filterBarOptions,
      searchWildcard,
    );
  };

  React.useEffect(() => {
    // Ignore updates from QuickFilter Set
    if (filter?.quickFilterLabel) {
      return;
    }

    // Handle QuickFilter Clear
    if (!filter || !filter?.conditions) {
      setQuickValueId('');
    }

    const matchedFilter = quickFilters?.find((quickFilter) => {
      const cleanedQuickFilter = omitDeepFilter(quickFilter.filter, [
        'quickFilterLabel',
        'fieldId',
      ]);
      const cleanedFilter = omitDeepFilter(filter, [
        'quickFilterLabel',
        'fieldId',
      ]);

      return (
        isEqual(cleanedFilter, cleanedQuickFilter) &&
        isEqual(orderBy, quickFilter.orderBy)
      );
    });

    // Display QuickFilter if matches FilterChips
    if (matchedFilter) {
      setQuickValueId(matchedFilter.id);
    } else {
      setQuickValueId('');
    }
  }, [filter, orderBy]);

  React.useEffect(() => {
    let check = false;
    origQuickFilters &&
      setQuickFilters(
        origQuickFilters?.map((quickFilter) => {
          if (!check && quickFilter.value) check = quickFilter.value;
          return {
            ...quickFilter,
            value: check,
            ...(quickFilter.filter && {
              filter: {
                ...quickFilter.filter,
                conditions: quickFilter?.filter?.conditions?.map((cond) => ({
                  ...cond,
                  fieldId: uuid(),
                })),
              },
            }),
          };
        }),
      );
  }, [origQuickFilters]);

  const handleChangeQuickFilters = (quickFilterFieldId: string) => {
    setQuickValueId(quickFilterFieldId);
    if (quickFilterFieldId) {
      const currentQuickFilter = quickFilters.find(
        (filter) => filter.id === quickFilterFieldId,
      );
      if (currentQuickFilter) {
        const quickFields = getQuickFields(currentQuickFilter);

        setFields(quickFields);
        dsOnSort &&
          dsOnSort(
            Array.isArray(currentQuickFilter?.orderBy)
              ? currentQuickFilter?.orderBy
              : [],
          );
        setFilter(
          currentQuickFilter?.filter
            ? {
                ...FilterBarFactory.filterFromFields(
                  quickFields,
                  searchWildcard,
                ),
                quickFilterLabel: currentQuickFilter?.label,
              }
            : { quickFilterLabel: currentQuickFilter?.label },
        );
      }
    }
  };

  return (
    <QuickWrapper>
      <StyledSelectField>
        <Select
          e2e="quick-filter-typeahead"
          a11yLabel="quick-filter-select"
          dsOnChange={(e) =>
            handleChangeQuickFilters(e?.target?.value as string)
          }
          placeholder={translate('QuickFilterPlaceholder')}
          value={quickValueId}
        >
          {quickFilters &&
            quickFilters.map((filter) => {
              return (
                <SelectItem key={filter.id} value={filter.id}>
                  {filter.label}
                </SelectItem>
              );
            })}
        </Select>
      </StyledSelectField>
    </QuickWrapper>
  );
};
