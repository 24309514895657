import * as React from 'react';
import {
  Popover as MuiPopover,
  PopoverProps as MUIPopoverProps,
  Theme,
  useTheme,
  Box,
} from '@mui/material';
import styled from '@emotion/styled';
import { transientOptions } from '../utils';
import { useThemeTokens } from '../Theme';

export interface PopoverProps
  extends Pick<
    MUIPopoverProps,
    | 'anchorEl'
    | 'anchorOrigin'
    | 'transformOrigin'
    | 'open'
    | 'className'
    | 'classes'
    | 'disableRestoreFocus'
    | 'disablePortal'
  > {
  /*
   * ID of popover element. The ID supplied here should also
   * be given to the invoking button as 'aria-describedBy'.
   */
  a11yId: string;
  children?: React.ReactNode;
  dsOnClose?: MUIPopoverProps['onClose'];
  e2e?: string;
  body?: React.ReactNode;
  arrow?: boolean;
  pointerEventsNone?: boolean;
  paperProps?: MUIPopoverProps['PaperProps'];
}

const StyledPopover = styled(MuiPopover, transientOptions)<{
  transformOrigin: MUIPopoverProps['transformOrigin'];
  anchorOrigin: MUIPopoverProps['anchorOrigin'];
  $arrow: boolean;
  $pointerEventsNone: boolean;
  $theme: Theme;
  $emphasis: ReturnType<typeof useThemeTokens>;
}>`
  & .MuiPopover-paper {
    border: ${({ $emphasis }) => `1px solid ${$emphasis.contextHigh}`};
  }

  ${({ $pointerEventsNone }) =>
    $pointerEventsNone ? 'pointer-events: none;' : ''}

  ${({ transformOrigin, anchorOrigin, $arrow, $theme, $emphasis }) => {
    const getArrowPosition = (
      transformOrigin: MUIPopoverProps['transformOrigin'],
      anchorOrigin: MUIPopoverProps['anchorOrigin'],
      $theme: Theme,
    ) => {
      const calcPosition = (origin) => {
        switch (origin) {
          case 'left':
          case 'top':
            return '15px;';
          case 'center':
            return 'calc(50% - 5px);';
          case 'right':
          case 'bottom':
            return 'calc(100% - 25px);';
          default:
            break;
        }
        return '';
      };
      let position = '',
        margin = '';
      if (
        anchorOrigin?.vertical === 'top' &&
        transformOrigin?.vertical === 'bottom'
      ) {
        position += 'bottom: -5px;';
        position += `left: ${calcPosition(anchorOrigin?.horizontal)}`;
        margin = `margin-top: -${$theme.spacing(1.5)};`;
      } else if (
        anchorOrigin?.horizontal === 'left' &&
        transformOrigin?.horizontal === 'right'
      ) {
        position = `top: ${calcPosition(anchorOrigin?.vertical)}`;
        position += 'right: -5px;';
        margin = `margin-left: -${$theme.spacing(1.5)};`;
      } else if (
        anchorOrigin?.horizontal === 'right' &&
        transformOrigin?.horizontal === 'left'
      ) {
        position = `top: ${calcPosition(anchorOrigin?.vertical)}`;
        position += 'left: -5px;';
        margin = `margin-left: ${$theme.spacing(1.5)};`;
      } else if (
        anchorOrigin?.vertical === 'bottom' &&
        transformOrigin?.vertical === 'top'
      ) {
        position += 'top: -5px;';
        position += `left: ${calcPosition(anchorOrigin?.horizontal)}`;
        margin = `margin-top: ${$theme.spacing(1.5)};`;
      }
      return { position, margin };
    };
    const { position, margin } = getArrowPosition(
      transformOrigin,
      anchorOrigin,
      $theme,
    );
    const arrowStyle =
      position &&
      `
      & .MuiPopover-paper {
        overflow: visible;
        transform: translate3d(0, 0, 0) !important;
        &::before {
          content: "";
          background-color: ${$theme.palette.background.paper};
          border-radius: ${$theme.spacing(0.5)};
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        },
        &::after {
          content: "";
          background-color: ${$theme.palette.background.paper};
          width: 10px;
          height: 10px;
          transform: rotate(-45deg);
          position: absolute;
          border: 1px solid ${$emphasis.contextHigh};
          z-index: -2;
          ${position}
        }
      }
    `;
    return `
    ${$arrow ? arrowStyle : ''}
    ${margin}
    `;
  }}
`;

const Popover: React.FC<PopoverProps> = ({
  a11yId,
  children,
  className,
  disableRestoreFocus,
  dsOnClose,
  e2e,
  body,
  anchorEl = null,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  open,
  paperProps,
  arrow = false,
  pointerEventsNone = false,
  disablePortal = false,
}: PopoverProps) => {
  const theme = useTheme();
  const themeTokens = useThemeTokens();

  if (!anchorEl) {
    return null;
  }

  return (
    <StyledPopover
      className={className}
      disableRestoreFocus={disableRestoreFocus}
      {...(e2e && { 'data-e2e': e2e })}
      id={a11yId}
      open={open}
      onClose={dsOnClose}
      anchorEl={anchorEl}
      transformOrigin={transformOrigin}
      anchorOrigin={anchorOrigin}
      PaperProps={paperProps}
      disablePortal={disablePortal}
      $arrow={arrow}
      $theme={theme}
      $pointerEventsNone={pointerEventsNone}
      $emphasis={themeTokens}
    >
      <Box
        sx={{
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          pointerEvents: 'auto',
        }}
        {...(e2e && { 'data-e2e': `${e2e}-body` })}
      >
        {body} {children}
      </Box>
    </StyledPopover>
  );
};

export default Popover;
