import * as React from 'react';
import {
  DataType,
  ValueType,
  ColumnType,
  ColumnWidth,
  OrderType,
  TableProps,
  Filter,
  FilterBarOptions,
  RowDisplayOptions,
} from './index';
import deepEqual from 'fast-deep-equal';
import { FilterFields, InternalOperator } from './types';
import {
  FilterUtilFactory,
  FilterUtilFactoryType,
  omitDeepFilter,
} from './utils';
import { isEqual } from 'lodash';
import { designTokens } from '../Theme.design-tokens';
import { TableRowProps, Theme } from '@mui/material';
import { FilterBarFactory } from './FilterBar/utils';

export const useEditableRow = ({
  uniqueKey,
  rows,
  dsOnEditChange,
  dsOnEditingRow,
  delegatedEditableRow,
}: Pick<
  TableProps,
  | 'uniqueKey'
  | 'rows'
  | 'dsOnEditChange'
  | 'delegatedEditableRow'
  | 'dsOnEditingRow'
>) => {
  const [editableRow, setEditableRow] = React.useState<DataType | undefined>(
    delegatedEditableRow,
  );

  React.useEffect(() => {
    setEditableRow(delegatedEditableRow);
  }, [delegatedEditableRow]);

  const handleToggleRowEdit = (row: DataType) => {
    const rowIndex = rows.findIndex((r) => r[uniqueKey] === row[uniqueKey]);

    if (!editableRow || row[uniqueKey] !== editableRow[uniqueKey]) {
      dsOnEditingRow?.({ row, rowIndex });
      setEditableRow(row);
    } else if (row[uniqueKey] === editableRow[uniqueKey]) {
      dsOnEditChange?.({ row: editableRow, rowIndex });
      setEditableRow(undefined);
    }
  };

  const handleInputChange = React.useCallback(
    ({ value, column }: { value: ValueType; column: ColumnType }) => {
      const newRow = { ...editableRow };
      newRow[column.field] = value;
      setEditableRow(newRow);
    },
    [editableRow],
  );

  return {
    editableRow,
    handleToggleRowEdit,
    handleInputChange,
  };
};

type EditableRows = {
  [key: string]: DataType;
};

export const useEditableRows = ({
  uniqueKey,
  rows,
  dsOnEditChange,
  displayRows,
  pageStart,
  pageEnd,
  isGlobalEditModeEnabled,
  offsetNestedRows,
}: Pick<
  TableProps,
  'uniqueKey' | 'rows' | 'dsOnEditChange' | 'isGlobalEditModeEnabled'
> & {
  displayRows: TableProps['rows'];
  pageStart?: number;
  pageEnd?: number;
  offsetNestedRows?: number;
}) => {
  const fillRowsData = React.useCallback(() => {
    if (!isGlobalEditModeEnabled) {
      return;
    }

    const data = {};
    const startIndex = pageStart || 0;
    let endIndex =
      (pageEnd && pageEnd + (offsetNestedRows || 0)) || displayRows.length;

    for (let index = startIndex; index < endIndex; index++) {
      if (displayRows[index]) {
        data[displayRows[index][uniqueKey] as string] = {
          ...displayRows[index],
        };

        const nestedRowsFromLastRow = displayRows.filter(
          (row) => row.parentId === displayRows[index].id,
        );
        endIndex += nestedRowsFromLastRow.length;
      }
    }

    return data;
  }, [pageStart, pageEnd, displayRows, uniqueKey, isGlobalEditModeEnabled]);

  const [editableRows, setEditableRows] = React.useState<
    EditableRows | undefined
  >(fillRowsData);

  React.useEffect(() => {
    if (!isGlobalEditModeEnabled) {
      return;
    }

    setEditableRows(fillRowsData());
  }, [
    fillRowsData,
    pageStart,
    pageEnd,
    rows,
    displayRows,
    uniqueKey,
    isGlobalEditModeEnabled,
  ]);

  const handleInputBlur = React.useCallback(
    ({
      row,
      editableRow,
    }: {
      column: ColumnType;
      row: DataType;
      editableRow?: DataType;
    }) => {
      if (
        !editableRow ||
        row[uniqueKey] !== editableRow[uniqueKey] ||
        deepEqual(row, editableRow)
      ) {
        return;
      }

      const rowIndex = rows.findIndex((r) => r[uniqueKey] === row[uniqueKey]);
      dsOnEditChange?.({ row: editableRow, rowIndex });
    },
    [editableRows],
  );

  const handleInputChange = React.useCallback(
    ({
      value,
      column,
      row,
    }: {
      value: ValueType;
      column: ColumnType;
      row: DataType;
    }) => {
      if (!editableRows) {
        return;
      }

      const uniqueValue = row[uniqueKey] as string;
      const newRow = { ...editableRows[uniqueValue] };

      if (row[uniqueKey] !== newRow[uniqueKey]) {
        return;
      }

      newRow[column.field] = value;
      setEditableRows({
        ...editableRows,
        [newRow[uniqueKey] as string]: newRow,
      });

      // Components that are not TextFields do not have reliable Blurs, initiate value save here.
      if (
        ['boolean', 'date', 'date-time', 'picklist', 'typeahead'].includes(
          column?.type || '',
        )
      ) {
        handleInputBlur({ row, editableRow: newRow, column });
      }
    },
    [setEditableRows, editableRows, uniqueKey],
  );

  return {
    editableRows,
    handleInputBlur,
    handleInputChange,
  };
};

export const useCustomColumns = ({
  inputColumns,
  dsOnColumnsChange,
}: {
  inputColumns: TableProps['columns'];
  dsOnColumnsChange: TableProps['dsOnColumnsChange'];
}) => {
  const reshapeInputColumns = (inputCols: TableProps['columns']) =>
    inputCols.map((c) => {
      if ('hidden' in c) {
        return c;
      }
      return { ...c, hidden: false };
    });
  const [columns, setColumns] = React.useState<ColumnType[]>(() =>
    reshapeInputColumns(inputColumns),
  );

  const handleColumnsChange = (columns: ColumnType[]) => {
    setColumns(columns);
    dsOnColumnsChange?.(columns);
  };

  React.useEffect(() => {
    setColumns(reshapeInputColumns(inputColumns));
  }, [inputColumns]);

  return {
    columns,
    handleColumnsChange,
  };
};

export const useColumnWidths = ({
  columnRefs,
  dsOnResizeChange,
  inputColumns,
}: {
  columnRefs: React.MutableRefObject<{ [key: string]: HTMLTableCellElement }>;
  dsOnResizeChange: TableProps['dsOnResizeChange'];
  inputColumns: TableProps['columns'];
}) => {
  const [columnWidths, setColumnWidths] = React.useState<ColumnWidth[]>([]);

  React.useEffect(() => {
    const columnNames = inputColumns.map((column) => column.field);
    const currentWidths = columnNames.map((columnName) => {
      const columnRef = columnRefs.current[columnName];
      return {
        field: columnName,
        width: columnRef?.offsetWidth,
      };
    });

    dsOnResizeChange?.(currentWidths);
    setColumnWidths(currentWidths);
  }, []);

  const updateColumnWidths = (newColWidth: ColumnWidth) => {
    const field = newColWidth.field;
    const index = columnWidths.findIndex(
      (columnWidth) => columnWidth.field === field,
    );

    let updatedColumnWidths;
    if (index === -1) {
      updatedColumnWidths = [...columnWidths, newColWidth];
    } else {
      updatedColumnWidths = [
        ...columnWidths.slice(0, index),
        newColWidth,
        ...columnWidths.slice(index + 1),
      ];
    }

    dsOnResizeChange?.(updatedColumnWidths);
    setColumnWidths(updatedColumnWidths);
  };

  return {
    updateColumnWidths,
  };
};

export const usePagination = ({
  inputRowsPerPage,
  dsOnPage,
  setInputRowsPerPage,
  singlePageLoadingOptions,
}: {
  inputRowsPerPage: TableProps['rowsPerPage'];
  dsOnPage: TableProps['dsOnPage'];
  setInputRowsPerPage?: (rows: number) => void;
  singlePageLoadingOptions: TableProps['singlePageLoadingOptions'];
}) => {
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(inputRowsPerPage || 5);

  const { pageStart, pageEnd } = React.useMemo(() => {
    return singlePageLoadingOptions
      ? {
          pageStart: 0,
          pageEnd: rowsPerPage,
        }
      : {
          pageStart: page * rowsPerPage,
          pageEnd: page * rowsPerPage + rowsPerPage,
        };
  }, [page, rowsPerPage]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    dsOnPage?.({ page: newPage, rowsPerPage });
  };

  const updateRowsPerPage = (rowCountPerPage: number) => {
    if (rowsPerPage !== rowCountPerPage) {
      setRowsPerPage(rowCountPerPage);
      setInputRowsPerPage?.(rowCountPerPage);
      setPage(0);
      dsOnPage?.({ page: 0, rowsPerPage: rowCountPerPage });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateRowsPerPage(+event.target.value);
  };

  return {
    page,
    rowsPerPage,
    pageStart,
    pageEnd,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSetRowsPerPage: updateRowsPerPage,
  };
};

export const useSearch = ({
  page,
  handleChangePage,
  dsOnSearchChange,
}: {
  page: number;
  handleChangePage: (newPage: number) => void;
  dsOnSearchChange: TableProps['dsOnSearchChange'];
}) => {
  const [search, setSearch] = React.useState<string>('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    dsOnSearchChange?.(event.target.value);
    if (page > 0) handleChangePage(0);
  };

  return {
    search,
    handleSearch,
  };
};

export const useSort = ({
  dsOnSort,
  initialSort,
  sortStrategy,
  defaultQuickFilter,
}) => {
  const [order, setOrder] = React.useState<OrderType>(
    initialSort?.[0]?.order?.toLowerCase() || 'asc',
  );

  const [orderBy, setOrderBy] = React.useState<keyof DataType | undefined>(
    initialSort?.[0]?.field || undefined,
  );

  const handleSortClick = (
    property?: keyof DataType,
    customOrder?: OrderType,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    if (sortStrategy === 'client') {
      setOrder(customOrder || isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    } else if (sortStrategy === 'server') {
      dsOnSort?.([
        {
          field: property,
          order: (customOrder || isAsc ? 'desc' : 'asc').toUpperCase(),
        },
      ]);
    }
  };

  React.useEffect(() => {
    if (sortStrategy === 'client') {
      dsOnSort?.([
        {
          field: orderBy,
          order: order.toUpperCase(),
        },
      ]);
    }
  }, [dsOnSort, order, orderBy]);

  React.useEffect(() => {
    setOrder(
      initialSort?.[0]?.order?.toLowerCase() ||
        defaultQuickFilter?.orderBy?.[0]?.order?.toLowerCase(),
    );
    setOrderBy(
      initialSort?.[0]?.field || defaultQuickFilter?.orderBy?.[0].field,
    );
  }, [initialSort, defaultQuickFilter]);

  return {
    order,
    orderBy,
    handleSortClick,
  };
};

export const useFilter = ({
  dsOnFilter,
  initialFilter,
  filterableFields,
  enableLegacyFilters,
  defaultFields,
  orderBy,
  resetPage,
  resetPageOnSort,
  vanityFilters,
  initialFields,
  filterBarOptions,
  defaultQuickFilter,
  searchWildcard,
}) => {
  const [fields, setFields] = React.useState<FilterFields>({});
  const [filter, setFilter] = React.useState<any>(undefined);
  const chipsFilter = filter || initialFilter;

  const [includeCustomFields, setIncludeCustomFields] =
    React.useState<boolean>(false);
  const [searchMode, setSearchMode] = React.useState(
    filterBarOptions?.defaultSearchOperator || FilterBarFactory.textModeDefault,
  );

  const internalFilter = React.useRef<any>({});

  const filterUtil = FilterUtilFactory(
    enableLegacyFilters
      ? FilterUtilFactoryType.Legacy
      : FilterUtilFactoryType.FilterBar,
  );

  React.useEffect(() => {
    if (filterableFields && filterBarOptions) {
      const generatedFields = filterUtil.filterToFields(
        filterableFields,
        initialFilter,
        defaultFields,
        vanityFilters,
        initialFilter ? undefined : defaultQuickFilter,
        filterBarOptions,
        searchWildcard,
      );
      setFields(generatedFields);
      if (
        !initialFilter &&
        defaultQuickFilter?.orderBy &&
        Array.isArray(defaultQuickFilter?.orderBy)
      ) {
        resetPageOnSort(
          defaultQuickFilter?.orderBy[0]?.field,
          defaultQuickFilter?.orderBy[0]?.order,
        );
      }
    }
  }, [filterableFields, initialFilter, filterBarOptions, searchWildcard]);

  React.useEffect(() => {
    if (!isEqual(internalFilter.current, filter)) {
      internalFilter.current = filter;

      /** Set Filter */
      if (dsOnFilter && filter) {
        const cleanedFilter = omitDeepFilter(filter, ['fieldId']);

        dsOnFilter({ filter: cleanedFilter });
        resetPage();
      }
    }
  }, [filter]);

  const removeFilter = () => {
    setFilter(
      filterUtil.filterFromFields(initialFields.current, searchWildcard) || {},
    );
    setFields(initialFields.current);

    /** Unset Sort when Filter clears */
    if (orderBy) {
      resetPageOnSort();
    }
  };

  const removeFilterByField = (chip) => {
    if (chipsFilter?.conditions?.length > 1) {
      const modifiedFilter = {
        ...chipsFilter,
        conditions: chipsFilter?.conditions.filter(
          (condition) => condition.field !== chip.field,
        ),
      };

      setFilter(modifiedFilter as Filter);
      setFields(
        filterUtil.filterToFields(
          filterableFields!,
          modifiedFilter,
          defaultFields,
          vanityFilters,
          undefined,
          filterBarOptions,
          searchWildcard,
        ),
      );
    } else {
      removeFilter();
    }
  };

  const changeQueryParameters = () => {
    setSearchMode(InternalOperator.STARTSWITH);
    setIncludeCustomFields(false);
  };

  return {
    setFilter,
    setFields,
    filterUtil,
    fields,
    filter,
    chipsFilter,
    removeFilterByField,
    removeFilter,
    includeCustomFields,
    setIncludeCustomFields,
    searchMode,
    setSearchMode,
    changeQueryParameters,
  };
};

interface UseGroupedDataArgs {
  groupByKey: TableProps['groupByKey'];
  searchedAndSortedRows: DataType[];
  nestedDataKey?: TableProps['nestedDataKey'];
  hideGroupCheckbox?: boolean;
  fixedGroupHeader?: boolean;
  initGroupRowsCollapsed?: boolean;
}

export const useGroupedData = ({
  groupByKey,
  searchedAndSortedRows,
  nestedDataKey,
  hideGroupCheckbox,
  fixedGroupHeader,
  initGroupRowsCollapsed,
}: UseGroupedDataArgs) => {
  const [collapsedGroups, setCollapsedGroups] = React.useState<{
    [key: string]: boolean;
  }>({});

  const nestedRows = React.useMemo(() => {
    const rows = searchedAndSortedRows.filter((item) => !!item.parentId);

    return rows;
  }, [searchedAndSortedRows]);

  const [collapsedRows, setCollapsedRows] = React.useState<{
    [key: string]: boolean;
  }>(
    (nestedRows as any).reduce(
      (acc, row) => ({
        ...acc,
        [row.parentId]: true,
      }),
      {},
    ),
  );

  const [allGroupExpanded, setAllGroupExpanded] = React.useState(true);

  const [initGroupRowsCollapsedCompleted, setInitGroupRowsCollapsedCompleted] =
    React.useState(false);

  React.useEffect(() => {
    if (
      groupByKey &&
      initGroupRowsCollapsed &&
      !initGroupRowsCollapsedCompleted
    ) {
      setCollapsedGroups(
        [
          ...new Set(
            searchedAndSortedRows.map((row) => String(row[groupByKey])),
          ),
        ].reduce((acc, curr) => ((acc[curr] = true), acc), {}),
      );
      setInitGroupRowsCollapsedCompleted(true);
    }
  }, [initGroupRowsCollapsed]);

  React.useEffect(() => {
    setAllGroupExpanded(
      !Object.values(collapsedGroups).some(Boolean) &&
        !Object.values(collapsedRows).some(Boolean),
    );
  }, [collapsedGroups, collapsedRows]);

  const onToggleHeaderRow = (key: string) => {
    setCollapsedGroups({
      ...collapsedGroups,
      [key]: !collapsedGroups[key],
    });
  };

  const onToggleRow = React.useCallback(
    (key: string) => {
      setCollapsedRows({
        ...collapsedRows,
        [key]: !collapsedRows[key],
      });
    },
    [collapsedRows],
  );

  const groupedRows: any = React.useMemo(() => {
    if (!groupByKey) {
      return {
        collection: searchedAndSortedRows,
        map: {
          '': searchedAndSortedRows,
        },
      };
    }

    // a map of grouped rows, sorted into buckets
    // by groupByKey
    const map = searchedAndSortedRows.reduce((acc, row) => {
      // omit nested rows
      if (row.hasOwnProperty(String(nestedDataKey))) return acc;

      const key = String(row[groupByKey]);

      if (Array.isArray(key)) return acc;

      if (Array.isArray(acc[key])) {
        // @ts-ignore
        acc[key].push(row);
      } else {
        // @ts-ignore
        acc[key] = [row];
      }
      return acc;
    }, {});

    // a flat ordered collection of each bucket, joined "end to end"
    let collection: any[] = [];
    Object.entries(map).forEach(([key, children]) => {
      // cut collapsed collections out
      if (!collapsedGroups[key]) {
        collection = collection.concat(children);
      }
    });

    return {
      map,
      collection,
    };
  }, [searchedAndSortedRows, groupByKey, collapsedGroups, nestedDataKey]);

  const onExpandAll = React.useCallback(
    (groupKey: string) => {
      const rows: any = nestedRows.filter((item) => item.state === groupKey);

      setCollapsedRows({
        ...collapsedRows,
        ...rows.reduce(
          (acc, row) => ({
            ...acc,
            [row.parentId]: false,
          }),
          {},
        ),
      });
    },
    [collapsedRows, nestedRows],
  );

  const onCollapseAll = React.useCallback(
    (groupKey: string) => {
      const rows: any = nestedRows.filter((item) => item.state === groupKey);

      setCollapsedRows({
        ...collapsedRows,
        ...rows.reduce(
          (acc, row) => ({
            ...acc,
            [row.parentId]: true,
          }),
          {},
        ),
      });
    },
    [nestedRows, collapsedRows],
  );

  const onToggleTableHeader = React.useCallback(() => {
    if (!fixedGroupHeader) {
      setCollapsedGroups(
        Object.keys(groupedRows.map).reduce(
          (acc, key) => (key ? { ...acc, [key]: allGroupExpanded } : acc),
          {},
        ),
      );
    }

    setCollapsedRows(
      (nestedRows as any).reduce(
        (acc, row) => ({
          ...acc,
          [row.parentId]: allGroupExpanded,
        }),
        {},
      ),
    );
  }, [nestedRows, allGroupExpanded, fixedGroupHeader, groupedRows.map]);

  return {
    collapsedGroups,
    collapsedRows,
    groupedRows,
    onToggleHeaderRow,
    onToggleRow,
    onExpandAll,
    onCollapseAll,
    onToggleTableHeader,
    allGroupExpanded,
    hideCheckbox: hideGroupCheckbox,
  };
};

const flattenChildren = (
  rows: TableProps['rows'],
  nestedRowsMap: { [key: string]: DataType[] },
  uniqueKey: TableProps['uniqueKey'],
): TableProps['rows'] => {
  return rows.reduce((acc, row) => {
    if (nestedRowsMap[row[uniqueKey] as string]) {
      return acc.concat(
        flattenChildren(
          nestedRowsMap[row[uniqueKey] as string],
          nestedRowsMap,
          uniqueKey,
        ),
        row,
      );
    }

    return [...acc, row];
  }, [] as TableProps['rows']);
};

const getRowsMap = (
  rows: TableProps['rows'],
  nestedRowsMap: { [key: string]: DataType[] },
  uniqueKey: TableProps['uniqueKey'],
  dsIsRowSelectable?: TableProps['dsIsRowSelectable'],
  dsIsRowSelectionDisabled?: TableProps['dsIsRowSelectionDisabled'],
) => {
  const selectableRows = flattenChildren(rows, nestedRowsMap, uniqueKey).filter(
    (row) => {
      if (dsIsRowSelectionDisabled && dsIsRowSelectionDisabled({ row })) {
        return false;
      }
      if (dsIsRowSelectable) {
        return dsIsRowSelectable({
          row,
          children: nestedRowsMap[row[uniqueKey] as string],
        });
      }
      return true;
    },
  );
  return selectableRows.reduce(
    (acc, row) => {
      return {
        ...acc,
        [row[uniqueKey] as string]: row,
      };
    },
    {} as {
      [key: string]: DataType;
    },
  );
};

const flattenNestedChildren = (
  nestedRowsMap: { [key: string]: DataType[] },
  key: string,
  uniqueKey = 'id',
) => {
  const rowsArray = nestedRowsMap[key] || [];
  let acc = [...rowsArray];

  rowsArray.forEach((parentRow) => {
    if (nestedRowsMap[parentRow[uniqueKey] as string]) {
      const nestedRowsArray = flattenNestedChildren(
        nestedRowsMap,
        parentRow[uniqueKey as string] as string,
        uniqueKey,
      );
      acc = [...acc, ...nestedRowsArray];
    }
  });

  return acc;
};

export const useMultiSelect = ({
  uniqueKey,
  selectedRows,
  rows,
  rowsInView,
  nestedRowsMap,
  nestedDataKey,
  groupByKey = '',
  dsOnSelectionChange,
  dsIsRowSelectable,
  dsIsRowSelectionDisabled,
  selectionStrategy,
}: {
  uniqueKey: TableProps['uniqueKey'];
  selectedRows: TableProps['selectedRows'];
  rows: DataType[];
  rowsInView: DataType[];
  nestedRowsMap: { [key: string]: DataType[] };
  nestedDataKey?: TableProps['nestedDataKey'];
  groupByKey: TableProps['groupByKey'];
  dsOnSelectionChange: TableProps['dsOnSelectionChange'];
  dsIsRowSelectable: TableProps['dsIsRowSelectable'];
  dsIsRowSelectionDisabled: TableProps['dsIsRowSelectionDisabled'];
  selectionStrategy: TableProps['selectionStrategy'];
}) => {
  const [selectedMap, setSelectedMap] = React.useState<{
    [key: string]: DataType;
  }>({});
  const rowSet = selectionStrategy === 'page' ? rowsInView : rows;
  const rowsMap = getRowsMap(rowSet, nestedRowsMap, uniqueKey);
  const selectableRowsMap = getRowsMap(
    rowSet,
    nestedRowsMap,
    uniqueKey,
    dsIsRowSelectable,
    dsIsRowSelectionDisabled,
  );

  const updateGroupHeader = (
    groupKey: string,
    sMap: {
      [key: string]: DataType;
    },
  ) => {
    const topLevelGroup = rowSet.filter(
      (row) => row[groupByKey as string] === groupKey,
    );
    let groupWithNestedChildren = [...topLevelGroup];
    topLevelGroup.forEach((item) => {
      groupWithNestedChildren = groupWithNestedChildren.concat(
        flattenNestedChildren(
          nestedRowsMap,
          item[uniqueKey] as string,
          uniqueKey,
        ),
      );
    });

    if (
      groupWithNestedChildren.every((row) => !!sMap[row[uniqueKey] as string])
    ) {
      sMap[groupKey as string] = {};
    } else {
      delete sMap[groupKey as string];
    }

    return sMap;
  };

  const updateGroupHeaders = (sMap: { [key: string]: DataType }) => {
    const uniqueGroupKeys = [...new Set(rowSet.map((row) => row[groupByKey]))];

    uniqueGroupKeys.forEach((groupKey) =>
      updateGroupHeader(groupKey as string, sMap),
    );
    return sMap;
  };

  React.useEffect(() => {
    if (selectedRows) {
      const sMap = Object.values(selectedRows).reduce(
        (acc, row) => {
          if (
            dsIsRowSelectable &&
            !dsIsRowSelectable({
              row,
              children: nestedRowsMap[row[uniqueKey] as string],
            })
          ) {
            return acc;
          }

          return {
            ...acc,
            [row[uniqueKey] as string]: row,
          };
        },
        {} as {
          [key: string]: DataType;
        },
      );
      updateGroupHeaders(sMap);
      setSelectedMap(sMap);
    }
  }, [selectedRows]);

  const informParents = ({
    parentId = '',
    newSelected,
  }: {
    parentId?: string;
    newSelected: {
      [key: string]: DataType;
    };
  }) => {
    const children = nestedRowsMap[parentId];
    if (!children) {
      return newSelected;
    }

    const parentRow = rowsMap[parentId];

    if (
      children.every((child) => !!newSelected[child[uniqueKey] as string]) &&
      (dsIsRowSelectable
        ? dsIsRowSelectable({ row: parentRow, children })
        : true)
    ) {
      newSelected[parentId] = parentRow;
    } else {
      delete newSelected[parentId];
    }

    if (parentRow[nestedDataKey || '']) {
      newSelected = informParents({
        parentId: parentRow[nestedDataKey as string] as string,
        newSelected,
      });
    } else {
      if (!newSelected[parentId]) {
        delete newSelected[parentRow[groupByKey] as string];
      } else {
        updateGroupHeader(parentRow[groupByKey] as string, newSelected);
      }
    }

    return newSelected;
  };

  const getSelectedRowsCount = () => {
    return Object.values(selectedMap).filter(
      (selectedRow) => Object.entries(selectedRow).length > 0,
    ).length;
  };

  const getGroupSelectedRowsCount = (groupKey: string) => {
    return Object.values(selectedMap).filter(
      (selectedRow) =>
        Object.entries(selectedRow).length > 0 &&
        selectedRow[groupByKey] === groupKey,
    ).length;
  };

  const handleSetSelected = (sMap: { [key: string]: DataType }) => {
    setSelectedMap(sMap);
    // TODO: pass back the values? or the hash map?
    dsOnSelectionChange &&
      dsOnSelectionChange(
        Object.values(sMap).filter(
          (selectedRow) => Object.entries(selectedRow).length > 0,
        ),
      );
  };

  const isSelected = (key: string) => !!selectedMap[key];

  const isIndeterminate = (key: string) => {
    const children = nestedRowsMap[key];
    if (!children) {
      return false;
    }

    return (
      !isSelected(key) &&
      children.some(
        (child) =>
          isSelected(child[uniqueKey] as string) ||
          isIndeterminate(child[uniqueKey] as string),
      )
    );
  };

  const isAllSelected = () => {
    if (selectionStrategy === 'page') {
      return (
        Object.values(rowsMap).every((row) => {
          return isSelected(row[uniqueKey] as string);
        }) && !!Object.keys(rowsMap).length
      );
    } else {
      //global selection strategy
      return Object.keys(rowsMap).length === getSelectedRowsCount();
    }
  };

  const isAllSelectIndeterminate = () => {
    return !isAllSelected() && getSelectedRowsCount() > 0;
  };

  const handleSelectAllClick = () => {
    if (
      isAllSelected() ||
      !Object.values(selectableRowsMap).some(
        (row) => !isSelected(row[uniqueKey] as string),
      )
    ) {
      handleSetSelected({});
    } else {
      const newSelected = {
        ...selectedMap,
        ...selectableRowsMap,
      };
      updateGroupHeaders(newSelected);
      handleSetSelected(newSelected);
    }
  };

  const isGroupSelected = (groupKey: string) => {
    if (selectionStrategy === 'page') {
      return (
        isSelected(groupKey) &&
        Object.values(selectedMap).some(
          (selectedRow) =>
            selectedRow[groupByKey as string] === groupKey &&
            rowsMap[selectedRow[uniqueKey] as string],
        )
      );
    } else {
      //global selection strategy
      return isSelected(groupKey);
    }
  };

  const isGroupSelectIndeterminate = (groupKey: string) => {
    if (selectionStrategy === 'page') {
      return (
        !isSelected(groupKey) &&
        Object.values(selectedMap).some(
          (selectedRow) =>
            selectedRow[groupByKey as string] === groupKey &&
            rowsMap[selectedRow[uniqueKey] as string],
        )
      );
    } else {
      //global selection strategy
      return (
        !isSelected(groupKey) &&
        Object.values(selectedMap).some(
          (selectedRow) => selectedRow[groupByKey as string] === groupKey,
        )
      );
    }
  };

  const handleGroupCheckboxClick = (groupKey: string) => {
    const newSelected = {
      ...selectedMap,
    };

    const topLevelGroup = rowSet.filter((row) => row[groupByKey] === groupKey);
    let selectableGroupRows = topLevelGroup.filter((row) => {
      if (dsIsRowSelectable) {
        return dsIsRowSelectable({
          row,
          children: nestedRowsMap[row[uniqueKey] as string],
        });
      }
      return true;
    });
    topLevelGroup.forEach((item) => {
      selectableGroupRows = selectableGroupRows.concat(
        flattenNestedChildren(
          nestedRowsMap,
          item[uniqueKey] as string,
          uniqueKey,
        ).filter((row) => {
          if (dsIsRowSelectable) {
            return dsIsRowSelectable({
              row,
              children: nestedRowsMap[row[uniqueKey] as string],
            });
          }
          return true;
        }),
      );
    });

    if (
      isGroupSelected(groupKey) ||
      !selectableGroupRows.some((row) => !isSelected(row[uniqueKey] as string))
    ) {
      selectableGroupRows.forEach(
        (row) => delete newSelected[row[uniqueKey] as string],
      );
      delete newSelected[groupKey];
    } else {
      selectableGroupRows.forEach(
        (row) => (newSelected[row[uniqueKey] as string] = row),
      );
      updateGroupHeader(groupKey, newSelected);
    }

    handleSetSelected(newSelected);
  };

  const handleCheckboxClick = (row: DataType, key: ValueType) => {
    let newSelected = {
      ...selectedMap,
    };

    // Mark self and check/uncheck Children
    const family = [
      ...flattenNestedChildren(nestedRowsMap, key as string, uniqueKey),
      row,
    ].filter((row) => {
      if (dsIsRowSelectable) {
        return dsIsRowSelectable({
          row,
          children: nestedRowsMap[row[uniqueKey] as string],
        });
      }
      return true;
    });

    if (newSelected[key as string]) {
      family.forEach((row) => delete newSelected[row[uniqueKey] as string]);
    } else {
      family.forEach((row) => (newSelected[row[uniqueKey] as string] = row));
    }

    // Propagate to ancestors if all children are checked/unchecked
    const parentId = row[nestedDataKey as string];
    if (parentId) {
      newSelected = informParents({
        parentId: parentId as string,
        newSelected,
      });
    } else {
      if (!newSelected[key as string]) {
        delete newSelected[row[groupByKey] as string];
      } else {
        newSelected = updateGroupHeader(row[groupByKey] as string, newSelected);
      }
    }

    handleSetSelected(newSelected);
  };

  const handleClearSelections = () => {
    handleSetSelected({});
  };

  return {
    selectedMap,
    handleSelectAllClick,
    handleGroupCheckboxClick,
    handleCheckboxClick,
    handleClearSelections,
    isSelected,
    isIndeterminate,
    getSelectedRowsCount,
    getGroupSelectedRowsCount,
    isAllSelectIndeterminate,
    isAllSelected,
    isGroupSelectIndeterminate,
    isGroupSelected,
  };
};

export const useFilterBarOptions = (
  userFilterBarOptions: FilterBarOptions = {},
): FilterBarOptions | undefined => {
  const [filterBarOptions, setFilterBarOptions] = React.useState<
    FilterBarOptions | undefined
  >();
  const lastUserFilterOptions = React.useRef<FilterBarOptions>();

  const defaultFilterBarOptions = {
    defaultSearchOperator: InternalOperator.CONTAINS,
    defaultIncludeCustomFields: true,
  };

  React.useEffect(() => {
    if (!isEqual(lastUserFilterOptions.current, userFilterBarOptions)) {
      lastUserFilterOptions.current = userFilterBarOptions;
      setFilterBarOptions({
        ...defaultFilterBarOptions,
        ...userFilterBarOptions,
      });
    }
  }, [userFilterBarOptions]);

  return filterBarOptions;
};

export interface HighlightColumnMap {
  [columnField: string]: string;
}
export const useColumnHighlighter = ({
  scrollToColumns,
  columnRefs,
}: {
  scrollToColumns: TableProps['scrollToColumns'];
  columnRefs: React.MutableRefObject<{ [key: string]: HTMLTableCellElement }>;
}): {
  highlightColumnMap: HighlightColumnMap;
} => {
  const [highlightColumnMap, setHighlightColumnMap] =
    React.useState<HighlightColumnMap>({});
  const highlightTimer = React.useRef<number>();
  const highlightColor = designTokens.colors.lightSelection;

  React.useEffect(() => {
    if (Array.isArray(scrollToColumns) && scrollToColumns.length > 0) {
      const scrollToColumnField = scrollToColumns[scrollToColumns.length - 1];
      const scrollToColumn = columnRefs.current[scrollToColumnField];
      if (scrollToColumn) {
        scrollToColumn.focus();
        scrollToColumn.blur();
      } else {
        console.error(
          'Table scrollToColumns could not find column.field === ' +
            scrollToColumnField,
        );
      }
    }

    setHighlightColumnMap(
      scrollToColumns?.reduce((acc, next) => {
        acc[next] = highlightColor;
        return acc;
      }, {}) || {},
    );

    const resetHighlightMap = () => setHighlightColumnMap({});
    highlightTimer.current = setTimeout(resetHighlightMap, 500);

    return () => {
      clearTimeout(highlightTimer.current);
      resetHighlightMap();
    };
  }, [JSON.stringify(scrollToColumns)]);

  return {
    highlightColumnMap,
  };
};

export interface RowDisplayUtils {
  setHighlightRowKey: (key: string) => void;
  getRowStyle: (rowIndex: number, rowKey: string) => TableRowProps['sx'];
}
export const useRowDisplayOptions = ({
  rowDisplayOptions: userRowDisplayOptions = {},
  overflowColor = '',
  theme,
  isSelected,
  isIndeterminate,
}: {
  rowDisplayOptions?: RowDisplayOptions;
  overflowColor?: string;
  theme: Theme;
  isSelected: (uniqueValue: string) => boolean;
  isIndeterminate: (uniqueValue: string) => boolean;
}): RowDisplayUtils => {
  const [highlightRowKey, setHighlightRowKey] = React.useState<string>();

  const baseRowDisplayOptions = {
    alternatingColor: false,
    hoverEffect: true,
    selection: true,
    highlight: false,
  };
  const rowDisplayOptions = {
    ...baseRowDisplayOptions,
    ...userRowDisplayOptions,
  };

  return {
    setHighlightRowKey,
    getRowStyle: (rowIndex, rowKey) => {
      const coreStyle = () => {
        // if row highlight
        if (
          rowDisplayOptions.highlight &&
          highlightRowKey &&
          highlightRowKey === rowKey
        ) {
          return {
            backgroundColor: `${designTokens.colors.darkGreen100} !important`,
            color: designTokens.colors.lightEmphasisHigh,
            fontVariationSettings: '"GRAD" 500',
          };
        }

        // if row select
        if (
          rowDisplayOptions.selection &&
          (isSelected(rowKey) || isIndeterminate(rowKey))
        ) {
          return {
            backgroundColor: `${designTokens.colors.lightActionSelected} !important`,
          };
        }

        // if rows alternatingColor
        if (rowDisplayOptions.alternatingColor && rowIndex % 2 === 0) {
          return {
            backgroundColor: theme.palette.background.default,
          };
        }

        return {
          backgroundColor: overflowColor || '',
        };
      };

      const hoverStyle = () => {
        return rowDisplayOptions.hoverEffect
          ? {
              backgroundColor: `${designTokens.colors.lightActionHover}`,
            }
          : {};
      };

      return {
        '&:hover': {
          '& td': hoverStyle(),
        },
        '& td': coreStyle(),
      };
    },
  };
};
