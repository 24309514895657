import * as React from 'react';
import { TextField, InputAdornment, useTheme } from '@mui/material';
import Icon from '../Icon';

const placeholderStyles = {
  fontStyle: 'italic',
  fontSize: '14px',
};

interface SearchBarProps {
  e2e?: string;
  search: string;
  searchPlaceholder?: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar = ({
  e2e,
  search,
  searchPlaceholder = 'Type here to filter results...',
  onSearch,
}: SearchBarProps) => {
  const [focused, setFocused] = React.useState(false);
  const theme = useTheme();

  return (
    <TextField
      {...(e2e && { 'data-e2e': `${e2e}-table-search` })}
      className="search"
      sx={{
        padding: `${theme.spacing(1.5)} 0`,
        display: 'flex',
        alignItems: 'center',
      }}
      type="text"
      variant="standard"
      value={search}
      placeholder={searchPlaceholder}
      onChange={onSearch}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      fullWidth
      style={{
        fontSize: '14px',
      }}
      InputProps={{
        sx: {
          '& input': {
            '&::placeholder': placeholderStyles,
            '&::-webkit-input-placeholder': placeholderStyles,
            '&:-moz-placeholder': placeholderStyles,
            '&::-moz-placeholder': placeholderStyles,
            '&:-ms-input-placeholder': placeholderStyles,
          },
        },
        startAdornment: (
          <InputAdornment position="start">
            <Icon
              color={focused ? 'primary' : 'action'}
              body="search"
              __addMargin
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
