import * as React from 'react';
import { Filter } from '../index';
import { FilterFields, TabFilterField } from '../types';
import Tabs from '../../Tabs';
import Tab from '../../Tabs/Tab';
import { useTranslate } from '../../i18n';
import styled from '@emotion/styled';
import { transientOptions } from '../../utils';

interface TabFiltersProps {
  e2e?: string;
  filter: Filter;
  fields: FilterFields;
  setFields: (fields) => void;
  setFilter: (filter) => void;
  removeFilter: () => void;
  tabFilters: TabFilterField[];
  tabFilterIndex: number;
  setTabFilterIndex: any;
}
const WrapperTabsFilter = styled('div', transientOptions)<{}>`
  width: 100%;
`;

export const TabFilters = ({
  e2e,
  filter,
  setFilter,
  tabFilters,
  tabFilterIndex,
  setTabFilterIndex,
}: TabFiltersProps) => {
  const { translate } = useTranslate();
  const [prevFilter, setPrevFilter] = React.useState<any>({});

  const handleChangeTabFilters = (event, value: any) => {
    if (value === 0) {
      setFilter(prevFilter);
      setPrevFilter({});
    } else {
      if (
        Object.keys(prevFilter).length === 0 &&
        JSON.stringify(filter) !==
          JSON.stringify(tabFilters[tabFilterIndex - 1]?.filter)
      ) {
        setPrevFilter(filter);
      }
      setFilter(tabFilters[value - 1].filter);
    }
    setTabFilterIndex(value);
  };

  if (!tabFilters || tabFilters.length === 0) return <></>;

  return (
    <WrapperTabsFilter>
      <Tabs
        e2e={e2e}
        a11yLabel={e2e}
        value={tabFilterIndex}
        dsOnChange={handleChangeTabFilters}
      >
        <Tab
          id="tab-filter-0"
          aria-controls={`tab-filter-${0}`}
          key={0}
          label={translate('AllTabFilterOption')}
        />
        {tabFilters.map(({ label }, idx) => (
          <Tab
            id={`tab-filter-${idx + 1}`}
            aria-controls={`tab-filter-${idx + 1}`}
            key={idx + 1}
            label={label}
          />
        ))}
      </Tabs>
    </WrapperTabsFilter>
  );
};
