import { DateTime, Interval } from 'luxon';
import React, { useEffect } from 'react';
import { Button, DatePicker, Typography } from '../../..';
import { FilterComponentProps, FilterWrapper } from './FilterComponentFactory';
import { MenuItem, MenuList } from '@mui/material';
import { Operator } from '../../types';
import { useTranslate } from '../../../i18n';
import { ClearComponent } from './ClearComponent';
import { PicklistOptionDateType, PicklistOptionType } from '../../index';

enum DateRange {
  LAST30 = 'LAST30',
  LAST60 = 'LAST60',
  LAST90 = 'LAST90',
  CUSTOM = 'CUSTOM',
}

interface PicklistOptionValueType extends Omit<PicklistOptionType, 'value'> {
  value: PicklistOptionDateType;
}

interface PicklistOptionDateShortType {
  days: PicklistOptionDateType['days'];
  fromToday: PicklistOptionDateType['fromToday'];
}

export const DateFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, dsComponentProps, filterableField } =
    props;
  const { translate } = useTranslate();

  const e2e = dsComponentProps?.e2e ?? null;

  const DateRangeTitle = {
    [DateRange.LAST30]: translate('FilterBar_DurationDays', ['30']),
    [DateRange.LAST60]: translate('FilterBar_DurationDays', ['60']),
    [DateRange.LAST90]: translate('FilterBar_DurationDays', ['90']),
    [DateRange.CUSTOM]: translate('FilterBar_CustomRange'),
  };

  const [startDate, setStartDate] = React.useState<DateTime | null>(null);
  const [format, setFormat] = React.useState<string>('mm/dd/yyyy');
  const [endDate, setEndDate] = React.useState<DateTime | null>(null);

  const [customRange, setCustomRange] = React.useState<boolean>(false);

  useEffect(() => setCustomRange(false), []);

  useEffect(() => {
    if (state[stateKey].value) {
      setStartDate(
        state[stateKey].value?.[Operator.Ge]
          ? DateTime.fromISO(state[stateKey].value?.[Operator.Ge])
          : null,
      );
      setEndDate(
        state[stateKey].value?.[Operator.Le]
          ? DateTime.fromISO(state[stateKey].value?.[Operator.Le])
          : null,
      );
    } else if (state[stateKey].value === null) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [state]);

  const getStartDate = (
    dateRange,
    { days, fromToday }: PicklistOptionDateShortType = {
      days: '0',
      fromToday: 'add',
    },
  ): DateTime | null => {
    switch (dateRange) {
      case DateRange.LAST30:
        return DateTime.now().startOf('day').minus({ days: 30 });
      case DateRange.LAST60:
        return DateTime.now().startOf('day').minus({ days: 60 });
      case DateRange.LAST90:
        return DateTime.now().startOf('day').minus({ days: 90 });
      case DateRange.CUSTOM:
        return days
          ? DateTime.now().startOf('day')[fromToday](days, 'days')
          : DateTime.now();
    }
    return DateTime.now();
  };

  const stateChange = (
    value: DateTime | { [key: string]: DateTime | null } | null,
    operator?: Operator | string,
  ) => {
    stateChangeFn(
      {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          value,
          operator,
        },
      },
      true,
    );
  };

  const isStartDateInvalid = !startDate?.isValid;
  const isEndDateInvalid = !endDate?.isValid;
  const isInvalidRange =
    startDate &&
    endDate &&
    Interval.fromISO(startDate.toISO() ?? '').isAfter(endDate);
  const isDisabled = isStartDateInvalid || isEndDateInvalid || isInvalidRange;

  return (
    <>
      {!customRange && (
        <MenuList>
          {filterableField?.picklistOptions
            ? [
                (
                  filterableField?.picklistOptions as PicklistOptionValueType[]
                ).map(
                  ({ label, value }, key) =>
                    value && (
                      <MenuItem
                        {...(e2e && { 'data-e2e': `${e2e}-menu-item` })}
                        key={key}
                        onClick={() => {
                          stateChange(
                            getStartDate(DateRange.CUSTOM, value),
                            value.operator,
                          );
                        }}
                      >
                        {label}
                      </MenuItem>
                    ),
                ),
                filterableField?.customSelector && (
                  <MenuItem
                    {...(e2e && { 'data-e2e': `${e2e}-menu-item` })}
                    key={DateRange.CUSTOM}
                    onClick={() => {
                      setCustomRange(true);
                    }}
                  >
                    {DateRangeTitle[DateRange.CUSTOM]}
                  </MenuItem>
                ),
              ]
            : Object.entries(DateRange).map(([key, value]) => (
                <MenuItem
                  {...(e2e && { 'data-e2e': `${e2e}-menu-item` })}
                  key={key}
                  onClick={() => {
                    if (DateRange[key] === DateRange.CUSTOM) {
                      setCustomRange(true);
                    } else {
                      stateChange(getStartDate(DateRange[key]), Operator.Ge);
                    }
                  }}
                >
                  {DateRangeTitle[value]}
                </MenuItem>
              ))}
        </MenuList>
      )}

      {customRange && (
        <FilterWrapper>
          <DatePicker
            {...dsComponentProps}
            label={translate('FilterBar_StartDate')}
            value={startDate}
            maxDate={endDate}
            setFormat={setFormat}
            dsOnChange={setStartDate}
          />

          {startDate && isStartDateInvalid && (
            <Typography variant="caption" color="red">
              {translate('TableInvalidDate_Format', [format])}
            </Typography>
          )}

          <DatePicker
            {...dsComponentProps}
            label={translate('FilterBar_EndDate')}
            value={endDate}
            minDate={startDate}
            dsOnChange={setEndDate}
            setFormat={setFormat}
          />

          {endDate && isEndDateInvalid && (
            <Typography variant="caption" color="red">
              {translate('TableInvalidDate_Format', [format])}
            </Typography>
          )}

          {isInvalidRange && (
            <Typography variant="caption" color="red">
              {translate('InvalidRange')}
            </Typography>
          )}

          <Button
            body={translate('FilterBar_Update')}
            dsOnClick={() =>
              stateChange({ [Operator.Ge]: startDate, [Operator.Le]: endDate })
            }
            disabled={!!isDisabled}
          />
        </FilterWrapper>
      )}

      {!!state[stateKey].value && (
        <ClearComponent onClear={() => stateChange(null)} />
      )}
    </>
  );
};
