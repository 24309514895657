import { Filter, Sort } from '.';

export enum InternalOperator {
  STARTSWITH = 'zds-internal-starts-with',
  CONTAINS = 'zds-internal-contains',
  EQUALS = 'zds-internal-equals',
}

export enum Operator {
  Lt = 'LT',
  Le = 'LE',
  Eq = 'EQ',
  Ge = 'GE',
  Gt = 'GT',
  Sw = 'SW',
  Ne = 'NE',
  In = 'IN',
}

export enum FilterDataTypes {
  BIGINT = 'BIGINT',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  TIMESTAMP = 'TIMESTAMP',
  DATE = 'DATE',
  STRING = 'STRING',
  PICKLIST = 'PICKLIST',
  BOOLEAN = 'BOOLEAN',
  VANITY = 'VANITY',
  TAB = 'TAB',
  BOOLEANPICKLIST = 'BOOLEANPICKLIST',
}

export interface FilterField {
  operator: Operator;
  value: string;
  type: FilterDataTypes;
  dataKey: string;
  label?: string;
  default?: boolean;
  internalOperator?: InternalOperator;
  options?: string[];
  orderBy?: Sort[];
  includeCustomFields?: boolean;
}

export interface VanityFilterField {
  filter: Filter;
  id: string;
  value: boolean;
  label: string;
  type: FilterDataTypes;
}

export interface TabFilterField {
  filter: Filter;
  id: string;
  filteredField: string;
  value: boolean;
  label: string;
  type: FilterDataTypes.TAB;
}

export interface QuickFilterField {
  filter?: Filter;
  id: string;
  orderBy?: Sort[];
  value: boolean;
  label: string;
}

export interface FilterFields {
  [stringifiedFilter: string]: FilterField;
}

export interface Option {
  value: string;
  label: string;
}
