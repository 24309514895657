import React from 'react';
import { FilterComponentProps, FilterWrapper } from './FilterComponentFactory';
import { Operator } from '../../types';
import { Radio, RadioGroup } from '../../..';
import { ClearComponent } from './ClearComponent';

export const BooleanPicklistFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, filterableField, dsComponentProps } =
    props;

  const e2e = dsComponentProps?.e2e ?? null;

  const [radioChoice, setRadioChoice] = React.useState<string>('');

  React.useEffect(() => {
    if (typeof state[stateKey].value === 'undefined') {
      setRadioChoice('');
    } else {
      setRadioChoice(state[stateKey].value);
    }
  }, [state]);

  const dsOnChange = (e) => {
    stateChangeFn(
      {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          value: e.target.value,
          operator: Operator.Eq,
        },
      },
      true,
    );
  };

  return (
    <>
      <FilterWrapper>
        <RadioGroup
          {...(e2e && { e2e: `${e2e}-radio-group` })}
          value={radioChoice}
          dsOnChange={dsOnChange}
          label=""
        >
          {filterableField?.picklistOptions?.map((option, index) => {
            const label = typeof option === 'string' ? option : option.label;
            const value = typeof option === 'string' ? option : option.value;

            return (
              <Radio value={value} label={label} key={`${value}+${index}`} />
            );
          })}
        </RadioGroup>
      </FilterWrapper>
      {!filterableField?.hideClear && (
        <ClearComponent
          onClear={() => dsOnChange({ target: { value: undefined } })}
        />
      )}
    </>
  );
};
