import React from 'react';
import styled from '@emotion/styled';
import { Box, InputAdornment, TextField, useTheme } from '@mui/material';
import { Button, SelectProps, Typography, Icon } from '..';
import { useTranslate } from '../i18n';
import { debounce, compact } from 'lodash';
import { SelectCheckbox, TreeSelectProps } from '.';
import { designTokens } from '../Theme.design-tokens';
import reactStringReplace from 'react-string-replace';
import { VariableSizeList } from 'react-window';
import MenuItem from '../MenuItem';
import { transientOptions } from '../utils';

type TreeLeaf = string | { label: string; value: any; description: string };
type TreeParent = {
  [key: string]: Tree | TreeLeaf[] | string;
};

export type Tree = TreeParent[];

interface LookupEntry {
  keys: string[];
  values: string[];
  root?: boolean;
}
interface LookupTree {
  [key: string]: LookupEntry;
}

type BuildTreeProps = {
  next: Tree | TreeParent | TreeLeaf[];
  parentUniqueKey: string;
  depth?: number;
  parentUniqueKeys?: string[];
  filterString?: string;
  dsOnChange?: SelectProps['dsOnChange'];
  setOpen?: (open: boolean) => void;
  parentDisabled?: boolean;
  parentCheckboxInputColor?: string;
};

const isTreeLeafParent = (leaf: TreeLeaf) => typeof leaf === 'string';
const isTreeLeafChild = (leaf: TreeLeaf) =>
  typeof leaf !== 'string' && leaf.label;

export const treeMenuWidth = 400;
export const menuHeight = 300;
const treeMenuItemSinglePad = 30;
const basePadding = (depth: number) => depth * treeMenuItemSinglePad + 16;

const StyledMenuItem = styled(MenuItem, transientOptions)<{
  depth: number;
  multiple?: boolean;
  $treeParentSelect?: boolean;
  treeMenuMinWidth: number;
}>`
  align-items: center;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ depth = 0, multiple, $treeParentSelect, treeMenuMinWidth }) => `
    width: ${treeMenuMinWidth}px;
    padding-left: ${
      basePadding(depth) +
      (multiple && $treeParentSelect ? treeMenuItemSinglePad : 0)
    }px;
  `};
`;

const StyledListSubheader = styled(StyledMenuItem)<{
  depth: number;
}>`
  ${({ depth = 0 }) => `
    padding-left: ${basePadding(depth)}px;
  `};
`;

const FilterBox = ({
  filterFn,
  registerRef,
  placeholder,
  e2e,
  a11yLabel,
}: {
  filterFn: (filterString: string) => void;
  registerRef: (ref: HTMLInputElement) => void;
  placeholder: SelectProps['placeholder'];
  a11yLabel: SelectProps['a11yLabel'];
  e2e?: string;
}) => {
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearch = React.useRef(
    debounce((value) => {
      filterFn(value);
    }, 150),
  ).current;

  const thisRef = React.useRef<HTMLInputElement>();

  const handleChange = (value) => {
    setSearchValue(value);
    debouncedSearch(value);
  };

  return (
    <MenuItem
      sx={{
        transition: `none !important`,
        backgroundColor: 'inherit !important',
        '&&.Mui-selected': {
          backgroundColor: 'inherit',
        },
      }}
      value=""
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onClick={(e) => e.preventDefault()}
    >
      <TextField
        variant="standard"
        size="small"
        inputRef={(ref) => {
          thisRef.current = ref;
          registerRef(ref);
        }}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        autoComplete="off"
        placeholder={placeholder}
        value={searchValue}
        inputProps={{
          ...(e2e && { 'data-e2e': `${e2e}-filterbox-input` }),
          ...(a11yLabel && { 'aria-label': `${a11yLabel}-filterbox` }),
        }}
        sx={{ width: '100%' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ paddingRight: '1rem' }}>
              <Icon body="search" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ paddingLeft: '1rem' }}>
              <Button
                icon="clear"
                dsOnClick={() => {
                  handleChange('');
                  thisRef?.current?.focus();
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </MenuItem>
  );
};

const renderRow = (props) => {
  const { style, item } = props;
  return item && <item.type {...item.props} style={style} />;
};

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const List = ({
  flatItems,
  treeMenuHeight,
  treeMenuMinWidth,
}: {
  flatItems: any;
  treeMenuHeight: number;
  treeMenuMinWidth: number;
}) => {
  const listRef = useResetCache(flatItems.length);
  return (
    <Box sx={{ minWidth: treeMenuMinWidth }}>
      <VariableSizeList
        itemData={flatItems}
        height={treeMenuHeight}
        itemSize={(index) => {
          const item = flatItems[index];
          if (item) {
            return item?.props?.description ? 60 : 40;
          }
          return 0;
        }}
        itemCount={flatItems.length}
        overscanCount={50}
        ref={listRef}
      >
        {(props) =>
          renderRow({
            ...props,
            item: flatItems[props.index],
          })
        }
      </VariableSizeList>
    </Box>
  );
};

const HighlightedMenu = ({ label, filterString }) => {
  const theme = useTheme();

  return filterString
    ? reactStringReplace(
        label,
        new RegExp(`(${filterString})`, 'gi'),
        (match, i) => (
          <span key={match + i} style={{ color: theme.palette.primary.main }}>
            {match}
          </span>
        ),
      )
    : label;
};

const getUniqueKey = (parentKey, key) =>
  parentKey ? `${parentKey}-${key}` : key;

export const useTreeStructure = ({
  tree,
  value: selectValue,
  multiple,
  e2e,
  a11yLabel,
  filterable,
  placeholder,
  treeParentSelect,
  itemRightContent,
  treeMenuMinWidth,
  treeMenuHeight,
  defaultCollapsed,
}: {
  tree: TreeSelectProps['tree'];
  value: SelectProps['value'];
  multiple: SelectProps['multiple'];
  e2e: SelectProps['e2e'];
  a11yLabel: SelectProps['a11yLabel'];
  filterable: TreeSelectProps['filterable'];
  placeholder: SelectProps['placeholder'];
  treeParentSelect: TreeSelectProps['treeParentSelect'];
  itemRightContent: SelectProps['itemRightContent'];
} & Required<
  Pick<
    TreeSelectProps,
    'treeMenuMinWidth' | 'treeMenuHeight' | 'defaultCollapsed'
  >
>) => {
  const { translate } = useTranslate();
  const filterBoxRef = React.useRef<HTMLInputElement>();
  const ParentItemComponent = treeParentSelect
    ? StyledMenuItem
    : StyledListSubheader;

  const [collapsedMap, setCollapsedMap] = React.useState({});
  const [hiddenMap, setHiddenMap] = React.useState<{ [key: string]: string }>(
    {},
  );

  // Serves: Filter and Selection
  // Key: Parent, Value: Deep Children
  const workingTreeLookupMap = React.useRef<LookupTree>({}).current;

  const disabledItemsLookupMap = React.useRef<{ [key: string]: boolean }>(
    {},
  ).current;

  // Key: Menu Value, Value: Menu Label
  // Serves: Display
  const [treeFlatMap, setTreeFlatMap] = React.useState<{
    [key: string]: string | boolean;
  }>({});
  const workingTreeFlatMap = React.useRef<{ [key: string]: string | boolean }>(
    {},
  ).current;

  const add = (
    key,
    value,
    baseline: LookupEntry = {
      keys: [],
      values: [],
    },
  ) => {
    baseline.keys.push(key);
    baseline.values.push(value);
    return baseline;
  };

  const delve = (
    item,
    parentKey,
    parentsArr: string[] = [],
    parentDisabled = false,
  ) => {
    if (isTreeLeafChild(item)) {
      const stringValue = JSON.stringify(item.value);
      workingTreeFlatMap[stringValue] = item.label;

      workingTreeLookupMap[item.label] = add(
        item.label,
        item.value,
        workingTreeLookupMap[item.label],
      );

      const isDisabled = !!item.value?.disabled || parentDisabled;

      disabledItemsLookupMap[item.label] = isDisabled;

      if (parentsArr) {
        parentsArr.forEach(
          (f) =>
            (workingTreeLookupMap[f] = add(
              item.label,
              item.value,
              workingTreeLookupMap[f],
            )),
        );
      }
    } else if (isTreeLeafParent(item)) {
      workingTreeFlatMap[item] = item;

      disabledItemsLookupMap[item] = parentDisabled;

      workingTreeLookupMap[item] = add(item, item, workingTreeLookupMap[item]);
      if (parentsArr) {
        parentsArr.forEach(
          (f) =>
            (workingTreeLookupMap[f] = add(
              item,
              item,
              workingTreeLookupMap[f],
            )),
        );
      }
    } else if (!Array.isArray(item)) {
      Object.entries(item).forEach(([key, value]) => {
        const itemUniqueKey = getUniqueKey(parentKey, key);

        const isDisabled = parentDisabled || !!item?.disabled;

        // Specify root node
        if (!parentKey) {
          workingTreeLookupMap[key] = { keys: [], values: [], root: true };
          disabledItemsLookupMap[key] = isDisabled;
        } else {
          disabledItemsLookupMap[itemUniqueKey] = isDisabled;
        }

        delve(value, itemUniqueKey, [...parentsArr, itemUniqueKey], isDisabled);
      });
    } else if (Array.isArray(item)) {
      item.forEach((i) => delve(i, parentKey, parentsArr, parentDisabled));
    }
  };

  const collapseEntireTree = ({
    parentUniqueKey = '',
    next = tree,
    parentUniqueKeys = [],
  }: Partial<
    Pick<BuildTreeProps, 'parentUniqueKeys' | 'parentUniqueKey' | 'next'>
  >) => {
    if (Array.isArray(next)) {
      return (next as Tree).reduce((acc, item) => {
        if (!item['label'] && typeof item !== 'string') {
          const itemTreeMap = collapseEntireTree({
            next: item as TreeParent,
            parentUniqueKey,
            parentUniqueKeys,
          });
          return { ...acc, ...itemTreeMap };
        }
        return acc;
      }, {});
    } else {
      return Object.entries(next)
        .filter(
          ([key, child]) =>
            !parentUniqueKeys.some((pk) => collapsedMap[pk]) &&
            Object.keys(child || {}).length > 0 &&
            !['description', 'checkboxColor', 'disabled'].includes(key), // Needed to exclude additional props from the tree object
        )
        .reduce((acc, [key, child]) => {
          const uniqueKey = getUniqueKey(parentUniqueKey, key);
          return {
            ...acc,
            ...(!hiddenMap[uniqueKey] &&
              collapseEntireTree({
                next: child as Tree | TreeLeaf[],
                parentUniqueKey: uniqueKey,
                parentUniqueKeys: [...parentUniqueKeys, uniqueKey],
              })),
            [uniqueKey]: !collapsedMap[uniqueKey],
          };
        }, {});
    }
  };

  React.useEffect(() => {
    if (tree) {
      delve(tree, '');
    }

    if (tree && defaultCollapsed) {
      setCollapsedMap(collapseEntireTree({}));
    }

    setTreeFlatMap(workingTreeFlatMap);
    return () => {
      for (const key in workingTreeLookupMap) {
        delete workingTreeLookupMap[key];
      }
    };
  }, [tree]);

  const filter = (filterString) => {
    const newHiddenMap = Object.keys(workingTreeLookupMap).reduce(
      (total, next) => {
        total[next] = true;
        return total;
      },
      {},
    );
    const regexp = new RegExp(filterString, 'gi');

    Object.entries(workingTreeLookupMap).forEach(([key, value]) => {
      // Default root node to visible
      if (value.root) {
        newHiddenMap[key] = false;
      }

      // If parent matches, update parent and children as visible.
      if (key.match(regexp)) {
        [key, ...value.keys].forEach((k) => (newHiddenMap[k] = false));
      }
      // If any child matched = add parent as visible.
      if (([...value.keys].join('') as String)?.match(regexp)) {
        newHiddenMap[key] = false;
      }
    });

    setCollapsedMap({});
    setHiddenMap(newHiddenMap);
  };

  // Map selected values to display labels
  const renderValueForTree = (selected) => {
    if (selected) {
      const selectedValues = Array.isArray(selected) ? selected : [selected];
      const valueLabel = selectedValues
        ?.filter(Boolean)
        .map((sel) => treeFlatMap[JSON.stringify(sel)] || sel)
        .join(', ');
      return (
        <Typography
          component="span"
          variant="inherit"
          tooltip={valueLabel}
          tooltipOnlyWhenTruncated
        >
          {valueLabel}
        </Typography>
      );
    }
    return undefined;
  };

  const getMenuItemValue = (itemValue) => {
    if (multiple && Array.isArray(selectValue)) {
      if (Array.isArray(selectValue)) {
        const index = selectValue.indexOf(itemValue);
        if (index > -1) {
          return selectValue.filter((v) => v !== itemValue);
        } else {
          return [...selectValue, itemValue];
        }
      } else {
        return [itemValue];
      }
    } else {
      return itemValue;
    }
  };

  const isMenuItemSelected = (
    itemValue: string,
    itemLabel: string,
    parent?: boolean,
  ) => {
    if (treeParentSelect && parent) {
      return itemValue === itemLabel && itemValue === selectValue;
    } else if (multiple) {
      return Array.from(selectValue as string[]).indexOf(itemValue) > -1;
    } else {
      return itemValue === selectValue;
    }
  };

  const buildTree = ({
    next,
    depth = 0,
    parentUniqueKey,
    parentUniqueKeys = [],
    filterString,
    parentDisabled = false,
    parentCheckboxInputColor,
    dsOnChange,
    setOpen,
  }: BuildTreeProps) => {
    if (Array.isArray(next)) {
      const parents = (next as Tree).reduce((acc, item) => {
        if (!item['label'] && typeof item !== 'string') {
          const itemTree = buildTree({
            next: item as TreeParent,
            depth,
            parentUniqueKey,
            parentUniqueKeys,
            filterString,
            dsOnChange,
            parentDisabled,
            parentCheckboxInputColor,
            setOpen,
          });
          return itemTree.length ? [...acc, itemTree] : acc;
        }
        return acc;
      }, [] as TreeParent[]);

      const children = (next as TreeLeaf[])
        .filter((item) => {
          const label = typeof item === 'string' ? item : item['label'];
          return (
            label &&
            !hiddenMap[label] &&
            !parentUniqueKeys.some((pk) => collapsedMap[pk])
          );
        })
        .map((item) => {
          const itemValue = typeof item === 'string' ? item : item['value'];
          const label = typeof item === 'string' ? item : item['label'];
          const description =
            typeof item !== 'string' ? item?.value?.['description'] : undefined;
          const itemDisabled =
            typeof item !== 'string' ? item?.value?.['disabled'] : undefined;

          // disabling the child item when parent is disabled
          const isDisabled = !!itemDisabled || parentDisabled;

          const isSelected = isMenuItemSelected(itemValue, label);
          return (
            <StyledMenuItem
              {...(e2e && {
                'data-e2e': `${e2e}-${getUniqueKey(parentUniqueKey, label)}`,
              })}
              value={itemValue}
              depth={depth}
              key={itemValue}
              multiple={multiple}
              disabled={isDisabled}
              treeMenuMinWidth={treeMenuMinWidth}
              $treeParentSelect={treeParentSelect}
              onClick={(e) => {
                const newValue = getMenuItemValue(itemValue);
                dsOnChange?.(
                  {
                    ...e,
                    target: { ...e.target, value: newValue, name: label },
                  } as any,
                  {
                    value: newValue,
                  },
                );

                if (!multiple) {
                  setOpen?.(false);
                }
              }}
              {...(isSelected && { className: 'Mui-selected' })}
              itemRightContent={itemRightContent}
              description={description as string}
              {...(multiple && {
                stylesForDescription: { marginLeft: '36px' },
              })}
            >
              {multiple && (
                <SelectCheckbox
                  checked={isSelected}
                  // leaf inherits the color from it's parent
                  color={parentCheckboxInputColor}
                />
              )}
              <Typography
                component="span"
                variant="body2"
                tooltip={label}
                tooltipOnlyWhenTruncated
                noWrap
              >
                <HighlightedMenu label={label} filterString={filterString} />
              </Typography>
            </StyledMenuItem>
          );
        });

      return [...parents, ...children];
    } else {
      // Needed to get the description and checkbox color of Parent value
      const description = Object.entries(next)
        .filter(([key]) => key === 'description')
        .map(([, value]) => value)?.[0];

      const selectCheckboxColorInPayload = Object.entries(next)
        .filter(([key]) => key === 'checkboxColor')
        .map(([, value]) => value)?.[0];

      const selectCheckboxColorValue =
        typeof selectCheckboxColorInPayload === 'string'
          ? selectCheckboxColorInPayload
          : undefined;

      const itemDisabled = Object.entries(next)
        .filter(([key]) => key === 'disabled')
        .map(([, value]) => value)?.[0];

      return Object.entries(next)
        .filter(
          ([key, child]) =>
            !parentUniqueKeys.some((pk) => collapsedMap[pk]) &&
            Object.keys(child || {}).length > 0 &&
            !['description', 'checkboxColor', 'disabled'].includes(key), // Needed to exclude additional props from the tree object
        )
        .map(([key, child]) => {
          const isSelected = isMenuItemSelected(key, key, true);

          // disabling the descendant hierarchy when parent is disabled
          const isDisabled = parentDisabled || !!itemDisabled;
          const uniqueKey = getUniqueKey(parentUniqueKey, key);

          const filteredLookupValues = compact(
            workingTreeLookupMap[uniqueKey]?.keys?.map((itemKey, ind) => {
              if (disabledItemsLookupMap[itemKey] === true) {
                return null;
              }
              return workingTreeLookupMap[uniqueKey].values?.[ind];
            }) ?? [],
          );

          const parentChecked =
            multiple && filteredLookupValues.length
              ? filteredLookupValues.every((childValue) =>
                  (selectValue as string[]).includes(childValue),
                )
              : false;
          const parentIndeterminate =
            multiple && !parentChecked && filteredLookupValues.length > 0
              ? filteredLookupValues.some((childValue) =>
                  (selectValue as string[]).includes(childValue),
                )
              : false;

          const toggleCollapse = () =>
            setCollapsedMap({
              ...collapsedMap,
              [uniqueKey]: !collapsedMap[uniqueKey],
            });

          const getParentValue = (): string[] => {
            if (parentIndeterminate || !parentChecked) {
              return Array.from(
                new Set([
                  ...(selectValue as string[]),
                  ...filteredLookupValues,
                ]),
              );
            } else {
              return (selectValue as string[]).filter(
                (item) => !filteredLookupValues.includes(item),
              );
            }
          };

          return (
            !hiddenMap[uniqueKey] && [
              <ParentItemComponent
                {...(e2e && { 'data-e2e': `${e2e}-${uniqueKey}` })}
                itemRightContent={itemRightContent}
                description={description as string}
                stylesForDescription={{
                  marginLeft: '30px',
                }}
                value={key}
                depth={depth}
                treeMenuMinWidth={treeMenuMinWidth}
                key={key}
                {...(isSelected && { className: 'Mui-selected' })}
                onClick={(e) => {
                  if (treeParentSelect && !isDisabled) {
                    const newValue = multiple
                      ? getParentValue()
                      : getMenuItemValue(key);

                    dsOnChange?.(
                      {
                        ...e,
                        target: { ...e.target, value: newValue, name: key },
                      } as any,
                      {
                        label: key,
                        value: newValue,
                      },
                    );

                    if (!multiple) {
                      setOpen?.(false);
                    }
                  } else {
                    toggleCollapse();
                  }
                }}
              >
                {/* Not disabling entire menu item to ensure expandable/collapsable icons can still be triggered even when 'disabled' is true */}
                {collapsedMap[uniqueKey] ? (
                  <Button
                    size="small"
                    icon="arrow_right"
                    disableRipple={!treeParentSelect}
                    dsOnClick={(e) => {
                      e.stopPropagation();
                      toggleCollapse();
                    }}
                  />
                ) : (
                  <Button
                    size="small"
                    icon="arrow_drop_down"
                    disableRipple={!treeParentSelect}
                    dsOnClick={(e) => {
                      e.stopPropagation();
                      toggleCollapse();
                    }}
                  />
                )}
                {multiple && treeParentSelect && (
                  <SelectCheckbox
                    indeterminate={parentIndeterminate}
                    checked={parentChecked}
                    disabled={isDisabled}
                    // parent checkbox color should override it's descendant checkbox colors too
                    color={parentCheckboxInputColor ?? selectCheckboxColorValue}
                  />
                )}
                <Typography
                  component="span"
                  variant="body2"
                  tooltip={key}
                  sx={{ ...(isDisabled && { opacity: 0.5 }) }}
                  tooltipOnlyWhenTruncated
                >
                  <HighlightedMenu label={key} filterString={filterString} />
                </Typography>
              </ParentItemComponent>,
              buildTree({
                next: child as Tree | TreeLeaf[],
                depth: depth + 1,
                parentUniqueKey: uniqueKey,
                parentUniqueKeys: [...parentUniqueKeys, uniqueKey],
                filterString,
                parentDisabled: isDisabled,
                parentCheckboxInputColor:
                  parentCheckboxInputColor ?? selectCheckboxColorValue,
                dsOnChange,
                setOpen,
              }),
            ]
          );
        });
    }
  };

  const renderHiddenTree = (next: Tree | TreeParent | TreeLeaf[]) => {
    if (Array.isArray(next)) {
      const parents = (next as Tree)
        .filter((item) => !item['label'] && typeof item !== 'string')
        .map((item) => renderHiddenTree(item as TreeParent));

      const children = (next as TreeLeaf[])
        .filter((item) => item['label'] || typeof item === 'string')
        .map((item) => {
          const value =
            typeof item === 'string' ? item : JSON.stringify(item['value']);
          const label = typeof item === 'string' ? item : ['label'];
          return (
            <option value={value} key={value}>
              {label}
            </option>
          );
        });
      return [...parents, ...children];
    } else {
      return Object.values(next)
        .filter((item) => typeof item !== 'string') // Needed to exclude the description from the tree object
        .map((value) => renderHiddenTree(value as Tree | TreeLeaf[]));
    }
  };

  const getTreeChildren = ({
    dsOnChange,
    setOpen,
  }: {
    dsOnChange?: SelectProps['dsOnChange'];
    setOpen?: (open: boolean) => void;
  }) => {
    const items = buildTree({
      next: tree as Tree,
      parentUniqueKey: '',
      filterString: filterBoxRef.current?.value,
      dsOnChange,
      setOpen,
    });
    const flatItems = items.flat(Infinity);
    const listBox = [
      filterable && (
        <FilterBox
          e2e={e2e}
          a11yLabel={a11yLabel}
          filterFn={filter}
          key="FILTER"
          registerRef={(el) => (filterBoxRef.current = el)}
          placeholder={placeholder}
        />
      ),
    ];

    if (!items.filter(Boolean).length) {
      listBox.push(
        <MenuItem
          key="NO-RESULTS"
          sx={{
            '&&.Mui-selected': {
              background: 'inherit',
            },
          }}
          value=""
          disabled
        >
          <Typography
            variant="body2"
            color={designTokens.colors.lightEmphasisLow}
            body={translate('NoResults')}
          />
        </MenuItem>,
      );
    } else {
      listBox.push(
        <List
          key="LIST"
          {...{
            flatItems,
            treeMenuHeight,
            treeMenuMinWidth,
          }}
        />,
      );
    }
    return listBox;
  };

  return {
    setHiddenMap,
    setCollapsedMap,
    filter,
    renderHiddenTree,
    filterBoxRef,
    getTreeChildren,
    renderValueForTree,
  };
};
