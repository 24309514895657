import * as React from 'react';

import {
  FormControlLabel,
  Radio as MuiRadio,
  RadioProps as MUIRadioProps,
} from '@mui/material';

export interface RadioProps
  extends Pick<
    MUIRadioProps,
    'required' | 'disabled' | 'checked' | 'name' | 'value'
  > {
  dsOnChange?: MUIRadioProps['onChange'];
  e2e?: string;
  label: React.ReactNode;
}

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      checked,
      disabled,
      dsOnChange,
      e2e,
      label,
      name,
      required,
      value,
    }: RadioProps,
    ref,
  ) => {
    return (
      <FormControlLabel
        label={label}
        disabled={disabled}
        {...(e2e && { 'data-e2e': e2e })}
        control={
          // @ts-ignore
          <MuiRadio
            sx={{ padding: '8px' }}
            inputRef={ref}
            required={required}
            disabled={disabled}
            checked={checked}
            name={name}
            onChange={dsOnChange}
            value={value}
            size="small"
            color="primary"
            {...(e2e && {
              inputProps: {
                'data-e2e': `${e2e}-input`,
              },
            })}
          />
        }
      />
    );
  },
);

export default Radio;
