import React, { useRef } from 'react';
import { TextField } from '../../..';
import { FilterComponentProps, FilterWrapper } from './FilterComponentFactory';
import { OperatorComponent } from './OperatorComponent';
import { ClearComponent } from './ClearComponent';

export const NumericalFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, dsComponentProps, dsOnBlur } = props;

  const e2e = dsComponentProps?.e2e ?? null;

  const inputRef = useRef<HTMLInputElement>();

  const onOperatorBlur = () => {
    inputRef.current?.focus();
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    closeFilter?: boolean,
  ) => {
    stateChangeFn(
      {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          value: e.target.value,
        },
      },
      closeFilter,
    );
  };

  return (
    <>
      <FilterWrapper>
        <OperatorComponent {...props} dsOnBlur={onOperatorBlur} />
        <TextField
          {...(e2e && { e2e: `${e2e}-text-field` })}
          inputRef={inputRef}
          {...dsComponentProps}
          value={state[stateKey].value || ''}
          dsOnBlur={dsOnBlur}
          dsOnKeyPress={({ key }) => {
            if (key === 'Enter') {
              dsOnBlur?.();
            }
          }}
          type="number"
          dsOnChange={(e) => onChange(e, false)}
        />
      </FilterWrapper>
      {state[stateKey].value !== '' && (
        <ClearComponent
          onClear={() =>
            onChange(
              { target: { value: '' } } as React.ChangeEvent<HTMLInputElement>,
              true,
            )
          }
        />
      )}
    </>
  );
};
