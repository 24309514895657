import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import {
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import {
  LocalizationProvider,
  LocalizationProviderProps,
} from '@mui/x-date-pickers/LocalizationProvider';
import makeClass from 'classnames';
import { DateTime } from 'luxon';
import * as React from 'react';
import { LocaleData, useLocaleConfig } from '../../utils';
import { dateGuard } from '../utils';

export interface DatePickerProps
  extends Pick<
    MUIDatePickerProps<
      string | DateTime | Date | undefined,
      string | DateTime | Date
    >,
    'disabled' | 'label' | 'value' | 'minDate' | 'maxDate'
  > {
  dsOnChange: MUIDatePickerProps<
    string | DateTime | Date | undefined,
    string | DateTime | Date
  >['onChange'];
  dsOnClose?: MUIDatePickerProps<
    string | DateTime | Date | undefined,
    string | DateTime | Date
  >['onClose'];
  /** Locale defaults to the locale of the Theme. It is unlikely you will need to set this property.  */
  locale?: LocalizationProviderProps['adapterLocale'];
  /** Uses AdapterLuxon by default. It is unlikely you will need to set this property. */
  dateAdapter?: LocalizationProviderProps['dateAdapter'];
  /** @deprecated Language is set by locale. This will not be used. **/
  language?: string;
  e2e?: string;
  dsOnBlur?: TextFieldProps['onBlur'];
  format?: MUIDatePickerProps<
    string | DateTime | Date | undefined,
    string | DateTime | Date
  >['inputFormat'];
  error?: TextFieldProps['error'];
  fullWidth?: TextFieldProps['fullWidth'];
  helperText?: TextFieldProps['helperText'];
  required?: TextFieldProps['required'];
  readonly?: boolean;
  placeholder?: TextFieldProps['placeholder'];
  views?: MUIDatePickerProps<
    string | DateTime | Date | undefined,
    string | DateTime | Date
  >['views'];
  setFormat?: (value: string) => void;
}

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      disabled,
      dsOnChange,
      dsOnClose,
      dsOnBlur,
      locale,
      dateAdapter,
      e2e,
      error,
      format,
      fullWidth,
      helperText,
      label,
      minDate,
      maxDate,
      value,
      required,
      readonly,
      placeholder,
      views,
      setFormat,
    },
    ref,
  ) => {
    const theme = useTheme();
    dateAdapter = dateAdapter || AdapterLuxon;
    locale = locale || theme.locale;
    const localeConfig = useLocaleConfig();
    const localeData: LocaleData =
      localeConfig[locale?.toString()?.replace('_', '-') || 'en-US'];
    if (!format) {
      format = localeData?.format;
    }

    React.useEffect(() => {
      format && setFormat?.(format.toLowerCase());
    }, [format, setFormat]);

    return (
      <LocalizationProvider adapterLocale={locale} dateAdapter={dateAdapter}>
        <MUIDatePicker
          inputRef={ref}
          mask={localeData.mask}
          disabled={disabled}
          inputFormat={format}
          label={label}
          minDate={dateGuard(minDate)}
          maxDate={dateGuard(maxDate)}
          onChange={dsOnChange}
          onClose={dsOnClose}
          readOnly={readonly}
          value={value}
          views={views}
          {...(e2e && {
            'data-e2e': e2e,
            inputProps: {
              'data-e2e': `${e2e}-input`,
            },
            InputLabelProps: {
              'data-e2e': `${e2e}-label`,
            },
          })}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className={makeClass({ ReadOnly: readonly })}
                fullWidth={fullWidth}
                onBlur={dsOnBlur}
                error={error}
                required={required}
                helperText={helperText}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholder ?? format?.toLowerCase(),
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    );
  },
);

export default DatePicker;
