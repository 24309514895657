import React from 'react';
import { FilterComponentProps, FilterWrapper } from './FilterComponentFactory';
import { Operator } from '../../types';
import { Radio, RadioGroup } from '../../..';
import { useTranslate } from '../../../i18n';
import { ClearComponent } from './ClearComponent';

enum RadioChoices {
  ALL = 'all',
  TRUE = 'true',
  FALSE = 'false',
}

export const BooleanFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, dsComponentProps } = props;

  const e2e = dsComponentProps?.e2e ?? null;

  const [radioChoice, setRadioChoice] = React.useState<string>(
    RadioChoices.ALL,
  );

  const { translate } = useTranslate();

  React.useEffect(() => {
    if (typeof state[stateKey].value === 'undefined') {
      setRadioChoice(RadioChoices.ALL);
    } else {
      setRadioChoice(state[stateKey].value);
    }
  }, [state]);

  const dsOnChange = (e) => {
    stateChangeFn(
      {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          value:
            e.target.value === RadioChoices.ALL ? undefined : e.target.value,
          operator: Operator.Eq,
        },
      },
      true,
    );
  };

  return (
    <>
      <FilterWrapper>
        <RadioGroup
          {...(e2e && { e2e: `${e2e}-radio-group` })}
          value={radioChoice}
          dsOnChange={dsOnChange}
          label=""
        >
          <Radio value={RadioChoices.ALL} label={translate('FilterBar_All')} />
          <Radio value={RadioChoices.TRUE} label={translate('FilterBar_Yes')} />
          <Radio value={RadioChoices.FALSE} label={translate('FilterBar_No')} />
        </RadioGroup>
      </FilterWrapper>
      {radioChoice !== RadioChoices.ALL && (
        <ClearComponent
          onClear={() => dsOnChange({ target: { value: RadioChoices.ALL } })}
        />
      )}
    </>
  );
};
