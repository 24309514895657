import React from 'react';
import { TextField } from '../../..';
import { FilterComponentProps, FilterRow } from './FilterComponentFactory';
import { OperatorComponent } from './OperatorComponent';

export const TextFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, dsComponentProps } = props;

  return (
    <FilterRow>
      <TextField
        {...dsComponentProps}
        label={state[stateKey].label || stateKey}
        value={state[stateKey].value}
        dsOnChange={(e) => {
          stateChangeFn({
            ...state,
            [stateKey]: {
              ...state[stateKey],
              value: e.target.value,
            },
          });
        }}
      />
      <OperatorComponent {...props} />
    </FilterRow>
  );
};
