import * as React from 'react';
import styled from '@emotion/styled';
import { transientOptions } from '../../utils';
import { Popover } from '@mui/material';
import Button from '../../Button';
import { FilterableField } from '../index';
import { useThemeTokens } from '../../Theme';
import { FilterComponentFactory } from './components/FilterComponentFactory';
import { FilterField, FilterFields } from '../types';
import { LegacyFilterFactory } from './utils';

const StyledFilterContainer = styled('span', transientOptions)<{
  $hasActions: boolean;
  $color: string;
}>`
  margin-left: ${({ $hasActions }) => ($hasActions ? '8px' : 0)};
  border-left: ${({ $hasActions, $color }) =>
    $hasActions ? `1px solid ${$color}` : 'none'};
  padding-left: ${({ $hasActions }) => ($hasActions ? '8px' : 0)};
  padding-right: 8px;
`;

const StyledCTABar = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const StyledPopoverContent = styled('div')`
  margin: 1rem;
  width: 600px;
`;

interface FilterPopoverProps {
  e2e?: string;
  filterableFields: FilterableField[];
  hasActions: boolean;
  fields: FilterFields;
  setFields: (fields) => void;
  setFilter: (filter) => void;
  removeFilter: () => void;
}

export const FilterPopover = ({
  e2e,
  filterableFields,
  hasActions,
  fields,
  setFields,
  setFilter,
  removeFilter,
}: FilterPopoverProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const themeTokens = useThemeTokens();

  /* Clears a field value if the new operator is not valid for the input type. */
  const setFieldsGuard = (incomingState) => {
    const newState = {};
    Object.entries(incomingState).forEach(([key, field]) => {
      const filterField = field as FilterField;

      newState[key] = {
        ...filterField,
        value: LegacyFilterFactory.operatorMatchesDataType(filterField)
          ? filterField.value
          : '',
      };
    });

    setFields(newState);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    setFilter(LegacyFilterFactory.filterFromFields(fields));
    handleClose();
  };

  const handleClear = () => {
    removeFilter();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <StyledFilterContainer
      $color={themeTokens.contextHigh}
      $hasActions={hasActions}
    >
      <Button
        {...(e2e && { e2e: `${e2e}-table-filter` })}
        aria-describedby={id}
        variant="contained"
        icon={
          open || Object.values(fields).some((field) => !!field.value)
            ? 'filter_filled'
            : 'filter'
        }
        tooltip="Filter"
        dsOnClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledPopoverContent>
          {Object.keys(fields)?.map((key, index) => {
            return (
              <FilterComponentFactory
                {...(e2e && { e2e: `${e2e}-table-filter` })}
                type={fields[key].type}
                key={key}
                stateKey={key}
                state={fields}
                filterableField={filterableFields?.[index]}
                stateChangeFn={setFieldsGuard}
              />
            );
          })}

          <StyledCTABar>
            <Button
              {...(e2e && { e2e: `${e2e}-table-filter-clear` })}
              variant="outlined"
              dsOnClick={handleClear}
            >
              Clear
            </Button>
            <Button
              {...(e2e && { e2e: `${e2e}-table-filter-apply` })}
              variant="contained"
              dsOnClick={handleSubmit}
            >
              Apply
            </Button>
          </StyledCTABar>
        </StyledPopoverContent>
      </Popover>
    </StyledFilterContainer>
  );
};
