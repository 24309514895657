import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';
import { AsyncRenderObjType } from './index';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (!dataSet?.key) {
    return (
      <ListSubheader key={dataSet?.key} component="div" style={inlineStyle}>
        {dataSet}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" noWrap style={inlineStyle}>
      {dataSet}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const ListboxComponent = (asyncRender: AsyncRenderObjType | boolean) => {
  return React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
      const { children, ...other } = props;
      const itemData: React.ReactChild[] = [];
      (children as React.ReactChild[]).forEach(
        (item: any & { children?: React.ReactChild[] }) => {
          const groupedItem = item?.props?.children;
          if (
            Array.isArray(groupedItem[1]?.props?.children) &&
            groupedItem[1]?.props?.children.length > 0
          ) {
            itemData.push(groupedItem[0]);
            itemData.push(...(groupedItem[1]?.props?.children || []));
          } else {
            itemData.push(item);
          }
        },
      );

      const itemCount = itemData.length;
      const itemSize =
        (typeof asyncRender === 'object' && asyncRender?.height) || 56;

      const getChildSize = (child: any) => {
        if (!child?.key) {
          return (
            (typeof asyncRender === 'object' && asyncRender?.groupHeight) || 48
          );
        }
        return itemSize;
      };

      const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
      };

      const gridRef = useResetCache(itemCount);

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              itemData={itemData}
              height={getHeight() + 2 * LISTBOX_PADDING}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType="div"
              itemSize={(index) => getChildSize(itemData[index])}
              overscanCount={10}
              itemCount={itemCount}
            >
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    },
  );
};
