import { DateTime } from 'luxon';
import { Filter, FilterableField, FilterCondition } from '..';
import { FilterDataTypes, FilterField, Operator } from '../types';

export class LegacyFilterFactory {
  static operatorLabelMap = {
    [Operator.Eq]: 'Exact Match',
    [Operator.Ne]: 'Does Not Match',
    [Operator.Lt]: 'Less Than',
    [Operator.Le]: 'Less Than Or Equal',
    [Operator.Gt]: 'Greater Than',
    [Operator.Ge]: 'Greater Than Or Equal',
    [Operator.Sw]: 'Starts With',
  };

  static operatorCharacterMap = {
    [Operator.Eq]: '=',
    [Operator.Ne]: '<>',
    [Operator.Lt]: '<',
    [Operator.Le]: '<=',
    [Operator.Gt]: '>',
    [Operator.Ge]: '>=',
    [Operator.Sw]: '~',
  };

  static componentOperatorMap = {
    [FilterDataTypes.STRING]: [Operator.Eq, Operator.Ne, Operator.Sw],
    [FilterDataTypes.DATE]: [
      Operator.Eq,
      Operator.Ge,
      Operator.Le,
      Operator.Ne,
    ],
    [FilterDataTypes.TIMESTAMP]: [
      Operator.Eq,
      Operator.Ge,
      Operator.Le,
      Operator.Ne,
    ],
    [FilterDataTypes.DECIMAL]: [
      Operator.Eq,
      Operator.Ge,
      Operator.Gt,
      Operator.Lt,
      Operator.Ne,
    ],
    [FilterDataTypes.BIGINT]: [
      Operator.Eq,
      Operator.Ge,
      Operator.Gt,
      Operator.Lt,
    ],
    [FilterDataTypes.PICKLIST]: [Operator.Eq, Operator.Ne],
  };

  /* Handling for TIMESTAMP */
  static validTimestampDatePickerOperators = (operator) =>
    [Operator.Ge, Operator.Le].includes(operator);
  static isTimestamp = (type) => FilterDataTypes.TIMESTAMP === type;
  static operatorMatchesDataType = (field: FilterField) => {
    if (LegacyFilterFactory.isTimestamp(field.type)) {
      return (
        (DateTime.isDateTime(field.value) &&
          [Operator.Ge, Operator.Le].includes(field.operator)) ||
        (!DateTime.isDateTime(field.value) &&
          [Operator.Eq, Operator.Ne].includes(field.operator))
      );
    }
    return true;
  };

  static formatInitialValue = (type, operator, value) => {
    if (
      LegacyFilterFactory.isTimestamp(type) &&
      LegacyFilterFactory.validTimestampDatePickerOperators(operator)
    ) {
      return DateTime.fromISO(value);
    }

    return value || '';
  };

  static formatValueForHawk = (value, type: FilterDataTypes) => {
    if (DateTime.isDateTime(value)) {
      return type === FilterDataTypes.DATE
        ? value.toFormat('yyyy-MM-dd')
        : value.startOf('day').toJSON();
    } else {
      return value.trim();
    }
  };

  static filterFromFields = (fields) => {
    const filter = {
      // TODO: Someday, with updated UX, this could be an OR
      relation: 'AND',
      conditions: Object.keys(fields)
        ?.map((key) => {
          if (fields[key].value || fields[key]?.length) {
            return {
              operator: fields[key].operator,
              value: LegacyFilterFactory.formatValueForHawk(
                fields[key].value,
                fields[key].type,
              ),
              field: key,
            };
          }
        })
        .filter(Boolean),
    };

    return filter.conditions?.length ? filter : {};
  };

  static filterToFields = (
    filterableFields: FilterableField[],
    initialFilter?: Filter,
  ) => {
    return filterableFields.reduce((acc, curr) => {
      const initialFilterMatch = initialFilter?.conditions?.find(
        (condition) => (condition as FilterCondition).field === curr.name,
      );
      const operator =
        (initialFilterMatch as FilterCondition)?.operator ||
        LegacyFilterFactory.componentOperatorMap[curr.type]?.[0];
      acc[curr.name] = {
        operator,
        value: LegacyFilterFactory.formatInitialValue(
          curr.type,
          operator,
          (initialFilterMatch as FilterCondition)?.value,
        ),
        label: curr.label,
        type: curr.type,
      };
      return acc;
    }, {});
  };

  static getPicklistLabel = (
    condition: FilterCondition,
    filterableFields?: FilterableField[],
  ) => {
    const picklistOptions = filterableFields?.find(
      (field) => field.name == condition.field,
    )?.picklistOptions;

    const chosenOption = picklistOptions?.find(
      // @ts-ignore
      (opt) => opt === condition.value || opt.value === condition.value,
    );

    // @ts-ignore
    return chosenOption?.label || chosenOption || condition.value;
  };
}
