import * as React from 'react';
import styled from '@emotion/styled';
import { isSafari, transientOptions } from '../utils';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  useTheme,
  capitalize,
  Collapse,
  Box,
  CardHeader,
  IconButton,
  Tooltip,
  ButtonGroup,
  TableCellProps,
} from '@mui/material';
import { useOverflow } from 'use-overflow';
// @ts-ignore
import { getBackgroundColor } from 'background-color-recursive';
import Icon from '../Icon';
import Spinner from '../Spinner';
import Shimmer from '../Shimmer';
import {
  ActionListItemChild,
  ActionListItem,
  ActionsList,
} from '../ActionsList';
import { isEqual } from 'lodash';
import { SearchBar } from './SearchBar';
import { ColumnControlPopoverList } from './ColumnControlPopoverList';
import { GroupRow } from './GroupRow';
import { Row } from './Row';
import { SummaryRow } from './SummaryRow';
import {
  useEditableRow,
  useEditableRows,
  useCustomColumns,
  useColumnWidths,
  usePagination,
  useSearch,
  useSort,
  useFilter,
  useGroupedData,
  useMultiSelect,
  useFilterBarOptions,
  useColumnHighlighter,
  useRowDisplayOptions,
} from './hooks';
import {
  getComparator,
  stableSort,
  stableSearch,
  getValueFields,
} from './utils';
import { useThemeTokens } from '../Theme';
import Typography from '../Typography';
import { FilterPopover } from './LegacyFilter';
import {
  FilterDataTypes,
  InternalOperator,
  Operator,
  TabFilterField,
  VanityFilterField,
  QuickFilterField,
} from './types';
import Chip from '../Chip';
import { designTokens, FilterBarFactory, Link } from '..';
import { useTranslate } from '../i18n';
import { FilterBar } from './FilterBar/FilterBar';
import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
import Button from '../Button';
import { TabFilters } from './TabFilters';
import TitleBar from '../TitleBar';

// TODO: https://philipwalton.github.io/polyfill/demos/position-sticky/
const StyledWrapper = styled('div')`
  width: 100%;
  position: relative;
`;

// Used for Table Sticky Cells, Filter Clear
const getBaseOverflowStyles = (
  $hasOverflow?: boolean,
  $overflowColor?: string,
  $isRowAction?: boolean,
) => `
  background-color: ${$overflowColor};
    &::before {
      content: ' ';
      display: ${$hasOverflow ? 'block' : 'none'};
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: ${$isRowAction ? '100%' : '80px'};
      margin-left: ${$isRowAction ? '' : '-80px'};
      box-shadow: ${
        $hasOverflow && $isRowAction
          ? `-4px 0px 8px ${designTokens.colors.lightContextMedium}`
          : ''
      };
      background:  ${
        $isRowAction
          ? $overflowColor
          : `linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        ${$overflowColor} 100%
      )`
      };
     
      pointer-events: none;
  }
`;

// Used for Table Header Row
const getBaseOverflowStylesSX = (
  hasOverflow?: boolean,
  overflowColor?: string,
  isRowAction?: boolean,
) => {
  return {
    backgroundColor: overflowColor,
    '&::before': {
      content: '""',
      display: hasOverflow ? 'block' : 'none',
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
      width: isRowAction ? '100%' : '80px',
      marginLeft: isRowAction ? '' : '-80px',
      boxShadow:
        hasOverflow && isRowAction
          ? `-4px 0px 8px ${designTokens.colors.lightContextMedium}`
          : '',
      background: isRowAction
        ? overflowColor
        : `linear-gradient(to right, rgba(255, 255, 255, 0) 0%, ${overflowColor} 100%)`,
      pointerEvents: 'none',
    },
  } as const;
};

const StyledFilterClearContainer = styled('div', transientOptions)<{
  $hasOverflow?: boolean;
  $overflowColor?: string;
}>`
  position: sticky;
  padding-left: 1rem;
  ${({ $hasOverflow, $overflowColor }) =>
    getBaseOverflowStyles($hasOverflow, $overflowColor)}
`;

export const StyledStickyCell = styled(TableCell, transientOptions)<{
  $hasOverflow?: boolean;
  $overflowColor?: string;
  $isRowActions?: boolean;
}>`
  position: sticky;
  z-index: 1;
  right: 0;
  padding: 0 !important;

  ${({ $hasOverflow, $overflowColor, $isRowActions }) =>
    getBaseOverflowStyles($hasOverflow, $overflowColor, $isRowActions)}
`;

const StyledHeaderControlWrapper = styled('div', transientOptions)<{
  $divider: string;
  $horAlign?: string;
}>`
  display: flex;
  align-items: stretch;
  justify-content: ${({ $horAlign }) => $horAlign || 'flex-end'};

  &:not(:empty) {
    border-bottom: 1px solid ${({ $divider }) => $divider};
  }
`;

const TableOpacity = styled('div', transientOptions)<{
  $loading: TableProps['loading'];
}>`
  opacity: ${({ $loading }) => ($loading ? 0.24 : 1)};
`;

const CenteredSpinner = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelInfoTypography = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;

    && span {
      display: flex;
    }
  }
`;

const LabelInfoIcon = styled(Icon)`
  && {
    font-size: 1rem;
  }
`;

export type OrderType = 'asc' | 'desc';

export type ValueType = string | boolean | number | undefined | null | any[];

export type DataType = {
  [key: string]: ValueType;
};

export interface ColumnType {
  field: string;
  label?: string;
  labelTooltip?: string;
  hidden?: boolean;
  type?:
    | 'string'
    | 'boolean'
    | 'number'
    | 'integer'
    | 'date'
    | 'date-time'
    | 'picklist'
    | 'relationship'
    | 'password';
  picklistOptions?: string[];
  dateFormat?: string;
  minDate?: string;
  maxDate?: string;
  radio?: string;
  dsRenderCell?: React.FC<{
    rowId: string;
    row: DataType;
    column: ColumnType;
    value: ValueType;
  }>;
  /* Column width, in pixels. */
  width?: number;
  minWidth?: number;
  /** default is true */
  sortable?: boolean;
  alignment?: 'left' | 'right';
  stickyPosition?: 'left' | 'right';
  verticalAlign?: 'top' | 'bottom' | 'middle' | 'inherit';
  sx?: TableCellProps['sx'];
  disableOrdering?: boolean;
  multiline?: boolean;
  maxRows?: number;
  loading?: boolean;
}

export interface ColumnWidth {
  field: string;
  width: number;
}

export type TableActionChildType = ActionListItemChild;
export type TableActionType = ActionListItem;
export type BuckActionType = ActionListItem;

export type RowActionType = ActionListItem | GetActionData;

/**
 * Dynimically determine the action based on the row.
 */
export interface GetActionData {
  dsGetActionData: (
    row: DataType,
  ) => ActionListItem | React.ReactNode | undefined;
}

/**
 * Check to see if a RowActionType is a GetActionData.
 */
export const isGetActionData = (
  input: RowActionType,
): input is GetActionData => {
  return (input as GetActionData).dsGetActionData !== undefined;
};

/**
 * Define a custom edit action for a row.
 */
export interface CustomRowEditAction {
  /**
   * Determine if the default save action should be used.
   */
  dsUseDefaultSave: (args: { row: DataType }) => boolean;
  /**
   * Determine if the default edit action should be used.
   */
  dsUseDefaultEdit: (args: { row: DataType }) => boolean;
  /**
   * Determine if the edit / save icon should be visible for a row.
   */
  dsIsActionVisible: (args: { row: DataType }) => boolean;
}

export interface PicklistOptionDateType {
  operator: Operator | string;
  days: string;
  fromToday: 'subtract' | 'add';
}

export interface PicklistOptionType {
  value: PicklistOptionDateType | string;
  label: string;
}

export interface FilterableField {
  name: string;
  label?: string;
  type: FilterDataTypes | string;
  indexed?: boolean;
  picklistOptions?: (
    | (PicklistOptionType & {
        /** Currently description only works for PICKLIST type in non-legacy filterable fields */
        description?: string;
      })
    | string
  )[];
  relationshipQueryCallback?: (
    searchString: string,
    selectedValues: string[],
  ) => Promise<{ value: string; label: string }[] | undefined>;
  customSelector?: boolean;
  hideClear?: boolean;
}

export enum FilterRelation {
  AND = 'AND',
  OR = 'OR',
}

export interface FilterCondition {
  fieldId?: string;
  operator: string;
  value: string;
  field: string;
}

export interface Filter {
  fieldId?: string;
  relation: FilterRelation | string;
  conditions: (Filter | FilterCondition)[];
  quickFilterLabel?: string;
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface Sort {
  field: string;
  order: SortOrder | string;
}
export interface TitleHeader {
  label: string;
  titleBar?: boolean;
}
export interface FilterBarOptions {
  disableFilterBarAdd?: boolean;
  disableAddingFilteredFields?: boolean;
  disableFilterBarClear?: boolean;
  disableSearchOperator?: boolean;
  defaultSearchOperator?: InternalOperator;
  defaultTextOperator?: InternalOperator;
  disableSearchExclusionBtn?: boolean;
  defaultIncludeCustomFields?: boolean;
  // Optionally specify the desired search fields, otherwise the STRING types will be plucked from the filterableFields
  searchFields?: FilterableField[];
  redirectToGlobalSearch?: boolean;
}

export interface TruncatedCellType {
  truncated: boolean;
  minWidth?: number;
}

export interface SinglePageLoadingOptions {
  isPreviousPageDisabled: boolean;
  isNextPageDisabled: boolean;
  totalCount?: number;
  currentPage: number;
}

export interface RowDisplayOptions {
  // Displays rows in one of two colors, alternating. "Zebra-Table". Default = false.
  alternatingColor?: boolean;
  // Activates a colored indication of hovering a row.
  hoverEffect?: boolean;
  // Activates a colored indication of having selected a row.
  selection?: boolean;
  // Activates a colored indication of having clicked on a row.
  highlight?: boolean;
}

export interface TableProps {
  e2e?: string;
  tableActions?: TableActionType[];
  rowActions?: RowActionType[];
  bulkActions?: BuckActionType[];
  columns: ColumnType[];
  rows: DataType[];
  selectedRows?: { [key: string]: DataType };
  /** If `page`, group or table selections only affect visible rows. This is the default.
   *  If `global`, group or table selections will affect all relevant rows in the collection.
   */
  selectionStrategy?: 'page' | 'global';
  /** If `true`, table cells get a compact vertical padding visual treatment */
  /**
   * @deprecated dense should not be used
   */
  dense?: boolean;
  /** Forces all columns to fit in the available viewport space (no scrolling).
   * Only enable this option on tables with a few columns, and the number of columns
   * is static.
   */
  fixed?: boolean;
  uniqueKey: string;
  groupByKey?: string;
  groupLink?: string;
  groupId?: string[];
  groupByRowAsset?: any;
  fixedGroupHeader?: boolean;
  showExpandAll?: boolean;
  nestedDataKey?: string;
  searchPlaceholder?: string;
  /** Specify the wildcard used in the search box. % is used by default.
   *  For the table with the standard object created in Murano as the data source,
   *  the wildcard should be *.
   */
  searchWildcard?: string;
  /** Display 'Customize Columns' table-level action, which permits re-ordering and hiding columns. */
  orderable?: boolean;
  /** Display 'Filter' table-level action, which permits creating a Hawk filter object. */
  /** Array of filterable fields to populate the 'Filter' popover. */
  filterableFields?: FilterableField[];
  /**  Array of field keys to be used as the baseline filters for New Filter Bar*/
  defaultFields?: string[];
  /**  Array of filter chips with predefined filter objects for New Filter Bar*/
  tabFilters?: TabFilterField[];
  quickFilters?: QuickFilterField[];
  /**   */
  vanityFilters?: VanityFilterField[];
  /**  Inserts a Search Box on the FilterBar that will provide a filter for all STRING type filterableFields */
  enableFilterBarSearch?: boolean;
  /** Load an existing Filter object to the 'Filter' popover.
   *  This is for display purposes only
   *  This filter should already have been applied to the incoming dataset
   */
  initialFilter?: Filter;
  /** Emit a Filter object for external datasource to use in fetching data */
  dsOnFilter?: (args: { filter: Filter }) => void;
  /** Must be used with other Filter props and enableLegacyFilters. Enables the use of chips to see and remove filter conditions.*/
  showFilterChips?: boolean;
  /** Use the legacy filtering experience. Defaults to true. Set to false to use New Filter Bar */
  enableLegacyFilters?: boolean;
  /** Set to false to disable column-level sorting. */
  sortable?: boolean;
  /** Load an existing Sort array to the column headers.
   *  This is for display purposes only
   *  This sort should already have been applied to the incoming dataset
   */
  initialSort?: Sort[];
  /**
   * This is for displaying a grouped row controls
   */
  showGroupedRowsControls?: boolean;
  /**
   * @deprecated searchable is a legacy feature, it is encouraged to use the FilterBar componentry
   */
  searchable?: boolean;
  /** Implementation to allow consuming applications to beacon internal table actions */
  dsInstrumentOn?: (args: {
    action: string;
    properties?: { removedCondition?: Filter | FilterCondition };
  }) => void;
  /** Emit a Sort array for external datasource to use in fetching data */
  dsOnSort?: (args: Sort[]) => void;
  dsOnSearchOperatorChange?: (operator: InternalOperator) => void;
  dsOnChangeIncludeCustomFields?: (include: boolean) => void;
  hidePagination?: boolean;
  loading?: boolean;
  showQueryTimeoutError?: boolean;
  dsOnPage?: (args: { page: number; rowsPerPage: number }) => void;
  dsIsRowSelectable?: (args: {
    row: DataType;
    children: DataType[];
  }) => boolean;
  /** Determines whether the selection checkbox in the row action should be disabled based on a condition. */
  dsIsRowSelectionDisabled?: (args: { row: DataType }) => boolean;
  dsIsCellEditable?: (args: {
    column: ColumnType;
    row: DataType;
    value: ValueType;
    columnIndex: number;
  }) => boolean;
  dsRenderMasterDetail?: (args: { row: DataType }) => React.ReactNode;
  dsHasMasterDetail?: (args: { row: DataType }) => boolean;
  dsOnEditChange?: (args: { row: DataType; rowIndex: number }) => void;
  dsOnEditingRow?: (args: { row: DataType; rowIndex: number }) => void;
  dsOnSelectionChange?: (state: DataType[]) => void;
  /** By default, the FilterBar Search field is embedded in the dsOnFilter response.
   * By providing dsOnSearchChange, the search term will be returned directly and omitted from the dsOnFilter object. */
  dsOnSearchChange?: (state: string) => void;
  dsOnColumnsChange?: (columns: ColumnType[]) => void;
  /**
   * Overrides the column control popover button click. This effectively disables "dsOnColumnsChange".
   */
  dsOnColumnControlPopoverClick?: () => void;
  maxHeight?: number;
  resizableColumns?: boolean;
  rowsPerPage?: number;
  setInputRowsPerPage?: (rows: number) => void;
  rowsPerPageOptions?: number[];
  hideTotalRows?: boolean;
  showSelectAll?: boolean;
  showNoRowsMessage?: boolean;
  delegatedEditableRow?: DataType;
  dsCustomRowEditAction?: CustomRowEditAction;
  sortStrategy?: 'server' | 'client';
  dsNoRecordsMessageOverride?: React.FC<any>;
  isGlobalEditModeEnabled?: boolean;
  remoteGlobalEditControl?: string;
  dsOnGlobalEditClick?: (col: string) => void;
  summaryRow?: React.ReactNode;
  hideGroupCheckbox?: boolean;
  /** Display a title and title bar for the table only when table is not searchable and filter bar is not enabled. */
  titleHeader?: TitleHeader;
  /** Options for the filter bar including:
   * Hide button to add new filters on Filter Bar.
   * Hides button to clear preexisting filters on Filter Bar.
   * Hides search operator dropdown
   * Only allow filters to be added to fields that do not already have a vanity filter, default field, and/or initial filter.
   * Designate default operator for Search
   * Designate default operator for Text Filter
   */
  filterBarOptions?: FilterBarOptions;
  truncatedCell?: TruncatedCellType;
  dsOnResizeChange?: (width: ColumnWidth[]) => void;
  stickyPosition?: 'left' | 'right';
  /**
   * Provide an array of column.field strings to be scrolled-to and highlighted
   */
  scrollToColumns?: string[];
  /** Single page loading
   * Row data will be for single page only
   * Option to disable nextPage/previousPage in pagination
   * */
  singlePageLoadingOptions?: SinglePageLoadingOptions;
  rowDisplayOptions?: RowDisplayOptions;
  initGroupRowsCollapsed?: boolean;
  groupedRowSelectedCount?: boolean;
  size?: 'small' | 'medium';
}

const StyledMuiTable = styled(MuiTable, transientOptions)<{ $fixed: boolean }>`
  table-layout: ${({ $fixed }) => ($fixed ? 'fixed' : 'auto')};
`;

const StyledColumnResizer = styled('button', transientOptions)<{
  $themeTokens: ReturnType<typeof useThemeTokens>;
}>`
  cursor: col-resize;
  border: none;
  background: none;
  color: currentcolor;
  display: flex;
  align-items: center;
  position: absolute;
  right: -16px;
  width: 32px;
  top: 0;
  bottom: 0;
  margin: 0;
  z-index: 1;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:focus::after,
  &:hover::after {
    background-color: ${({ $themeTokens }) => $themeTokens.emphasisMedium};
    width: 2px;
  }

  &::after {
    background-color: ${({ $themeTokens }) => $themeTokens.emphasisLow};
    content: '';
    width: 1px;
    position: absolute;
    left: 16px;
  }
`;

const StyledColumnContainer = styled('div', transientOptions)<{
  $align: 'left' | 'right';
}>`
  display: flex;
  justify-content: ${({ $align }) =>
    $align === 'right' ? 'flex-end' : 'flex-start'};
  align-items: center;
`;

const StyledColumnLabel = styled('span', transientOptions)<{
  $active: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${({ $active }) => ($active ? 'calc(100% + 26px)' : '100%')};
  display: flex;
`;

const StyledTableSortLabel = styled(TableSortLabel)`
  line-height: 16px;
  width: ${({ active }) => (active ? 'auto' : 0)};
  z-index: 2;
  overflow: hidden;
`;

const StyledTableActionsContainer = styled('div', transientOptions)<{
  $notEmpty: boolean;
  $color: string;
}>`
  border-left: ${({ $notEmpty, $color }) =>
    $notEmpty ? `1px solid ${$color}` : 'none'};
  padding-left: ${({ $notEmpty }) => ($notEmpty ? '8px' : 0)};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const Table: React.FC<TableProps> = ({
  dense = false,
  e2e,
  fixed = false,
  rows = [],
  selectedRows,
  selectionStrategy = 'page',
  uniqueKey = '',
  groupByKey,
  groupLink,
  groupId = [],
  groupByRowAsset,
  fixedGroupHeader,
  showExpandAll,
  nestedDataKey,
  columns: inputColumns = [],
  tableActions,
  rowActions = [],
  bulkActions,
  searchPlaceholder,
  orderable,
  filterableFields,
  defaultFields,
  vanityFilters,
  tabFilters,
  quickFilters,
  enableFilterBarSearch = false,
  initialFilter,
  dsOnFilter,
  dsOnSearchOperatorChange,
  dsOnChangeIncludeCustomFields,
  showFilterChips,
  enableLegacyFilters = true,
  hidePagination,
  loading,
  showQueryTimeoutError,
  searchable = false,
  dsOnPage,
  dsIsRowSelectable,
  dsIsRowSelectionDisabled,
  dsIsCellEditable,
  dsRenderMasterDetail,
  dsHasMasterDetail,
  dsOnEditChange,
  dsOnEditingRow,
  dsOnSelectionChange,
  dsOnSearchChange,
  dsOnColumnsChange,
  dsOnColumnControlPopoverClick,
  dsInstrumentOn,
  maxHeight,
  resizableColumns = false,
  rowsPerPage: inputRowsPerPage,
  setInputRowsPerPage,
  rowsPerPageOptions = [5, 10, 15, 20, 30, 50],
  hideTotalRows,
  showSelectAll = true,
  showNoRowsMessage,
  delegatedEditableRow,
  dsCustomRowEditAction,
  sortable = true,
  initialSort,
  showGroupedRowsControls = false,
  dsOnSort,
  sortStrategy = 'client',
  dsNoRecordsMessageOverride: CustomNoRecordsComponent,
  isGlobalEditModeEnabled = false,
  remoteGlobalEditControl,
  dsOnGlobalEditClick,
  summaryRow,
  hideGroupCheckbox,
  titleHeader,
  filterBarOptions: userFilterBarOptions,
  truncatedCell,
  stickyPosition,
  dsOnResizeChange,
  scrollToColumns = [],
  singlePageLoadingOptions,
  rowDisplayOptions,
  initGroupRowsCollapsed = false,
  groupedRowSelectedCount = false,
  searchWildcard = '%',
  size = 'medium',
}: TableProps) => {
  const themeTokens = useThemeTokens();
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const chipsOverflowContainerRef = React.useRef<HTMLDivElement>(null);
  const actionsRef = React.useRef<HTMLDivElement>(null);
  const [isNextPageDisabled, setIsNextPageDisabled] = React.useState(false);

  // @ts-ignore
  const { refXScrollEnd, refXOverflowing, refXScrollBegin } =
    useOverflow(tableContainerRef);

  const { refXScrollEnd: chipsXScrollEnd, refXOverflowing: chipsXOverflowing } =
    useOverflow(chipsOverflowContainerRef);
  const { translate } = useTranslate();

  const [overflowColor, setOverflowColor] = React.useState<string>();
  const [columnLabelTooltipIndex, setColumnLabelTooltipIndex] =
    React.useState(-1);
  const [tabFilterIndex, setTabFilterIndex] = React.useState(0);

  const [editingColumnRow, setEditingColumnRow] = React.useState('');

  const [nestedRowsOffsetPerPage, setNestedRowsOffsetPerPage] = React.useState(
    {},
  );

  React.useEffect(() => {
    setEditingColumnRow(remoteGlobalEditControl || '');
  }, [remoteGlobalEditControl]);

  const filterBarOptions = useFilterBarOptions(userFilterBarOptions);

  const hasActions = !!rowActions?.length;
  const hasSelection = !!dsOnSelectionChange;
  const isEditable =
    !!isGlobalEditModeEnabled || (!isGlobalEditModeEnabled && !!dsOnEditChange);
  const hasExpandItem = (!!groupByKey && !fixedGroupHeader) || !!nestedDataKey;
  const hasOverflow = fixed ? false : refXOverflowing && !refXScrollEnd;

  const hasOverflowLeft =
    stickyPosition === 'left' ? false : refXOverflowing && !refXScrollBegin;

  const chipsHasOverflow = fixed
    ? false
    : chipsXOverflowing && !chipsXScrollEnd;
  const theme = useTheme();
  const filterChipBackgroundColor = theme.palette.background.default;

  const defaultQuickFilter = quickFilters?.find((filter) => filter?.value);

  // @ts-ignore
  const nestedRowsMap: {
    [key: string]: DataType[];
  } = React.useMemo(() => {
    if (!nestedDataKey) return {};

    const nestedRowsMap = rows.reduce((acc, row) => {
      const parentId = row[nestedDataKey] as string;
      if (parentId) {
        // @ts-ignore
        acc[parentId] = [row].concat(acc[parentId] || []);
      }
      return acc;
    }, {});

    return nestedRowsMap;
  }, [rows, nestedDataKey]);

  // Editable row data
  const {
    editableRow,
    handleToggleRowEdit,
    handleInputChange: handleInputChangeSingle,
  } = useEditableRow({
    uniqueKey,
    rows,
    dsOnEditChange,
    dsOnEditingRow,
    delegatedEditableRow,
  });

  // Custom Columns
  const { columns, handleColumnsChange } = useCustomColumns({
    inputColumns,
    dsOnColumnsChange,
  });

  // Pagination
  const {
    page,
    rowsPerPage,
    pageStart,
    pageEnd,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSetRowsPerPage,
  } = usePagination({
    // if we're hiding pagination, we show the entire collection at once
    inputRowsPerPage: hidePagination ? rows.length : inputRowsPerPage,
    dsOnPage,
    setInputRowsPerPage,
    singlePageLoadingOptions,
  });

  const resetPagination = () => {
    if (page !== 0) {
      handleChangePage(0);
    }
  };

  // Update pagination state if rows per page input changes
  React.useEffect(() => {
    if (!hidePagination && !!inputRowsPerPage) {
      handleSetRowsPerPage(inputRowsPerPage);
    } else if (hidePagination) {
      handleSetRowsPerPage(rows.length);
    }
  }, [inputRowsPerPage, hidePagination, rows]);

  // Standalone Search
  const { search, handleSearch } = useSearch({
    page,
    handleChangePage,
    dsOnSearchChange,
  });

  // Sort
  const { order, orderBy, handleSortClick } = useSort({
    dsOnSort,
    initialSort,
    sortStrategy,
    defaultQuickFilter,
  });

  // Display Data
  const searchedAndSortedRows = React.useMemo(() => {
    let output = dsOnSearchChange ? rows : stableSearch(rows, search);
    if (orderBy && sortStrategy === 'client') {
      output = stableSort<DataType>(
        output,
        // @ts-ignore TODO fix type
        getComparator(order, orderBy),
      );
    }

    return output;
  }, [rows, search, order, orderBy, dsOnSearchChange]);

  // Global editable multi row data
  const {
    editableRows,
    handleInputBlur,
    handleInputChange: handleInputChangeMultiple,
  } = useEditableRows({
    uniqueKey,
    rows,
    displayRows: searchedAndSortedRows,
    dsOnEditChange,
    pageStart,
    pageEnd,
    isGlobalEditModeEnabled,
    offsetNestedRows: nestedRowsOffsetPerPage[page],
  });

  // Grouped Data
  const {
    collapsedGroups,
    groupedRows,
    collapsedRows,
    onToggleHeaderRow,
    onToggleRow,
    onExpandAll,
    onCollapseAll,
    onToggleTableHeader,
    allGroupExpanded,
    hideCheckbox,
  } = useGroupedData({
    groupByKey,
    searchedAndSortedRows,
    nestedDataKey,
    hideGroupCheckbox,
    fixedGroupHeader,
    initGroupRowsCollapsed,
  });

  const rowsCount = React.useMemo(() => {
    const values: any[] = Object.values(groupedRows?.map || {});
    return values.reduce((acc: number, curr: any[]): number => {
      if (curr?.length) {
        return acc + curr?.length;
      }
      return acc;
    }, 0);
  }, [groupedRows?.map]);

  const rowsInView = React.useMemo(() => {
    const collection = [...groupedRows.collection];

    if (!collection.length) return [];

    const out: any[] = [];
    let i = pageStart + (nestedRowsOffsetPerPage[page] || 0);
    let childrenOnPage = 0;
    while (out.length < rowsPerPage && collection[i]) {
      const item = collection[i];

      // omit nested rows
      if (!item.hasOwnProperty(nestedDataKey)) {
        out.push(item);
      } else {
        childrenOnPage++;
      }
      i++;
    }
    setNestedRowsOffsetPerPage({
      [page + 1]: childrenOnPage + (nestedRowsOffsetPerPage[page] || 0),
      ...nestedRowsOffsetPerPage,
    });

    return out;
  }, [groupedRows, pageStart, rowsPerPage]);

  // Multi-Select
  const {
    handleSelectAllClick,
    handleCheckboxClick,
    isSelected,
    isIndeterminate,
    handleClearSelections,
    handleGroupCheckboxClick,
    getSelectedRowsCount,
    getGroupSelectedRowsCount,
    isAllSelectIndeterminate,
    isAllSelected,
    isGroupSelectIndeterminate,
    isGroupSelected,
  } = useMultiSelect({
    uniqueKey,
    selectedRows,
    rows,
    rowsInView,
    nestedRowsMap,
    nestedDataKey,
    groupByKey,
    dsOnSelectionChange,
    dsIsRowSelectionDisabled,
    dsIsRowSelectable,
    selectionStrategy,
  });

  const rowDisplayUtils = useRowDisplayOptions({
    rowDisplayOptions,
    overflowColor,
    theme,
    isSelected,
    isIndeterminate,
  });

  // Column Resize
  const resizeRefs = React.useRef<HTMLButtonElement[]>([]);
  const activeColumn = React.useRef<{
    field: string;
    initPageX: number;
    initWidth: number;
  } | null>(null);
  const columnRefs = React.useRef<{
    [key: string]: HTMLTableCellElement;
  }>({});
  const columnMap = columns.reduce((prevValue, currValue) => {
    return {
      ...prevValue,
      [currValue.field]: currValue,
    };
  }, {} as { [key: string]: ColumnType });

  const { highlightColumnMap } = useColumnHighlighter({
    scrollToColumns,
    columnRefs,
  });

  const getColumnWidth = (columnName: string) => {
    const column = columnMap[columnName];
    if (column?.width) {
      return column.width;
    }

    const node = columnRefs.current[columnName];
    if (node) {
      const { width } = node.getBoundingClientRect();
      return width;
    }
  };

  const selectedRowsCount = getSelectedRowsCount();

  // TODO: move to TableRows component along with all the rest of the GroupRow / Row rendering logic
  // -- track the start idx to avoid rendering collapsed groups
  let startIdx = 0;

  const colSpan =
    columns.length +
    (hasSelection ? 1 : 0) +
    (hasActions || (isEditable && !isGlobalEditModeEnabled) ? 1 : 0);

  const actionCount =
    isEditable && !isGlobalEditModeEnabled
      ? rowActions.length + 1
      : rowActions.length;

  const hasTableActions = Boolean(
    tableActions?.length ||
      orderable ||
      (enableLegacyFilters && filterableFields?.length),
  );

  const hasBulkActions = Boolean(bulkActions?.length);

  /* Filtering */

  const resetPage = () => {
    resetPagination();
    handleClearSelections();
  };

  const resetPageOnSort = (property?: keyof DataType, order?: OrderType) => {
    handleSortClick(property, order);
    resetPage();
  };

  const [internalFilterWithIds, setInternalFilterWithIds] =
    React.useState<any>(undefined);

  useEffect(() => {
    if (initialFilter) {
      setInternalFilterWithIds({
        ...initialFilter,
        conditions: initialFilter?.conditions?.map((cond) => ({
          ...cond,
          fieldId: cond.fieldId || uuid(),
        })),
      });
    } else if (defaultQuickFilter && defaultQuickFilter?.filter) {
      setInternalFilterWithIds({
        ...defaultQuickFilter?.filter,
        conditions: (defaultQuickFilter?.filter?.conditions || [])?.map(
          (cond) => ({
            ...cond,
            fieldId: cond.fieldId || uuid(),
          }),
        ),
      });
    }
  }, [initialFilter]);

  const { updateColumnWidths } = useColumnWidths({
    columnRefs,
    dsOnResizeChange,
    inputColumns,
  });

  const initialFields = React.useRef<any>({});

  React.useEffect(() => {
    if (filterableFields && filterBarOptions) {
      initialFields.current = FilterBarFactory.filterToFields(
        filterableFields!,
        undefined,
        defaultFields,
        vanityFilters,
        undefined,
        filterBarOptions,
        searchWildcard,
      );
    }
  }, [filterableFields, filterBarOptions, searchWildcard]);

  const {
    setFilter,
    setFields,
    filterUtil,
    fields,
    filter,
    chipsFilter,
    removeFilterByField,
    removeFilter,
    includeCustomFields,
    setIncludeCustomFields,
    searchMode,
    setSearchMode,
    changeQueryParameters,
  } = useFilter({
    dsOnFilter,
    initialFilter: internalFilterWithIds,
    filterableFields,
    enableLegacyFilters,
    defaultFields,
    orderBy,
    resetPage,
    resetPageOnSort,
    vanityFilters,
    initialFields,
    filterBarOptions,
    defaultQuickFilter,
    searchWildcard,
  });

  const isShowClearFilterButton = React.useMemo(() => {
    return (
      !filterBarOptions?.disableFilterBarClear &&
      (!isEqual(
        getValueFields(fields),
        getValueFields(initialFields.current),
      ) ||
        !!orderBy)
    );
  }, [orderBy, fields]);

  const isExpanded = React.useCallback(
    (uniqueValue: string): boolean => {
      return !collapsedRows[uniqueValue];
    },
    [collapsedRows],
  );

  const visibleNestedElements = Object.values(nestedRowsMap).reduce(
    (visibleNestedElements, item) => visibleNestedElements - item?.length,
    rows?.length ?? 0,
  );

  const showFilterBar: boolean =
    !enableLegacyFilters && (!!dsOnSearchChange || !!filterableFields?.length);

  const showExpandSearch = React.useMemo(
    () =>
      showFilterBar &&
      Object.values(fields).some(
        (field) =>
          field.internalOperator === InternalOperator.STARTSWITH ||
          field.internalOperator === InternalOperator.EQUALS,
      ),
    [showFilterBar, fields],
  );

  const showTitleHeader = !showFilterBar && !!titleHeader;

  const isSinglePageLoading = !!singlePageLoadingOptions;

  const ExpandSearchComponent = () => {
    return (
      (showExpandSearch && (
        <Box
          sx={{
            marginTop: '0.5rem',
          }}
        >
          <Box sx={{ margin: '24px 0' }}>
            <Box
              sx={{
                fontSize: '15px',
                lineHeight: '24px',
                marginBottom: '16px',
              }}
            >
              {translate('ExpandSearchExplainer')}
            </Box>
            {filterBarOptions?.redirectToGlobalSearch && (
              <Box
                sx={{
                  fontSize: '15px',
                  lineHeight: '24px',
                  marginBottom: '16px',
                }}
              >
                {translate('GlobalSearchExplainer')}
              </Box>
            )}
          </Box>
          <ButtonGroup>
            {filterBarOptions?.redirectToGlobalSearch && (
              <Box sx={{ paddingRight: '16px' }}>
                <Button
                  e2e={`${e2e}-use-global-search`}
                  variant="outlined"
                  href="/platform/apps/search"
                  body={translate('UseGlobalSearch')}
                />
              </Box>
            )}
            <Button
              variant="contained"
              e2e={`${e2e}-reset-filters`}
              dsOnClick={removeFilter}
              body={translate('ResetFilters')}
              disabled={!isShowClearFilterButton}
            />
          </ButtonGroup>
        </Box>
      )) ||
      null
    );
  };

  const QueryTimeoutComponent = () => {
    return (
      <Box
        sx={{
          padding: theme.spacing(2),
          textAlign: 'center',
          maxWidth: '420px',
          margin: 'auto',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            marginTop: '1rem',
          }}
        >
          {translate('QueryTimeoutError')}
        </Typography>
        <Box
          sx={{
            marginTop: '0.5rem',
          }}
        >
          <Box sx={{ margin: '24px 0' }}>
            <Box
              sx={{
                fontSize: '15px',
                lineHeight: '24px',
                marginBottom: '16px',
              }}
            >
              {
                (translate('QueryTimeoutExplainer') as string).split(
                  new RegExp(translate('ZuoraSupport'), 'i'),
                  2,
                )[0]
              }
              <Link
                href="https://www.zuora.com/support-center/"
                color="primary"
              >
                {translate('ZuoraSupport')}
              </Link>
              {
                (translate('QueryTimeoutExplainer') as string).split(
                  new RegExp(translate('ZuoraSupport'), 'i'),
                  2,
                )[1]
              }{' '}
            </Box>
          </Box>
          <ButtonGroup>
            <Box sx={{ paddingRight: '16px' }}>
              <Button
                e2e={`${e2e}-change-query-parameters`}
                variant="outlined"
                dsOnClick={changeQueryParameters}
                body={translate('ChangeQueryParameters')}
                disabled={
                  searchMode === InternalOperator.STARTSWITH &&
                  !includeCustomFields
                }
              />
            </Box>
            <Button
              variant="contained"
              e2e={`${e2e}-reset-filters`}
              dsOnClick={removeFilter}
              body={translate('ResetFilters')}
              disabled={!isShowClearFilterButton}
            />
          </ButtonGroup>
        </Box>
      </Box>
    );
  };

  return (
    <StyledWrapper
      {...(e2e && { 'data-e2e': `${e2e}-table` })}
      ref={(wrapper) => {
        if (wrapper) {
          const color = getBackgroundColor(wrapper);
          color && setOverflowColor(color);
        }
      }}
    >
      <TableOpacity $loading={loading}>
        <StyledHeaderControlWrapper
          {...(e2e && {
            'data-e2e': `${e2e}-table-controls`,
          })}
          $divider={theme.palette.divider}
          $horAlign="space-between"
        >
          <TabFilters
            e2e="tab-filters"
            fields={fields}
            filter={filter}
            setFields={setFields}
            setFilter={setFilter}
            removeFilter={removeFilter}
            tabFilters={tabFilters || []}
            tabFilterIndex={tabFilterIndex}
            setTabFilterIndex={setTabFilterIndex}
          />
          {tabFilterIndex > 0 && hasTableActions && (
            <StyledTableActionsContainer
              $color={themeTokens.contextHigh}
              $notEmpty={hasTableActions}
            >
              <ActionsList
                {...(e2e && {
                  e2e: `${e2e}-table`,
                })}
                actions={tableActions}
              />

              {enableLegacyFilters && filterableFields?.length && (
                <FilterPopover
                  e2e={e2e}
                  hasActions={Boolean(tableActions?.length)}
                  filterableFields={filterableFields}
                  fields={fields}
                  setFilter={setFilter}
                  setFields={setFields}
                  removeFilter={removeFilter}
                />
              )}

              {orderable && (
                // @ts-ignore
                <DndProvider backend={HTML5Backend}>
                  <ColumnControlPopoverList
                    e2e={e2e}
                    hasActions={Boolean(tableActions?.length)}
                    columns={columns.filter(
                      (column) => !column.disableOrdering,
                    )}
                    onColumnControlPopoverClick={dsOnColumnControlPopoverClick}
                    onColumnsChange={handleColumnsChange}
                  />
                </DndProvider>
              )}
            </StyledTableActionsContainer>
          )}
        </StyledHeaderControlWrapper>
        <Collapse in={tabFilterIndex === 0}>
          <StyledHeaderControlWrapper
            {...(e2e && {
              'data-e2e': `${e2e}-table-controls`,
            })}
            $divider={theme.palette.divider}
          >
            {showTitleHeader && (
              <CardHeader
                sx={{
                  padding: theme.spacing(1.5),
                  maxHeight: '48px',
                  marginRight: 'auto',
                }}
                title={
                  <>
                    {titleHeader?.titleBar && <TitleBar />}
                    <Box>{titleHeader?.label || ''}</Box>
                  </>
                }
              />
            )}
            {searchable && enableLegacyFilters && (
              <SearchBar
                e2e={e2e}
                search={search}
                searchPlaceholder={searchPlaceholder}
                onSearch={handleSearch}
              />
            )}
            {showFilterBar && (
              <FilterBar
                e2e={e2e}
                enableFilterBarSearch={enableFilterBarSearch}
                searchPlaceholder={searchPlaceholder}
                instrumentOn={dsInstrumentOn}
                {...(!!dsOnSearchChange && {
                  dsOnSearchChange: (state: string) => {
                    dsOnSearchChange(state);
                    handleChangePage(0);
                  },
                })}
                defaultFields={defaultFields}
                filterableFields={filterableFields!}
                fields={fields}
                initialFilter={internalFilterWithIds}
                filter={filter}
                setFilter={setFilter}
                setFields={setFields}
                removeFilter={removeFilter}
                filterBarOptions={filterBarOptions}
                quickFilters={quickFilters}
                dsOnSort={dsOnSort}
                isShowClearFilterButton={isShowClearFilterButton}
                vanityFilters={vanityFilters}
                orderBy={orderBy}
                dsOnSearchOperatorChange={dsOnSearchOperatorChange}
                dsOnChangeIncludeCustomFields={dsOnChangeIncludeCustomFields}
                includeCustomFields={includeCustomFields}
                setIncludeCustomFields={setIncludeCustomFields}
                searchMode={searchMode}
                setSearchMode={setSearchMode}
                searchWildcard={searchWildcard}
              />
            )}
            {hasTableActions && (
              <StyledTableActionsContainer
                $color={themeTokens.contextHigh}
                $notEmpty={hasTableActions}
              >
                <ActionsList
                  {...(e2e && {
                    e2e: `${e2e}-table`,
                  })}
                  actions={tableActions}
                />

                {enableLegacyFilters && filterableFields?.length && (
                  <FilterPopover
                    e2e={e2e}
                    hasActions={Boolean(tableActions?.length)}
                    filterableFields={filterableFields}
                    fields={fields}
                    setFilter={setFilter}
                    setFields={setFields}
                    removeFilter={removeFilter}
                  />
                )}

                {orderable && (
                  // @ts-ignore
                  <DndProvider backend={HTML5Backend}>
                    <ColumnControlPopoverList
                      e2e={e2e}
                      hasActions={Boolean(tableActions?.length)}
                      columns={columns.filter(
                        (column) => !column.disableOrdering,
                      )}
                      onColumnControlPopoverClick={
                        dsOnColumnControlPopoverClick
                      }
                      onColumnsChange={handleColumnsChange}
                    />
                  </DndProvider>
                )}
              </StyledTableActionsContainer>
            )}
          </StyledHeaderControlWrapper>
        </Collapse>
        {showFilterChips && enableLegacyFilters && (
          <Box
            sx={{
              paddingTop: theme.spacing(1),
              paddingRight: theme.spacing(2),
              paddingBottom: '0',
              paddingLeft: theme.spacing(2),
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                '& div': {
                  marginRight: theme.spacing(1),
                  height: '20px',
                },
                paddingBottom: theme.spacing(1),
              }}
              ref={chipsOverflowContainerRef}
            >
              {(chipsFilter?.conditions?.length > 0 &&
                chipsFilter?.conditions?.map((chip) => (
                  <Chip
                    e2e={`${chip.field}-${chip.operator}-${chip.value}`}
                    key={`${chip.field}-${chip.operator}-${chip.value}`}
                    state="default"
                    uppercase={false}
                    label={`${
                      fields[chip.field]?.label || capitalize(chip.field || '')
                    } ${
                      filterUtil.operatorCharacterMap[chip.operator]
                    } ${filterUtil.getPicklistLabel(chip, filterableFields)}`}
                    dismissible
                    dsOnDelete={() => removeFilterByField(chip)}
                  />
                ))) ||
                'No Filters Applied'}
            </Box>
            {chipsFilter?.conditions?.length > 0 && (
              <StyledFilterClearContainer
                data-chromatic="ignore"
                {...(e2e && {
                  'data-e2e': `${e2e}-chip-bar-clear-filter`,
                })}
                onClick={removeFilter}
                $overflowColor={filterChipBackgroundColor}
                $hasOverflow={chipsHasOverflow}
              >
                <Link>{translate('ClearFilters')}</Link>
              </StyledFilterClearContainer>
            )}
          </Box>
        )}
        {hasSelection && (
          <StyledHeaderControlWrapper
            {...(e2e && {
              'data-e2e': `${e2e}-table-controls`,
            })}
            $divider={theme.palette.divider}
            $horAlign="space-between"
          >
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  padding: theme.spacing(2),
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '13px',
                  lineHeight: '18px',
                  color: designTokens.colors.coolGray400,
                }}
                e2e={`${e2e}-rows-selected-message`}
              >
                Selected {selectedRowsCount} Items
              </Typography>
              {selectedRowsCount > 0 && (
                <Button
                  variant="text"
                  body={translate('ClearSelection')}
                  dsOnClick={() => {
                    handleClearSelections();
                  }}
                />
              )}
            </Box>
            {hasBulkActions && selectedRowsCount > 0 && (
              <StyledTableActionsContainer
                $color={themeTokens.contextHigh}
                $notEmpty={false}
              >
                <ActionsList
                  {...(e2e && {
                    e2e: `${e2e}-table`,
                  })}
                  actions={bulkActions}
                />
              </StyledTableActionsContainer>
            )}
          </StyledHeaderControlWrapper>
        )}
        <TableContainer ref={tableContainerRef} style={{ maxHeight }}>
          <StyledMuiTable stickyHeader={!!maxHeight} $fixed={fixed} size={size}>
            <TableHead>
              <TableRow>
                {hasSelection && (
                  <TableCell
                    padding="checkbox"
                    sx={{
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      position: 'relative',
                      width: 40,
                      paddingLeft: theme.spacing(0.5),
                      paddingRight: 0,
                    }}
                  >
                    {showSelectAll && (
                      <Checkbox
                        color="primary"
                        indeterminate={isAllSelectIndeterminate()}
                        checked={isAllSelected()}
                        disabled={
                          selectionStrategy === 'page' && !rowsInView.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={
                          {
                            ...(e2e && {
                              'data-e2e': `${e2e}-table-head-checkbox`,
                            }),
                            ...{ 'aria-label': 'select all' },
                          } as any
                        }
                      />
                    )}
                  </TableCell>
                )}
                {columns.map((column, columnIndex) => {
                  if (column.hidden) return null;

                  if (column.loading) {
                    return (
                      <TableCell key={`${column.field}-${columnIndex}`}>
                        <Shimmer />
                      </TableCell>
                    );
                  }

                  const active = orderBy === column.field;

                  const columnSortable = sortable && (column.sortable ?? true);

                  return (
                    <TableCell
                      key={`table-head-table-cell-${columnIndex}`}
                      {...(e2e && {
                        'data-e2e': `${e2e}-table-head-table-cell-${column.field}`,
                      })}
                      sx={{
                        '&:focus-visible': {
                          outlineOffset: isSafari ? '-5px' : '-1px',
                        },
                        backgroundColor:
                          highlightColumnMap[column.field] ||
                          overflowColor ||
                          '',
                        textTransform: 'capitalize',
                        fontSize: '12px',
                        cursor: 'pointer',
                        position: 'relative',
                        fontWeight: '600',
                        lineHeight: '16px',
                        ...(column.minWidth && { minWidth: column.minWidth }),
                        ...(fixed && { wordBreak: 'break-word' }),
                        ...(!columnSortable && { cursor: 'default' }),
                        ...(column.stickyPosition === 'left' &&
                          hasOverflowLeft && {
                            position: 'sticky',
                            left: 0,
                            zIndex: 1,
                            boxShadow: `4px 0px 8px ${designTokens.colors.lightContextMedium}`,
                          }),
                        ...(column.stickyPosition === 'right' &&
                          hasOverflow && {
                            position: 'sticky',
                            right: 0,
                            zIndex: 1,
                            boxShadow: `-4px 0px 8px ${designTokens.colors.lightContextMedium}`,
                          }),
                        ...column.sx,
                      }}
                      style={{
                        right:
                          column.stickyPosition === 'right' && hasOverflow
                            ? actionsRef.current?.offsetWidth
                            : 0,
                      }}
                      ref={(thNode: HTMLTableCellElement) => {
                        columnRefs.current[column.field as any] = thNode;
                      }}
                      {...(columnSortable && {
                        tabIndex: 0,
                        onClick: () => resetPageOnSort(column.field),
                        onKeyDown: (e) => {
                          const isSpace = e.keyCode === 32;
                          const isEnter = e.keyCode === 13;

                          if (isSpace || isEnter) {
                            resetPageOnSort(column.field);
                          }
                        },
                      })}
                    >
                      <StyledColumnContainer
                        $align={column.alignment || 'left'}
                      >
                        {hasExpandItem && columnIndex === 0 && (
                          <Tooltip
                            title={translate(
                              allGroupExpanded ? 'CollapseAll' : 'ExpandAll',
                            )}
                          >
                            <IconButton
                              {...(e2e && {
                                'data-e2e': `${e2e}-${
                                  allGroupExpanded ? 'collpase' : 'expand'
                                }-all-button`,
                              })}
                              color="primary"
                              size="small"
                              style={{
                                maxWidth: theme.spacing(2.5),
                                maxHeight: theme.spacing(2.5),
                                ...(!showExpandAll && {
                                  visibility: 'hidden',
                                }),
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                resetPagination();
                                onToggleTableHeader();
                              }}
                            >
                              <Icon
                                body={
                                  allGroupExpanded
                                    ? 'collapse_all'
                                    : 'expand_all'
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <StyledColumnContainer
                          $align={column.alignment || 'left'}
                          onMouseEnter={() =>
                            setColumnLabelTooltipIndex(columnIndex)
                          }
                          onMouseLeave={() => setColumnLabelTooltipIndex(-1)}
                        >
                          <StyledColumnLabel $active={active}>
                            {column.label || column.field}
                            {column.labelTooltip && (
                              <LabelInfoTypography
                                body={<LabelInfoIcon body="help_outline" />}
                                component="span"
                                tooltip={column.labelTooltip}
                                tooltipOpen={
                                  columnLabelTooltipIndex === columnIndex
                                }
                              />
                            )}
                          </StyledColumnLabel>
                          {columnSortable && (
                            <StyledTableSortLabel
                              tabIndex={-1}
                              active={active}
                              direction={active ? order : 'asc'}
                            />
                          )}
                        </StyledColumnContainer>
                      </StyledColumnContainer>
                      {resizableColumns && (
                        <StyledColumnResizer
                          $themeTokens={themeTokens}
                          ref={(elem) => {
                            if (elem) {
                              resizeRefs.current.push(elem);
                            }
                          }}
                          onClick={(e) => {
                            // prevent sorting
                            e.stopPropagation();
                          }}
                          onMouseDownCapture={(e) => {
                            activeColumn.current = {
                              field: column.field,
                              initPageX: Number(e.pageX),
                              initWidth: e.currentTarget?.parentElement
                                ?.offsetWidth as number,
                            };

                            const handleMouseMove = (e: MouseEvent) => {
                              if (
                                activeColumn.current?.field === column.field
                              ) {
                                const width = activeColumn.current.initWidth;
                                if (!width) {
                                  return;
                                }
                                const diffX =
                                  e.pageX - activeColumn.current.initPageX;

                                // Minimum width of 80px
                                const newWidth = Math.max(width + diffX, 80);
                                [
                                  columnRefs.current[column.field],
                                  ...Array.from(
                                    (
                                      tableContainerRef.current as HTMLElement
                                    ).querySelectorAll<HTMLElement>(
                                      `[data-column="${column.field}"]`,
                                    ),
                                  ),
                                ].forEach((cell) => {
                                  // Imperatively control the width while dragging for maximal performance.
                                  cell.style.width = `${newWidth}px`;
                                  cell.style.minWidth = `${newWidth}px`;
                                  cell.style.maxWidth = `${newWidth}px`;
                                });
                              }
                            };
                            const handleMouseUp = (e: MouseEvent) => {
                              window.document.removeEventListener(
                                'mousemove',
                                handleMouseMove,
                              );
                              window.document.removeEventListener(
                                'mouseup',
                                handleMouseUp,
                              );

                              const clonedColumns = [...columns].map((c) => ({
                                ...c,
                              }));

                              const newColumn = clonedColumns.find(
                                (col) =>
                                  col.field === activeColumn.current?.field,
                              );

                              if (newColumn && activeColumn?.current) {
                                const width = activeColumn.current?.initWidth;
                                if (!width) {
                                  return;
                                }
                                const diffX =
                                  e.pageX - activeColumn.current?.initPageX;

                                // Minimum width of 80px
                                const newWidth = Math.max(width + diffX, 80);

                                updateColumnWidths({
                                  field: activeColumn.current?.field,
                                  width: newWidth,
                                });

                                newColumn.width = newWidth;
                                activeColumn.current = null;
                                dsOnColumnsChange?.(clonedColumns);
                              }
                            };

                            window.document.addEventListener(
                              'mousemove',
                              handleMouseMove,
                            );
                            window.document.addEventListener(
                              'mouseup',
                              handleMouseUp,
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Tab') {
                              return;
                            }
                            e.preventDefault();

                            const width = getColumnWidth(column.field);
                            if (width) {
                              const newColumns = [...columns];
                              const widthChange = (() => {
                                if (e.key === 'ArrowLeft') {
                                  return -5;
                                }
                                if (e.key === 'ArrowRight') {
                                  return 5;
                                }
                                return 0;
                              })();
                              // min width is 80px
                              const newWidth = Math.max(
                                width + widthChange,
                                80,
                              );
                              newColumns[columnIndex].width = newWidth;
                              dsOnColumnsChange?.(newColumns);
                            }
                          }}
                        />
                      )}
                    </TableCell>
                  );
                })}
                <TableCell
                  ref={actionsRef}
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '12px !important',
                    cursor: 'pointer',
                    position: 'relative',
                    fontWeight: '600 !important',
                    lineHeight: '16px !important',
                    '& *': {
                      color: `${designTokens.colors.lightEmphasisHigh} !important`,
                    },
                    // https://github.com/styled-components/styled-components/issues/1253#issuecomment-337871693
                    '&&': {
                      position: 'sticky',
                    },
                    '&:empty': {
                      padding: 0,
                    },
                    width: `${actionCount * 40}px`,
                    right: 0,
                    zIndex: 1,
                    ...getBaseOverflowStylesSX(hasOverflow, overflowColor),
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedRows.map).map(
                ([groupKey, children]: any, index) => {
                  const groupSelectedRowsCount =
                    getGroupSelectedRowsCount(groupKey);

                  const isItemGroupExpanded = !collapsedGroups[groupKey];

                  const lowerBound = startIdx;

                  if (
                    pageStart > startIdx + children.length ||
                    pageEnd < startIdx
                  ) {
                    if (isItemGroupExpanded) {
                      startIdx += children.length;
                    }
                    return null;
                  } else if (isItemGroupExpanded) {
                    startIdx += children.length;
                  }

                  const isPastLowerBound = pageStart > lowerBound;

                  const isGroupRowIndeterminate =
                    isGroupSelectIndeterminate(groupKey);
                  const isGroupRowSelected = isGroupSelected(groupKey);

                  return (
                    <React.Fragment
                      key={`table-body-row-group-fragment-${groupKey}`}
                    >
                      {!!groupKey && (
                        <GroupRow
                          {...(e2e && {
                            e2e: `${e2e}-group-${groupKey}`,
                          })}
                          colSpan={colSpan}
                          expanded={isItemGroupExpanded}
                          disabled={isPastLowerBound}
                          isSelectable={hasSelection}
                          isIndeterminate={isGroupRowIndeterminate}
                          isSelected={isGroupRowSelected}
                          onCheckboxClick={handleGroupCheckboxClick}
                          groupKey={groupKey}
                          hidden={isPastLowerBound && !isItemGroupExpanded}
                          showGroupedRowsControls={showGroupedRowsControls}
                          onToggleHeaderRow={() => onToggleHeaderRow(groupKey)}
                          onExpandAll={onExpandAll}
                          onCollapseAll={onCollapseAll}
                          resetPagination={resetPagination}
                          hideCheckbox={hideCheckbox}
                          fixedGroupHeader={fixedGroupHeader}
                          hasExpandItem={hasExpandItem}
                        >
                          {groupId.length > 0 && groupId[index] && groupLink ? (
                            <Link href={`${groupLink}${groupId[index]}`}>
                              {groupByRowAsset && groupByRowAsset[groupKey]
                                ? groupByRowAsset[groupKey]
                                : groupKey}
                            </Link>
                          ) : (
                            <>
                              {groupByRowAsset && groupByRowAsset[groupKey]
                                ? groupByRowAsset[groupKey]
                                : groupKey}
                            </>
                          )}
                          {isPastLowerBound &&
                            '  (continued from previous page)'}
                          {groupedRowSelectedCount &&
                            groupSelectedRowsCount > 0 && (
                              <Box
                                component="span"
                                sx={{ marginLeft: theme.spacing(1) }}
                              >
                                <Chip
                                  state="info"
                                  label={`${groupSelectedRowsCount} selected`}
                                />
                              </Box>
                            )}
                        </GroupRow>
                      )}
                      {rowsInView.map((row, rowIndex) => {
                        if (!isItemGroupExpanded) return null;
                        // @ts-ignore TODO fix row indexes
                        if (!!groupKey && row[groupByKey] !== groupKey) {
                          return null;
                        }

                        // For compatibility, if dsRenderMasterDetail exists but dsHasMasterDetail does not,
                        // we assume that all rows have master detail
                        const hasMasterDetail = dsHasMasterDetail
                          ? dsHasMasterDetail({ row })
                          : true;

                        return (
                          <Row
                            index={rowIndex}
                            key={`table-body-row-${groupKey}-${rowIndex}-${row[uniqueKey]}`}
                            {...(e2e && {
                              e2e: `${e2e}-table-body-row-${row[uniqueKey]}`,
                            })}
                            hideBorderForLastRow={!!hidePagination}
                            dense={dense}
                            columns={columns}
                            hasOverflow={hasOverflow}
                            hasOverflowLeft={hasOverflowLeft}
                            highlightColumnMap={highlightColumnMap}
                            colSpan={colSpan}
                            row={row}
                            rows={rows}
                            editableRows={editableRows}
                            nestedRowsMap={nestedRowsMap}
                            editableRow={editableRow}
                            isGlobalEditModeEnabled={isGlobalEditModeEnabled}
                            uniqueKey={uniqueKey}
                            rowActions={rowActions}
                            isEditable={isEditable}
                            isSelected={isSelected}
                            isIndeterminate={isIndeterminate}
                            isSelectable={hasSelection}
                            truncatedCell={truncatedCell}
                            isExpanded={isExpanded}
                            dsRenderMasterDetail={
                              hasMasterDetail ? dsRenderMasterDetail : undefined
                            }
                            dsIsRowSelectable={dsIsRowSelectable}
                            dsIsRowSelectionDisabled={dsIsRowSelectionDisabled}
                            dsIsCellEditable={dsIsCellEditable}
                            onCheckboxClick={handleCheckboxClick}
                            onToggleRowEdit={handleToggleRowEdit}
                            onInputChange={
                              !isGlobalEditModeEnabled
                                ? handleInputChangeSingle
                                : handleInputChangeMultiple
                            }
                            dsCustomRowEditAction={dsCustomRowEditAction}
                            onInputBlur={
                              isGlobalEditModeEnabled
                                ? handleInputBlur
                                : undefined
                            }
                            onToggleRowExpand={onToggleRow}
                            editingColumnRow={editingColumnRow}
                            setEditingColumnRow={
                              dsOnGlobalEditClick ?? setEditingColumnRow
                            }
                            hasExpandItem={hasExpandItem}
                            rowDisplayUtils={rowDisplayUtils}
                            overflowColor={overflowColor}
                          />
                        );
                      })}
                    </React.Fragment>
                  );
                },
              )}
            </TableBody>
          </StyledMuiTable>
        </TableContainer>
        {summaryRow && (
          <SummaryRow
            {...(e2e && {
              e2e: `${e2e}-table-summary-row`,
            })}
          >
            {summaryRow}
          </SummaryRow>
        )}
        {groupedRows.collection.length > 0 && !hidePagination && (
          <TablePagination
            nextIconButtonProps={
              {
                ...(e2e && {
                  'data-e2e': `${e2e}-table-pagination-next-button`,
                }),
                disabled:
                  singlePageLoadingOptions?.isNextPageDisabled ||
                  isNextPageDisabled,
              } as any
            }
            backIconButtonProps={
              {
                ...(e2e && {
                  'data-e2e': `${e2e}-table-pagination-back-button`,
                }),
                ...(singlePageLoadingOptions && {
                  disabled: singlePageLoadingOptions?.isPreviousPageDisabled,
                }),
              } as any
            }
            SelectProps={
              {
                ...(e2e && {
                  'data-e2e': `${e2e}-table-pagination-select`,
                }),
              } as any
            }
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={singlePageLoadingOptions?.totalCount || rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              if (!isSinglePageLoading) {
                let end = to;
                if (
                  to >= groupedRows.collection.length ||
                  to >= visibleNestedElements
                ) {
                  if (to >= visibleNestedElements) {
                    end = visibleNestedElements;
                  } else {
                    end = groupedRows.collection.length;
                  }
                  setIsNextPageDisabled(true);
                } else {
                  setIsNextPageDisabled(false);
                }
                const range = `${from} - ${end}`;
                return hideTotalRows ? range : `${range} | ${count}`;
              } else {
                let end;
                if (groupedRows.collection.length < rowsPerPage) {
                  end = page * rowsPerPage + groupedRows.collection.length;
                } else {
                  end = page * rowsPerPage + rowsPerPage;
                }
                const range = `${from} - ${end}`;
                return hideTotalRows || !singlePageLoadingOptions?.totalCount
                  ? range
                  : `${range} | ${singlePageLoadingOptions?.totalCount}`;
              }
            }}
            labelRowsPerPage={null}
          />
        )}
        {groupedRows.collection.length === 0 &&
        !loading &&
        showNoRowsMessage &&
        CustomNoRecordsComponent
          ? !loading &&
            showNoRowsMessage &&
            !showQueryTimeoutError && (
              <Box
                sx={{
                  padding: theme.spacing(2),
                  textAlign: 'center',
                  maxWidth: '420px',
                  margin: 'auto',
                }}
              >
                <CustomNoRecordsComponent />
                <ExpandSearchComponent />
              </Box>
            )
          : !loading &&
            showNoRowsMessage &&
            !showQueryTimeoutError && (
              <Box
                sx={{
                  padding: theme.spacing(2),
                  textAlign: 'center',
                  maxWidth: '420px',
                  margin: 'auto',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: designTokens.colors.coolGray400 }}
                >
                  {translate('NoRecordsFound')}
                </Typography>
                <ExpandSearchComponent />
              </Box>
            )}
        {groupedRows.collection.length === 0 &&
          !loading &&
          showQueryTimeoutError && <QueryTimeoutComponent />}
      </TableOpacity>
      {loading && (
        <CenteredSpinner>
          <Spinner />
        </CenteredSpinner>
      )}
    </StyledWrapper>
  );
};

export default Table;
