import * as React from 'react';
import {
  Box,
  IconButton,
  TableCell,
  TableCellProps,
  Tooltip,
  useTheme,
} from '@mui/material';
import { ValueType, ColumnType, DataType, TruncatedCellType } from './index';
import { EditableInput } from './EditableInput';
import styled from '@emotion/styled';
import { transientOptions } from '../utils';
import { useOverflow } from 'use-overflow';
import LinesEllipsis from 'react-lines-ellipsis';
import { designTokens } from '../Theme.design-tokens';
import Icon from '../Icon';
import { Theme } from '@mui/system';

interface CellProps {
  /**
   * @deprecated dense should not be used
   */
  dense?: boolean;
  e2e?: string;
  value: ValueType;
  column: ColumnType;
  uniqueKey: string;
  isEditable: boolean;
  uniqueValue: string;
  editableRowUniqueValue?: string;
  align: 'left' | 'right';
  verticalAlign: 'top' | 'middle' | 'bottom' | 'inherit';
  onInputChange: (args: {
    value: ValueType;
    column: ColumnType;
    row: DataType;
  }) => void;
  row: DataType;
  onInputBlur?: (args: {
    column: ColumnType;
    row: DataType;
    editableRow?: DataType;
  }) => void;
  editableRow?: DataType;
  editingColumnRow: string;
  setEditingColumnRow: (string) => void;
  isGlobalEditModeEnabled?: boolean;
  truncatedCell?: TruncatedCellType;
  stickyLeft?: boolean | { backgroundColor: string };
  stickyRight?: boolean | { backgroundColor: string; right: number };
  isExpandCollapseColumn?: boolean;
  hasRowChildren?: boolean;
  onToggleRowExpand?: (uniqueValue: string) => void;
  isItemExpanded?: boolean;
  indentLevel?: number;
  sx?: TableCellProps['sx'];
}

const StyledTableCell = styled(TableCell, transientOptions)<{
  $isGlobalEdit: boolean;
  $isCustomCell: boolean;
  $isEditable: boolean;
  $isEditing: boolean;
  $width?: number;
  $isTruncated?: boolean;
  $stickyLeft?: any;
  $stickyRight?: any;
  $verticalAlign: string;
  $isExpandCollapseColumn?: boolean;
  $indentLevel?: number;
  $theme: Theme;
}>`
  // Min width of 80px
  min-width: 80px;

  //Prevent long strings from overflowing from cell
  word-break: break-word;

  vertical-align: ${({ $verticalAlign }) => $verticalAlign};

  ${({ $width }) =>
    $width
      ? `
    min-width: ${$width}px;
    width: ${$width}px;
    max-width: ${$width}px;
  `
      : ''}

  // Custom-rendered cells are not subject to
  // single-line truncation/ellipsis treatment
  ${({ $isCustomCell, $isTruncated }) =>
    $isCustomCell
      ? ''
      : !$isTruncated &&
        `
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  `}

  ${({ $isTruncated }) =>
    $isTruncated
      ? `
    & p {
      margin: 0;
    }
      `
      : ''}

   ${({ $isGlobalEdit }) =>
    $isGlobalEdit
      ? `
      color: ${designTokens.colors.lightEmphasisMedium};
      `
      : ''}

  ${({ $isEditable }) =>
    $isEditable
      ? `
      cursor: pointer !important;
      color: inherit;
      `
      : ''}

  ${({ $isEditing }) =>
    $isEditing
      ? `
        padding: 0 !important;
      `
      : ''}
      ${({ $stickyLeft }) =>
    $stickyLeft
      ? `
      position: sticky;
      left: 0;
      box-shadow: 4px 0px 8px ${designTokens.colors.lightContextMedium};
      background: ${$stickyLeft.backgroundColor}
      `
      : ''}
  ${({ $stickyRight }) =>
    $stickyRight
      ? `
      position: sticky;
      box-shadow: -4px 0px 8px ${designTokens.colors.lightContextMedium};
      background: ${$stickyRight.backgroundColor};
      right: ${$stickyRight.right}px;
      `
      : ''}

  .caret {
    ${({ $isExpandCollapseColumn, $theme, $indentLevel = 0 }) =>
      $isExpandCollapseColumn
        ? `
        margin-left: ${$theme.spacing($indentLevel * 2)};
        `
        : ''}
  }

  ${({ $isExpandCollapseColumn }) =>
    $isExpandCollapseColumn
      ? `
       white-space: nowrap;
        `
      : ''}
`;

export const Cell = React.memo(
  ({
    e2e,
    value,
    column,
    uniqueKey,
    uniqueValue,
    isEditable,
    onInputChange,
    align,
    verticalAlign,
    row,
    onInputBlur,
    editableRow,
    editingColumnRow,
    setEditingColumnRow,
    isGlobalEditModeEnabled,
    truncatedCell,
    stickyLeft,
    stickyRight,
    isExpandCollapseColumn,
    hasRowChildren,
    onToggleRowExpand,
    isItemExpanded,
    indentLevel,
    sx,
  }: CellProps) => {
    const theme = useTheme();

    const cellRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [clamped, setClamped] = React.useState<boolean>(true);

    const { refXOverflowing } = useOverflow(cellRef);

    const isFocused = cellRef.current === document.activeElement;

    React.useEffect(() => {
      if (isFocused) inputRef.current?.focus();
    }, [inputRef, isFocused]);

    if (column.hidden || Array.isArray(value)) return null;

    const columnRowId = `column-${column.field}:row-${row.id}`;

    const isEditing = columnRowId === editingColumnRow;

    const renderEditableOutput = isGlobalEditModeEnabled
      ? isEditing
      : isEditable;

    let output;

    if (column.dsRenderCell) {
      output = column.dsRenderCell({
        rowId: uniqueValue,
        row,
        column,
        value,
      });
    } else if (column.field === uniqueKey) {
      output = value;
    } else {
      output = String(value);
    }

    if (column?.type === 'password') {
      output = '*'.repeat(String(value).length);
    }

    const handleEditable = () => {
      if (isEditable && !isEditing) {
        setEditingColumnRow(columnRowId);
      }
    };

    const editableOutput = (
      <EditableInput
        ref={inputRef}
        e2e={e2e}
        column={column}
        type={column?.type || 'string'}
        dateFormat={column?.dateFormat}
        minDate={column?.minDate}
        maxDate={column?.maxDate}
        picklistOptions={column?.picklistOptions}
        value={value}
        picklistUniqueKey={uniqueKey}
        onChange={(value) => onInputChange({ value, column, row })}
        onBlur={() => onInputBlur?.({ column, row, editableRow })}
        onEditComplete={() => setEditingColumnRow('')}
        multiline={column?.multiline}
        maxRows={column?.maxRows}
      />
    );

    const shouldShowTooltip = !column.dsRenderCell && refXOverflowing;

    const cellCaret =
      !renderEditableOutput &&
      isExpandCollapseColumn &&
      (hasRowChildren ? (
        <IconButton
          {...(e2e && {
            'data-e2e': `${e2e}-expandCollapseAction`,
          })}
          color="primary"
          size="small"
          className="caret"
          onClick={(e) => {
            e.stopPropagation();
            !!onToggleRowExpand && onToggleRowExpand(uniqueValue);
          }}
          onFocus={(e) => e.stopPropagation()}
          style={{
            maxWidth: theme.spacing(2.5),
            maxHeight: theme.spacing(2.5),
            transform: `rotate(${isItemExpanded ? '90deg' : '0deg'})`,
            transition: 'transform 0.1s linear',
          }}
        >
          <Icon body="arrow_right" />
        </IconButton>
      ) : (
        <Box
          sx={{
            display: 'inline-flex',
            verticalAlign: 'middle',
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
          }}
          className="caret"
        />
      ));

    const cellOutput = renderEditableOutput ? (
      editableOutput
    ) : truncatedCell?.truncated ? (
      <LinesEllipsis
        text={output as string}
        maxLine={2}
        component="p"
        onReflow={(state) => setClamped(state.clamped)}
        basedOn="letters"
        style={{
          ...(isExpandCollapseColumn && { display: 'inline-block' }),
        }}
      />
    ) : (
      output
    );

    const innerCell = (
      <StyledTableCell
        $isGlobalEdit={!!isGlobalEditModeEnabled}
        $isEditable={isEditable}
        $isEditing={renderEditableOutput}
        $isCustomCell={Boolean(column.dsRenderCell)}
        $isTruncated={truncatedCell?.truncated}
        data-column={column.field}
        {...(e2e && { 'data-e2e': e2e })}
        ref={cellRef}
        $width={truncatedCell?.minWidth || column.width}
        align={align}
        $verticalAlign={verticalAlign}
        onClick={handleEditable}
        onFocus={handleEditable}
        $stickyLeft={column.stickyPosition === 'left' && stickyLeft}
        $stickyRight={column.stickyPosition === 'right' && stickyRight}
        {...(isEditable && { tabIndex: isFocused ? -1 : 0 })}
        $indentLevel={indentLevel}
        $isExpandCollapseColumn={isExpandCollapseColumn}
        $theme={theme}
        sx={sx}
      >
        {cellCaret ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cellCaret}
            {cellOutput}
          </Box>
        ) : (
          cellOutput
        )}
      </StyledTableCell>
    );

    if ((truncatedCell?.truncated && clamped) || shouldShowTooltip) {
      return (
        <Tooltip
          arrow
          {...(truncatedCell?.truncated && {
            disableHoverListener: !clamped,
            disableTouchListener: !clamped,
            placement: 'right',
          })}
          title={output as string}
          PopperProps={{
            ...(e2e && { 'data-e2e': `${e2e}-tooltip` }),
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8],
                },
              },
            ],
          }}
        >
          {innerCell}
        </Tooltip>
      );
    }

    return innerCell;
  },
  (prevProps, nextProps) => {
    if (prevProps.uniqueValue === nextProps.editableRowUniqueValue) {
      return false;
    }

    if (prevProps?.column?.dsRenderCell !== nextProps?.column?.dsRenderCell) {
      return false;
    }
    if (
      prevProps?.stickyLeft !== nextProps?.stickyLeft ||
      prevProps?.stickyRight !== nextProps?.stickyRight
    ) {
      return false;
    }
    if (prevProps?.sx !== nextProps.sx) {
      return false;
    }

    return (
      prevProps.value === nextProps.value &&
      prevProps.isEditable === nextProps.isEditable &&
      prevProps.isItemExpanded === prevProps.isItemExpanded &&
      prevProps.onToggleRowExpand === nextProps.onToggleRowExpand
    );
  },
);
