import * as React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MUICheckboxProps,
} from '@mui/material';

export interface CheckboxProps
  extends Pick<
    MUICheckboxProps,
    | 'checked'
    | 'name'
    | 'value'
    | 'disabled'
    | 'required'
    | 'indeterminate'
    | 'size'
  > {
  dsOnChange: MUICheckboxProps['onChange'];
  e2e?: string;
  label?: React.ReactNode;
  margin?: 'none' | 'normal';
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      disabled,
      dsOnChange,
      e2e,
      required,
      indeterminate,
      label,
      checked,
      name,
      value,
      margin = 'normal',
      size,
    },
    ref,
  ) => {
    const noMargin = margin === 'none';

    const checkbox = (
      // @ts-ignore
      <MuiCheckbox
        inputRef={ref}
        sx={{ ...(noMargin && { padding: '0 5px 0 0 ' }) }}
        disabled={disabled}
        {...(e2e && { 'data-e2e': e2e })}
        indeterminate={indeterminate}
        required={required}
        checked={!!checked}
        name={name}
        onChange={dsOnChange}
        value={value}
        color="primary"
        disableRipple={noMargin}
        size={size}
        {...(e2e && { inputProps: { 'data-e2e': `${e2e}-input` } })}
      />
    );

    if (label) {
      return (
        <FormControl required={required}>
          <FormControlLabel
            sx={{ ...(noMargin && { marginLeft: 0 }) }}
            disabled={disabled}
            label={
              <div>
                {label}
                <FormLabel />
              </div>
            }
            control={checkbox}
          />
        </FormControl>
      );
    }

    return checkbox;
  },
);

export default Checkbox;
