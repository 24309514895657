import React from 'react';
import { DateTime } from 'luxon';
import {
  FilterableField,
  Filter,
  FilterCondition,
  FilterRelation,
  FilterBarOptions,
} from '..';
import {
  Operator,
  FilterDataTypes,
  FilterFields,
  VanityFilterField,
  QuickFilterField,
  FilterField,
  InternalOperator,
  Option,
} from '../types';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';
import { SEARCH_KEY } from './components/SearchFilterComponent';
import { omitDeepFilter } from '../utils';

export class FilterBarFactory {
  static textModeDefault = InternalOperator.STARTSWITH;

  static operatorLabelMap = {
    [Operator.Eq]: 'FilterBar_ExactMatch',
    [Operator.Ne]: 'FilterBar_DoesNotMatch',
    [Operator.Lt]: 'FilterBar_LessThan',
    [Operator.Le]: 'FilterBar_LessThanOrEqual',
    [Operator.Gt]: 'FilterBar_GreaterThan',
    [Operator.Ge]: 'FilterBar_GreaterThanOrEqual',
    [Operator.Sw]: 'FilterBar_Contains',
  };

  static operatorCharacterMap = {
    [Operator.Eq]: '=',
    [Operator.Ne]: '<>',
    [Operator.Lt]: '<',
    [Operator.Le]: '<=',
    [Operator.Gt]: '>',
    [Operator.Ge]: '>=',
    [Operator.Sw]: '~',
  };

  static componentOperatorMap = {
    [FilterDataTypes.STRING]: [Operator.Eq, Operator.Sw],
    [FilterDataTypes.DATE]: [
      Operator.Eq,
      Operator.Ge,
      Operator.Le,
      Operator.Ne,
    ],
    [FilterDataTypes.TIMESTAMP]: [
      Operator.Eq,
      Operator.Ge,
      Operator.Le,
      Operator.Ne,
    ],
    [FilterDataTypes.DECIMAL]: [Operator.Eq, Operator.Gt, Operator.Lt],
    [FilterDataTypes.INTEGER]: [Operator.Eq, Operator.Gt, Operator.Lt],
    [FilterDataTypes.BIGINT]: [Operator.Eq, Operator.Gt, Operator.Lt],
    [FilterDataTypes.BOOLEAN]: [Operator.Eq],
  };

  static favoredOperators = {
    [FilterDataTypes.STRING]: Operator.Sw,
    [FilterDataTypes.DECIMAL]: Operator.Eq,
    [FilterDataTypes.INTEGER]: Operator.Eq,
    [FilterDataTypes.BIGINT]: Operator.Eq,
    [FilterDataTypes.BOOLEAN]: Operator.Eq,
  };

  static internalOperatorToGqlOperatorMap = {
    [InternalOperator.EQUALS]: Operator.Eq,
    [InternalOperator.STARTSWITH]: Operator.Sw,
    [InternalOperator.CONTAINS]: Operator.Sw,
  };

  static getFavoredOperator = (
    type: FilterDataTypes,
    internalOperator?: InternalOperator,
  ): Operator => {
    if (type === FilterDataTypes.STRING && internalOperator) {
      return FilterBarFactory.internalOperatorToGqlOperatorMap[
        internalOperator
      ];
    } else {
      return FilterBarFactory.favoredOperators[type];
    }
  };

  // Determines Filter Chip display order
  static getFilterChipIterable = (
    fields: FilterFields,
  ): [string, FilterField][] => {
    const sortABC = (
      segment: [string, FilterField][],
    ): [string, FilterField][] =>
      segment.sort(
        (
          [, aValue]: [string, FilterField],
          [, bValue]: [string, FilterField],
        ) => {
          try {
            return (aValue.label || aValue.dataKey)?.localeCompare(
              bValue.label || bValue.dataKey,
            );
          } catch {
            return 0;
          }
        },
      );
    const segmentMap = Object.entries(fields).reduce(
      (acc, next) => {
        if (next[1].default) {
          (acc.default as [string, FilterField][]).push(next);
        } else if (next[1].type === FilterDataTypes.VANITY) {
          (acc.vanity as [string, FilterField][]).push(next);
        } else {
          (acc.userAdded as [string, FilterField][]).push(next);
        }
        return acc;
      },
      { default: [], vanity: [], userAdded: [] },
    );

    return [
      ...sortABC(segmentMap.default),
      ...sortABC(segmentMap.userAdded),
      ...sortABC(segmentMap.vanity),
    ];
  };

  static getFilterChipLabel = (
    fields,
    filterableFields,
    key,
    translate,
    isTooltip = false,
    cachedSelections,
  ) => {
    const matchedFilterableField = filterableFields.find(
      ({ name }) => name === fields[key].dataKey,
    );

    const filterType = fields[key].type || matchedFilterableField?.type;
    const filterLabel =
      fields[key].label || matchedFilterableField?.label || fields[key].dataKey;
    const hasRelCallback =
      fields[key].hasOwnProperty('relationshipQueryCallback') ||
      matchedFilterableField?.hasOwnProperty('relationshipQueryCallback');
    const operator =
      FilterBarFactory.operatorCharacterMap[fields[key].operator];
    const value = fields[key].value;
    const options = fields[key]?.options;
    const dateFormatter = (dateValue: DateTime) =>
      dateValue.toFormat('yyyy-MM-dd');

    if (isTooltip) {
      const pickListChosenOpts = filterableFields
        .find((field) => field.name === fields[key]?.dataKey)
        ?.picklistOptions?.filter((opt) => value.includes(opt.value))
        .map(({ label }) => label)
        .join(', ');

      const stringChosenOpts = (cachedSelections[key] || options)
        ?.filter((opt) => value.includes(opt.value))
        .map(({ label }) => label)
        .join(', ');

      if (value.length < 1) {
        return null;
      }

      return stringChosenOpts || pickListChosenOpts;
    }

    if (filterType === FilterDataTypes.VANITY) {
      return `${filterLabel}: ${
        fields[key].value
          ? translate('FilterBar_Yes')
          : translate('FilterBar_No')
      }`;
    }

    if (!fields[key].value || fields[key].value?.length < 1) {
      return filterLabel;
    }
    switch (filterType) {
      case FilterDataTypes.STRING:
        if (hasRelCallback && Array.isArray(value)) {
          const fieldsOpt = options?.filter((field) =>
            value.includes(field.value),
          );
          const chosenOpt = (cachedSelections[key] || fieldsOpt)
            ?.map(({ label }) => label)
            .sort();

          return value.length > 1 ? (
            FilterBarFactory.combineValues(filterLabel, value, chosenOpt)
          ) : (
            <div className="filterValue">{`${filterLabel}: ${chosenOpt[0]}`}</div>
          );
        }
        return `${filterLabel}: ${value}`;
      case FilterDataTypes.BOOLEAN:
        return `${filterLabel}: ${
          value === 'true'
            ? translate('FilterBar_Yes')
            : translate('FilterBar_No')
        }`;
      case FilterDataTypes.PICKLIST:
      case FilterDataTypes.BOOLEANPICKLIST:
        if (value.length < 1) {
          return filterLabel;
        }
        const chosenOpts = filterableFields
          .find(
            (field) =>
              field.name?.toLowerCase() === fields[key]?.dataKey?.toLowerCase(),
          )
          ?.picklistOptions?.filter((opt) => value.includes(opt.value))
          .map(({ label }) => label);
        return Array.isArray(value) && value.length > 1
          ? FilterBarFactory.combineValues(filterLabel, value, chosenOpts)
          : `${filterLabel}: ${chosenOpts?.[0]}`;
      case FilterDataTypes.BIGINT:
      case FilterDataTypes.INTEGER:
      case FilterDataTypes.DECIMAL:
        return `${filterLabel}: ${operator}${value}`;
      case FilterDataTypes.TIMESTAMP:
      case FilterDataTypes.DATE:
        if (DateTime.isDateTime(value)) {
          return `${filterLabel}: ${operator}${dateFormatter(value)}`;
        } else if (value[Operator.Ge] && value[Operator.Le]) {
          return `${filterLabel}: ${dateFormatter(
            value[Operator.Ge],
          )} - ${dateFormatter(value[Operator.Le])}`;
        } else if (value[Operator.Ge]) {
          return `${filterLabel}: ${
            FilterBarFactory.operatorCharacterMap[Operator.Ge]
          }${dateFormatter(value[Operator.Ge])}`;
        } else if (value[Operator.Le]) {
          return `${filterLabel}: ${
            FilterBarFactory.operatorCharacterMap[Operator.Le]
          }${dateFormatter(value[Operator.Le])}`;
        }
      default:
        return `${filterLabel}: ${operator}${value}`;
    }
  };

  static combineValues = (filterLabel, value, chosenOpts = []) => (
    <>
      <div className="filterValueWrapper">
        {`${filterLabel}:`}
        <div className="filterValue filterMultipleValues">
          {chosenOpts[0] || value[0] || null}
        </div>
      </div>
      <div className="numberOfFilterValues numberOfFilterValuesWithValue">
        +{value.length - 1}
      </div>
    </>
  );

  static formatInitialValue = (type, value = '', searchWildcard = '%') => {
    switch (type) {
      case FilterDataTypes.TIMESTAMP:
      case FilterDataTypes.DATE:
        return value ? DateTime.fromISO(value) : undefined;
      case FilterDataTypes.STRING:
        return value.split(searchWildcard).join('');
      case FilterDataTypes.PICKLIST:
        return Array.isArray(value) ? value : [value].filter(Boolean);
      default:
        return value;
    }
  };

  static formatValueForHawk = (
    value,
    type: FilterDataTypes,
    operator?: Operator,
    internalOperator?: InternalOperator,
    searchWildcard?: string,
  ) => {
    if (!value) {
      return null;
    }

    if (DateTime.isDateTime(value)) {
      return type === FilterDataTypes.DATE
        ? value.toFormat('yyyy-MM-dd')
        : value.startOf('day').toJSON();
    } else if (type === FilterDataTypes.STRING && operator === Operator.Sw) {
      return internalOperator === InternalOperator.CONTAINS
        ? `${searchWildcard}${value.trim()}${searchWildcard}`
        : `${value.trim()}`;
    } else if (type === FilterDataTypes.BOOLEAN) {
      return value;
    } else {
      return value?.trim();
    }
  };

  static createInclusiveCondition = (fields, key, searchWildcard) => {
    const valuesArray = Array.isArray(fields[key].value)
      ? fields[key].value
      : [fields[key].value];
    const condition = {
      fieldId: key,
      relation: 'OR',
      conditions: valuesArray.filter(Boolean).map((value) => ({
        operator: Operator.Eq,
        value: FilterBarFactory.formatValueForHawk(
          value,
          fields[key].type,
          fields[key].operator,
          fields[key].internalOperator,
          searchWildcard,
        ),
        field: fields[key].dataKey,
      })),
    };

    if (
      condition.conditions.map((cond) => cond.value).filter(Boolean).length > 0
    ) {
      return condition;
    }
  };

  static createSearchCondition = (fields, key, searchWildcard) => {
    const field = fields[key];
    const condition = {
      fieldId: key,
      relation: 'OR',
      conditions: field?.dataKey?.filter(Boolean).map((queryField) => ({
        operator:
          fields[key].internalOperator === InternalOperator.EQUALS
            ? Operator.Eq
            : Operator.Sw,
        value: FilterBarFactory.formatValueForHawk(
          field.value,
          fields[key].type,
          Operator.Sw,
          fields[key].internalOperator,
          searchWildcard,
        ),
        field: queryField,
      })),
    };

    if (
      condition.conditions.map((cond) => cond.value).filter(Boolean).length > 0
    ) {
      return condition;
    }
  };

  static createCompoundCondition = (fields, key, searchWildcard) => {
    const condition = {
      fieldId: key,
      relation: 'AND',
      conditions: Object.entries(fields[key].value)
        .filter(([, value]) => !!value)
        .map(([operator, value]) => ({
          operator,
          value: FilterBarFactory.formatValueForHawk(
            value,
            fields[key].type,
            fields[key].operator,
            fields[key].internalOperator,
            searchWildcard,
          ),
          field: fields[key].dataKey,
        })),
    };

    if (
      condition.conditions.map((cond) => cond.value).filter(Boolean).length > 0
    ) {
      return condition;
    }
  };

  static createSingleCondition = (fields, key, searchWildcard) => {
    const field = fields[key];

    return {
      fieldId: key,
      operator:
        FilterBarFactory.internalOperatorToGqlOperatorMap[
          field.internalOperator
        ] ?? field.operator,
      value: FilterBarFactory.formatValueForHawk(
        field.value,
        field.type,
        field.operator,
        field.internalOperator,
        searchWildcard,
      ),
      field: field.dataKey,
    };
  };

  static filterFromFields = (fields, searchWildcard) => {
    const filter = {
      relation: 'AND',
      conditions: Object.entries(fields)
        ?.map(([key, value]) => {
          const field = fields[key];
          // Vanity
          if (
            (value as VanityFilterField)?.id &&
            !!(value as VanityFilterField)?.value &&
            (value as VanityFilterField)?.type === FilterDataTypes.VANITY
          ) {
            return (value as VanityFilterField).filter;
          }

          if (field.value || field?.length) {
            // Search
            if (
              field.operator === Operator.In &&
              field.type === FilterDataTypes.STRING &&
              !Array.isArray(field.value)
            ) {
              return FilterBarFactory.createSearchCondition(
                fields,
                key,
                searchWildcard,
              );
            }

            // Chips
            if (field.operator === Operator.In || Array.isArray(field.value)) {
              return FilterBarFactory.createInclusiveCondition(
                fields,
                key,
                searchWildcard,
              );
            } else if (
              typeof field.value === 'object' &&
              !DateTime.isDateTime(field.value)
            ) {
              return FilterBarFactory.createCompoundCondition(
                fields,
                key,
                searchWildcard,
              );
            } else {
              return FilterBarFactory.createSingleCondition(
                fields,
                key,
                searchWildcard,
              );
            }
          }
        })
        .filter(Boolean),
    };

    return filter.conditions?.length ? filter : {};
  };

  static getOptionData = async (fields, relationshipQueryCallback) => {
    const optionsData = await relationshipQueryCallback('', fields);

    return optionsData;
  };

  static createSingleField = ({
    filterCondition: curr,
    representedDataKeys,
    filterableFields,
    searchWildcard,
  }) => {
    const dataKey =
      ((curr as Filter).conditions?.[0] as FilterCondition)?.field ||
      (curr as FilterCondition).field;
    representedDataKeys.push(dataKey);
    const filterableFieldMatch = filterableFields.find(
      (f) => f.name === dataKey,
    );
    const type = filterableFieldMatch?.type;
    const label = filterableFieldMatch?.label;
    let operator: string | undefined = (curr as FilterCondition).operator;
    let value;
    let internalOperator;
    const options: Option[] | undefined = [];

    if (
      type === FilterDataTypes.STRING &&
      (curr as FilterCondition).operator === Operator.Eq &&
      !curr.conditions &&
      Array.isArray(curr.value)
    ) {
      value = FilterBarFactory.convertNestedConditionsToFieldValue(
        { ...curr, conditions: curr.conditions ?? [{ ...curr }] },
        type,
        searchWildcard,
      );
      operator = undefined;
    } else {
      value = Array.isArray((curr as Filter).conditions)
        ? FilterBarFactory.convertNestedConditionsToFieldValue(
            curr,
            type,
            searchWildcard,
          )
        : FilterBarFactory.formatInitialValue(
            type,
            (curr as FilterCondition).value,
            searchWildcard,
          );
    }

    if (filterableFieldMatch?.relationshipQueryCallback) {
      FilterBarFactory.getOptionData(
        curr,
        filterableFieldMatch?.relationshipQueryCallback,
      ).then(
        (res) =>
          res &&
          Array.isArray(res) &&
          res.forEach((item) => options.push(item)),
      );
    }

    if (type === FilterDataTypes.STRING) {
      internalOperator =
        (curr as FilterCondition).operator === Operator.Eq
          ? InternalOperator.EQUALS
          : JSON.stringify(curr)?.includes(searchWildcard)
          ? InternalOperator.CONTAINS
          : InternalOperator.STARTSWITH;
    }
    return {
      internalOperator,
      operator, // Is undefined for nested filters
      value,
      type,
      dataKey,
      label,
      options,
    };
  };

  static createSearchField = ({ filterCondition: curr, searchWildcard }) => {
    const dataKey = curr.conditions.map((cond) => cond.field);

    const type = FilterDataTypes.STRING;

    const internalOperator = (() => {
      if (curr.conditions[0]?.value?.includes(searchWildcard)) {
        return InternalOperator.CONTAINS;
      } else if (curr.conditions[0]?.operator === Operator.Eq) {
        return InternalOperator.EQUALS;
      } else {
        return InternalOperator.STARTSWITH;
      }
    })();

    const includeCustomFields = dataKey.find((fieldName) =>
      fieldName.includes('__c'),
    );
    return {
      internalOperator,
      operator: Operator.In,
      type: type,
      value: FilterBarFactory.formatInitialValue(
        type,
        curr.conditions[0]?.value,
        searchWildcard,
      ),
      dataKey,
      includeCustomFields: !!includeCustomFields,
    };
  };

  static isSearchCondition = (
    filterCondition: FilterCondition | Filter,
    filterableFields?: FilterableField[],
  ) => {
    const isSameValue = (cond: FilterCondition | Filter) => {
      const firstFilterValue = (
        (filterCondition as Filter).conditions[0] as FilterCondition
      ).value;

      return (cond as FilterCondition).value === firstFilterValue;
    };

    const isSingleSearchCondition = () => {
      if ((filterCondition as Filter)?.conditions?.length === 1) {
        const { field } = (filterCondition as Filter)
          .conditions[0] as FilterCondition;

        const isPicklist = filterableFields?.find(
          ({ name, type, relationshipQueryCallback }) =>
            name === field &&
            (type === FilterDataTypes.PICKLIST ||
              (type === FilterDataTypes.STRING && !!relationshipQueryCallback)),
        );

        return isPicklist === undefined;
      }
    };

    return (
      (filterCondition as Filter).relation === FilterRelation.OR &&
      (((filterCondition as Filter)?.conditions?.length > 1 &&
        (filterCondition as Filter)?.conditions.every(isSameValue)) ||
        isSingleSearchCondition())
    );
  };

  static filterToFields = (
    filterableFields: FilterableField[],
    initialFilter?: Filter,
    defaultFields?: string[],
    vanityFilters?: VanityFilterField[],
    quickFilter?: QuickFilterField,
    filterBarOptions?: FilterBarOptions,
    searchWildcard?: string,
  ): FilterFields => {
    const representedDataKeys: string[] = [];
    const representedVanityFilters: { vanityFilterId; fieldId }[] = [];
    let newFields = {};

    if (initialFilter || quickFilter) {
      if (quickFilter) {
        quickFilter = {
          ...quickFilter,
          ...(quickFilter.filter && {
            filter: {
              ...quickFilter.filter,
              conditions: quickFilter?.filter?.conditions?.map((cond) => ({
                ...cond,
                fieldId: uuid(),
              })),
            },
          }),
        };
      }

      initialFilter = quickFilter?.filter || (initialFilter as Filter);
      if (initialFilter?.conditions) {
        newFields = initialFilter?.conditions?.reduce((acc, curr) => {
          const vanityFilterMatch = vanityFilters?.find((vf) => {
            const cleanedFilter = omitDeepFilter(curr, ['fieldId']);
            return isEqual(vf.filter, cleanedFilter);
          });

          if (vanityFilterMatch) {
            representedVanityFilters.push({
              vanityFilterId: vanityFilterMatch.id,
              fieldId: curr.fieldId,
            });
            return acc;
          }

          if (FilterBarFactory.isSearchCondition(curr, filterableFields)) {
            acc[SEARCH_KEY] = FilterBarFactory.createSearchField({
              filterCondition: curr,
              searchWildcard,
            });
          } else {
            acc[curr.fieldId!] = FilterBarFactory.createSingleField({
              filterCondition: curr,
              filterableFields,
              representedDataKeys,
              searchWildcard,
            });
          }
          return acc;
        }, {});
      }
    }
    if (Array.isArray(defaultFields)) {
      defaultFields.forEach((key) => {
        if (representedDataKeys.indexOf(key) > -1) {
          const [fieldKey] =
            Object.entries(newFields).find(
              ([, fieldValue]) => (fieldValue as FilterField)?.dataKey === key,
            ) || [];
          if (fieldKey) {
            newFields[fieldKey].default = true;
          }

          return;
        }

        const filterField = filterableFields.find(({ name }) => name === key);
        if (filterField) {
          const options: Option[] | undefined = [];

          if (filterField?.relationshipQueryCallback) {
            FilterBarFactory.getOptionData(
              filterableFields,
              filterField?.relationshipQueryCallback,
            ).then(
              (res) =>
                res &&
                Array.isArray(res) &&
                res.forEach((item) => options.push(item)),
            );
          }

          const internalOperator = FilterBarFactory.getInternalOperator(
            filterField.type as FilterDataTypes,
            filterBarOptions,
          );

          const newField = {
            dataKey: filterField.name,
            label: filterField.label,
            operator: FilterBarFactory.getFavoredOperator(
              filterField.type as FilterDataTypes,
              internalOperator,
            ),
            type: filterField.type,
            value: FilterBarFactory.formatInitialValue(
              filterField.type,
              '',
              searchWildcard,
            ),
            default: true,
            internalOperator,
            options,
          };
          newFields[uuid()] = newField;
        }
      });
    }
    if (Array.isArray(vanityFilters)) {
      vanityFilters.forEach((vf) => {
        const represented = representedVanityFilters.find(
          ({ vanityFilterId }) => vanityFilterId === vf.id,
        );
        const newField = { ...vf };
        newField.value = !!represented || newField.value;
        newFields[represented?.fieldId || uuid()] = newField;
      });
    }
    return newFields;
  };

  static convertNestedConditionsToFieldValue = (
    initialFilterMatch,
    type,
    searchWildcard,
  ) => {
    return type === FilterDataTypes.PICKLIST || type === FilterDataTypes.STRING
      ? initialFilterMatch.conditions.map(({ value }) => value)
      : (initialFilterMatch as Filter).conditions.reduce((accu, next) => {
          accu[(next as FilterCondition).operator] =
            FilterBarFactory.formatInitialValue(
              type,
              (next as FilterCondition).value,
              searchWildcard,
            );
          return accu;
        }, {});
  };

  static keyToField = (
    key: string,
    filterableFields: FilterableField[],
    filterBarOptions?: FilterBarOptions,
  ) => {
    const field = filterableFields.find((field) => field.name === key);

    if (!field) {
      return;
    }

    const internalOperator = FilterBarFactory.getInternalOperator(
      field.type as FilterDataTypes,
      filterBarOptions,
    );

    return {
      internalOperator,
      dataKey: key,
      operator: FilterBarFactory.getFavoredOperator(
        field.type as FilterDataTypes,
        internalOperator,
      ),
      value: '',
      label: field.label,
      type: field.type,
    };
  };

  static getInternalOperator = (
    dataType: FilterDataTypes,
    filterBarOptions?: FilterBarOptions,
  ) => {
    return dataType === FilterDataTypes.STRING
      ? filterBarOptions?.defaultTextOperator ||
          FilterBarFactory.textModeDefault
      : undefined;
  };

  static getPicklistLabel = (
    condition: FilterCondition,
    filterableFields?: FilterableField[],
  ) => {
    const picklistOptions = filterableFields?.find(
      (field) => field.name == condition.field,
    )?.picklistOptions;

    const chosenOption = picklistOptions?.find(
      // @ts-ignore
      (opt) => opt === condition.value || opt.value === condition.value,
    );

    // @ts-ignore
    return chosenOption?.label || chosenOption || condition.value;
  };

  static getMenuOptions = (defaultFields, fields, filter, filterableFields) => {
    // fetch any default fields and vanity fields
    const defaultFilteredFields = Object.entries(fields)
      ?.filter(([key]) => key !== SEARCH_KEY)
      .map((appliedFilter) => {
        const defaultFieldObj = appliedFilter[1] as FilterField;
        if (defaultFieldObj.type === FilterDataTypes.VANITY) {
          const vanityFilt = defaultFieldObj['filter']['conditions'];
          return vanityFilt.map((condition) => {
            return condition.field;
          });
        } else {
          return defaultFieldObj.dataKey;
        }
      });

    const uniqueFilteredFields = [
      ...new Set(defaultFilteredFields.flat().concat(defaultFields || [])),
    ];

    // check if any filters have been applied
    if (filter && filter.conditions) {
      // fetch fields that have filters applied to them, not including search
      const filterIds = Object.entries(fields)
        ?.filter(([key]) => key !== SEARCH_KEY)
        .map((appliedFilter) => {
          return appliedFilter[0];
        });

      const filteredFields = filter.conditions
        .filter((condition) => filterIds.includes(condition.fieldId as string))
        .map((filter) => {
          return typeof filter['conditions'] !== 'undefined'
            ? filter['conditions'][0]['field']
            : filter['field'];
        });

      // remove duplicate field names
      uniqueFilteredFields.concat([
        ...new Set(
          filteredFields.filter(
            (fieldName) => !uniqueFilteredFields.includes(fieldName),
          ),
        ),
      ]);
    }

    // filter out the fields that already have filters on them and/or have
    // (a default filter and/or vanity filter) involving them
    const unusedFilterOptions = filterableFields
      .filter(
        (filterableField) =>
          !uniqueFilteredFields.includes(filterableField.name),
      )
      .sort((a, b) => (a.label || a.name)?.localeCompare(b.label || b.name));

    return unusedFilterOptions;
  };
}
