import React from 'react';
import styled from '@emotion/styled';
import { FilterableField } from '../..';
import { FilterDataTypes, FilterFields } from '../../types';
import { DateFilterComponent } from './DateFilterComponent';
import { TimestampHandlerComponentFactory } from './TimestampHandlerComponentFactory';
import { PicklistFilterComponent } from './PicklistFilterComponent';
import { TextFilterComponent } from './TextFilterComponent';
import { LegacyFilterFactory } from '../utils';

export interface FilterComponentProps {
  stateKey: string;
  state: FilterFields;
  stateChangeFn: (value, blurred?: boolean) => void;
  operators?: string[];
  dsComponentProps?: any;
  filterableField?: FilterableField;
  e2e?: string;
}

interface FilterComponentFactoryProps extends FilterComponentProps {
  type: FilterDataTypes;
}

export const FilterRow = styled('div')`
  display: flex;
  margin-bottom: 1rem;

  > div:first-of-type {
    flex: 55%;
    margin-right: 1rem;
  }
  > div:last-of-type {
    flex: 45%;
  }
`;

export const FilterComponentFactory = ({
  type,
  ...props
}: FilterComponentFactoryProps) => {
  const componentProps = {
    ...props,
    operators: LegacyFilterFactory.componentOperatorMap[type],
  };

  const baseDsComponentProps = {
    e2e: props.e2e
      ? `${props.e2e}-${props.stateKey}-filter-field`
      : `${props.stateKey}-filter-field`,
  };

  switch (type) {
    case FilterDataTypes.PICKLIST:
      return (
        <PicklistFilterComponent
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.BIGINT:
    case FilterDataTypes.DECIMAL:
      return (
        <TextFilterComponent
          {...componentProps}
          dsComponentProps={{ type: 'number', ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.DATE:
      return (
        <DateFilterComponent
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.TIMESTAMP:
      return (
        <TimestampHandlerComponentFactory
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
    case FilterDataTypes.STRING:
    default:
      return (
        <TextFilterComponent
          {...componentProps}
          dsComponentProps={{ ...baseDsComponentProps }}
        />
      );
  }
};
