import * as React from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButtonProps as MuiIconButtonProps,
  useTheme,
} from '@mui/material';
import { AllIconsType } from '../../index';
import Icon from '../../Icon';
import { designTokens } from '../../Theme.design-tokens';

type Variant = MuiButtonProps['variant'] | 'tertiary';

export interface ButtonProps
  extends Partial<
    Pick<MuiButtonProps, 'disabled' | 'fullWidth' | 'href' | 'type'>
  > {
  /**
   * If button invokes a dropdown, set to id of dropdown DOM node.
   */
  a11yControls?: MuiButtonProps['aria-controls'];
  /**
   * If button invokes a popover, set to id of popover DOM node.
   */
  a11yDescribedby?: MuiButtonProps['aria-describedby'];
  /**
   * if button invokes a dropdown, set to 'true'.
   */
  a11yHaspopup?: MuiButtonProps['aria-haspopup'];
  /**
   * Accessibility label (needed for Icon Button only)
   */
  a11yLabel?: string;
  buttonRef?: MuiButtonProps['ref'];
  body?: React.ReactNode;
  children?: React.ReactNode;
  dsOnClick?: MuiIconButtonProps['onClick'] | MuiButtonProps['onClick'];
  dsOnDelete?: MuiIconButtonProps['onClick'] | MuiButtonProps['onClick'];
  e2e?: string;
  startIcon?: AllIconsType;
  endIcon?: AllIconsType;
  icon?: AllIconsType;
  size?: 'small' | 'medium';
  tooltip?: string;
  variant?: Variant | string;
  state?:
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'indeterminate'
    | 'default';
}

export interface StyleProps {
  state?: ButtonProps['state'];
  dsOnClick?: MuiButtonProps['onClick'];
}

const ButtonFilter: React.FC<ButtonProps> = ({
  a11yControls,
  a11yDescribedby,
  a11yHaspopup,
  body,
  state = 'default',
  buttonRef,
  children,
  dsOnClick,
  dsOnDelete,
  e2e,
  fullWidth,
  disabled,
  href,
  startIcon,
  endIcon,
  size,
  type,
}) => {
  const theme = useTheme();

  return (
    <MuiButton
      sx={{
        ...(theme.density !== 'condensed' && {
          height: '36px',
        }),
        padding: '7px 12px',
        '& div': {
          padding: 0,
        },
        '& .filterValueWrapper': {
          display: 'flex',
          alignItems: 'center',
          maxWidth: 'calc(100% - 26px)',
          overflow: 'hidden',
        },
        '& .filterValue': {
          fontWeight: 500,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 200,
        },
        '& .filterMultipleValues': {
          marginLeft: '4px',
        },
        '& .numberOfFilterValues': {
          display: 'inline-block',
          padding: '2px 5px',
          fontSize: '10px',
          lineHeight: '14px',
          backgroundColor: 'rgba(255, 255, 255, 0.16)',
          borderRadius: '10px',
        },
        '& .numberOfFilterValuesWithValue': {
          marginLeft: '8px',
        },
        '& .MuiIcon-root.iconArrowDown': {
          width: '18px',
          height: '18px',
          fontSize: '18px',
          color: 'inherit',
        },
        '& .iconDelete': {
          display: 'flex',
          alighItems: 'center',
        },
        '& .materialIcon, & .iconDelete > span': {
          color: 'inherit',
          fontSize: '18px',
          lineHeight: '18px',
        },
        ...(state === 'default' && {
          backgroundColor: designTokens.colors.navy050,
          color: designTokens.colors.black,
          '&:hover': {
            backgroundColor: designTokens.colors.navy100,
          },
          '&:focus': {
            color: designTokens.colors.white,
            backgroundColor: designTokens.colors.navy600,
          },
        }),
        ...(state === 'indeterminate' && {
          backgroundColor: designTokens.colors.navy500,
          color: designTokens.colors.white,
          '&:hover': {
            backgroundColor: designTokens.colors.navy300,
          },
          '&:focus': {
            color: designTokens.colors.white,
            backgroundColor: designTokens.colors.navy600,
          },
        }),
      }}
      aria-controls={a11yControls}
      aria-describedby={a11yDescribedby}
      aria-haspopup={a11yHaspopup}
      disabled={disabled}
      disableElevation
      {...(e2e && { 'data-e2e': e2e })}
      fullWidth={fullWidth}
      onClick={dsOnClick}
      href={href}
      size={size}
      type={type}
      focusRipple
      startIcon={startIcon && <Icon color="inherit" body={startIcon} />}
      endIcon={
        endIcon &&
        dsOnDelete && (
          <span className="iconDelete" onClick={dsOnDelete}>
            <Icon color="inherit" body={endIcon} />
          </span>
        )
      }
      ref={buttonRef}
    >
      <span {...(e2e && { 'data-e2e': `${e2e}-body` })}>
        {body}
        {children}
      </span>
    </MuiButton>
  );
};

ButtonFilter.defaultProps = {
  variant: 'contained',
};

ButtonFilter.displayName = 'ButtonFilter';

export default ButtonFilter;
