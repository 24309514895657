import React from 'react';
import Select, { SelectItem } from '../../../Select';
import { FilterComponentProps, FilterRow } from './FilterComponentFactory';
import styled from '@emotion/styled';
import { Operator } from '../../types';

export const PicklistFilterComponent = (props: FilterComponentProps) => {
  const { stateKey, state, stateChangeFn, dsComponentProps, filterableField } =
    props;

  const StyledSelect = styled.div`
    div[data-e2e$='-filter-field'] {
      width: 55%;
    }
  `;

  return (
    <FilterRow>
      <StyledSelect>
        <Select
          className="select-container"
          {...dsComponentProps}
          label={state[stateKey].label || stateKey}
          dsOnChange={(e) => {
            stateChangeFn({
              ...state,
              [stateKey]: {
                ...state[stateKey],
                value: e.target.value,
                operator: Operator.Eq,
              },
            });
          }}
          value={state[stateKey].value}
        >
          {filterableField?.picklistOptions?.map((option, index) => {
            const label = typeof option === 'string' ? option : option.label;
            const value =
              typeof option === 'string'
                ? option
                : typeof option.value === 'string'
                ? option.value
                : '';

            return (
              <SelectItem value={value} key={`${value}+${index}`}>
                {label}
              </SelectItem>
            );
          })}
        </Select>
      </StyledSelect>
    </FilterRow>
  );
};
