import * as React from 'react';
import {
  FormHelperText as MuiFormHelperText,
  FormHelperTextProps as MUIFormHelperTextProps,
} from '@mui/material';

export interface FormHelperTextProps
  extends Pick<MUIFormHelperTextProps, 'disabled' | 'error' | 'required'> {
  children?: React.ReactNode;
  label?: string;
}

const FormHelperText: React.FC<FormHelperTextProps> = ({
  children,
  disabled,
  error,
  label,
  required,
}: FormHelperTextProps) => {
  return (
    <MuiFormHelperText disabled={disabled} error={error} required={required}>
      {children}
      {label}
    </MuiFormHelperText>
  );
};

export default FormHelperText;
