import * as React from 'react';
import {
  Grid as MUIGrid,
  GridProps as MUIGridProps,
  GridSize,
} from '@mui/material';
import { Breakpoint } from '@mui/material/styles';

export interface GridProps
  extends Pick<
      MUIGridProps,
      | 'alignContent'
      | 'alignItems'
      | 'direction'
      | 'item'
      | 'container'
      | 'children'
      | 'className'
      | 'spacing'
      | 'wrap'
      | 'sx'
    >,
    Partial<Record<Breakpoint, boolean | GridSize>> {
  body?: React.ReactNode;
  e2e?: string;
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

const Grid = ({
  body,
  children,
  e2e,
  alignContent,
  alignItems,
  direction,
  item,
  justify,
  container,
  className,
  spacing = 2,
  wrap,
  sx,
  ...props
}: GridProps) => {
  return (
    <MUIGrid
      {...(e2e && { 'data-e2e': e2e })}
      {...(!!container && { spacing })}
      alignContent={alignContent}
      alignItems={alignItems}
      direction={direction}
      item={item}
      justifyContent={justify}
      container={container}
      className={className}
      wrap={wrap}
      sx={sx}
      {...props}
    >
      {body}
      {children}
    </MUIGrid>
  );
};

export default Grid;
