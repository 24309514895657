import React from 'react';
import { Button } from '../../..';
import { Divider } from '@mui/material';
import { useTranslate } from '../../../i18n';

export const ClearComponent = ({ onClear }: { onClear: any }) => {
  const { translate } = useTranslate();

  return (
    <>
      <Divider />
      <Button variant="text" dsOnClick={onClear} fullWidth>
        {translate('FilterBar_Clear')}
      </Button>
    </>
  );
};
