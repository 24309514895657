/* eslint-disable prettier/prettier */
import * as React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';
import {
  TextField as MuiTextField,
  TextFieldProps,
  CircularProgress,
} from '@mui/material';
import Icon from '../Icon';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import makeClass from 'classnames';
import { ListboxComponent } from './AsyncReneder';
import MenuItem, { MenuItemProps } from '../MenuItem';

export interface AsyncRenderObjType {
  groupHeight?: number;
  height?: number;
}

export interface TypeAheadProps<T>
  extends Pick<
      MuiAutocompleteProps<T, any, true, false>,
      | 'disabled'
      | 'fullWidth'
      | 'groupBy'
      | 'loading'
      | 'options'
      | 'placeholder'
      | 'disableClearable'
      | 'disableCloseOnSelect'
      | 'inputValue'
      | 'freeSolo'
      | 'autoSelect'
    >,
    Pick<
      TextFieldProps,
      'error' | 'helperText' | 'name' | 'required' | 'autoFocus'
    > {
  /**
   * ARIA label. Use if component is not accompanied by a visible label.
   */
  a11yLabel?: string;
  getOptionLabel: NonNullable<
    MuiAutocompleteProps<T, any, true, false>['getOptionLabel']
  >;
  getOptionSelected?: MuiAutocompleteProps<
    T,
    any,
    true,
    false
  >['isOptionEqualToValue'];
  dsOnChange?: MuiAutocompleteProps<T, any, false, false>['onChange'];
  dsOnClose?: MuiAutocompleteProps<T, any, false, false>['onClose'];
  dsOnOpen?: MuiAutocompleteProps<T, any, false, false>['onOpen'];
  dsOnInputChange?: MuiAutocompleteProps<T, any, false, false>['onInputChange'];
  dsRenderOption?: (option: T) => React.ReactNode;
  dsFilterOptions?: (options: T[]) => T[];
  e2e?: string;
  value: any;
  label?: string;
  inputChangeDebounce?: number;
  multiple?: boolean;
  limitTags?: number;
  readonly?: boolean;
  asyncRender?: boolean | AsyncRenderObjType;
  itemRightContent?: MenuItemProps['itemRightContent'];
  description?: string;
}

const StyledAutocompleteWrapper = styled.div<{
  $readonly?: boolean;
}>`
  .typeAhead-multiple {
    height: auto;
    flex-wrap: wrap;

    input {
      flex-basis: content;
    }
  }

  .typeAhead-multiple-limited {
    height: auto;
    overflow: auto;

    input {
      flex-basis: content;
    }
  }
  ${({ $readonly }) =>
    $readonly &&
    `
      & .MuiAutocomplete-popupIndicator:hover {
        background: inherit;
        cursor: text;
      }
    `}
`;

const TypeAhead = React.forwardRef<HTMLInputElement, TypeAheadProps<any>>(
  (
    {
      a11yLabel,
      disabled,
      dsOnChange,
      dsOnClose,
      dsOnOpen,
      dsOnInputChange,
      dsRenderOption,
      // Should be set to (options) => options in the case of async filtering
      dsFilterOptions,
      e2e,
      error,
      fullWidth = true,
      getOptionLabel,
      getOptionSelected,
      groupBy,
      helperText,
      label,
      loading,
      name,
      options,
      placeholder,
      disableClearable,
      disableCloseOnSelect,
      required,
      value,
      inputValue,
      inputChangeDebounce = 0,
      autoSelect = false,
      freeSolo = false,
      autoFocus = false,
      multiple = false,
      limitTags,
      readonly,
      asyncRender = false,
      itemRightContent,
      description,
    }: TypeAheadProps<any>,
    ref,
  ) => {
    const timer = React.useRef<number>();
    const isMultiLimited = multiple && limitTags;

    const [open, setOpen] = React.useState<boolean>(false);
    const [multiClass, setMultiClass] = React.useState<string>(
      isMultiLimited ? 'typeAhead-multiple-limited' : 'typeAhead-multiple',
    );

    React.useEffect(() => {
      return () => {
        clearTimeout(timer.current);
        setOpen(false);
      };
    }, []);

    /* In the design system, all inputs are controlled.
     * Uncontrolled is when value === undefined. If presented
     * with an this invalid state, render nothing.
     */
    if (value === undefined || !options) {
      return null;
    }

    const focusGuard = () => {
      if (autoFocus) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setOpen(true);
        }, 100);
      }

      setMultiClass('typeAhead-multiple');
    };

    const onBlur = () => {
      if (isMultiLimited) {
        setMultiClass('typeAhead-multiple-limited');
      }
    };

    return (
      <StyledAutocompleteWrapper $readonly={readonly}>
        <MuiAutocomplete
          ref={ref}
          open={autoFocus ? open : undefined}
          {...(asyncRender && {
            ListboxComponent: ListboxComponent(asyncRender),
          })}
          autoHighlight
          autoSelect={!autoFocus && autoSelect}
          freeSolo={freeSolo}
          aria-label={a11yLabel}
          {...(e2e && { 'data-e2e': e2e })}
          disabled={disabled}
          fullWidth={fullWidth}
          getOptionLabel={getOptionLabel}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              value={getOptionLabel(option)}
              {...(dsRenderOption && {
                children: dsRenderOption(option),
              })}
              itemRightContent={itemRightContent}
              description={description}
            />
          )}
          isOptionEqualToValue={getOptionSelected}
          groupBy={groupBy}
          multiple={multiple}
          limitTags={limitTags}
          readOnly={readonly}
          loading={loading}
          onChange={dsOnChange}
          onClose={dsOnClose}
          onOpen={dsOnOpen}
          onInputChange={
            inputChangeDebounce && dsOnInputChange
              ? debounce(dsOnInputChange, inputChangeDebounce, {
                  leading: false,
                  trailing: true,
                })
              : dsOnInputChange
          }
          options={options}
          {...(dsFilterOptions && {
            filterOptions: dsFilterOptions,
          })}
          disableClearable={disableClearable}
          disableCloseOnSelect={disableCloseOnSelect}
          renderInput={(params) => {
            return (
              <div className="input-wrapper">
                <MuiTextField
                  {...params}
                  error={error}
                  className={makeClass({ ReadOnly: readonly })}
                  autoFocus={autoFocus}
                  onFocus={focusGuard}
                  onBlur={onBlur}
                  helperText={helperText}
                  {...(e2e && {
                    inputProps: {
                      ...params.inputProps,
                      'data-e2e': `${e2e}-input`,
                    },
                  })}
                  InputProps={{
                    ...params.InputProps,
                    ...(!multiple
                      ? {
                          className: 'typeAhead',
                          startAdornment: <Icon body="search" />,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }
                      : {
                          className: multiClass,
                        }),
                  }}
                  name={name}
                  placeholder={placeholder}
                  required={required}
                  label={label}
                  variant="outlined"
                  value={inputValue}
                />
              </div>
            );
          }}
          value={value}
        />
      </StyledAutocompleteWrapper>
    );
  },
);

export default TypeAhead;
